#StandaardInfo1 {
    background-color: $bgc;

    @include md {
        padding-top: 5vh;
    }

    @include lg {
        padding: 10vh 0vh;
    }

    .parent {
        .inner {
            gap: 2.5vw;
            border-radius: $base-border-radius *2;
            // overflow: hidden;
            @include fullWidth();
            flex-direction: row-reverse;

            >* {
                color: $txtColorSecondary;
            }
            
            .ph-1 {
                flex-direction: row;
                color: $txtColor;
                width: 100%;

                .TextComponentCore {
                    // text-align: flex-start;
                    // align-items: left;

                    // text-align: center;
                    // align-items: center;
                }

                .image {
                    width: 100%;
                }
            }

        }
    }
}