.defaultCardSwiper {
    width: 20em;
    height: 20em;
    // transform: rotate(5deg);
    overflow: visible !important;

    .swiper-slide {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: $base-border-radius;
        font-size: 22px;
        font-weight: bold;
        color: #fff;
        // background-color: purple;
        width: 1em;
        overflow: hidden;


        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }
}