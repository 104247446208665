#basicHeader2 {
    align-items: flex-start;


    .hamburgerMenuIcon{
        color: $menuButtonActive;
    }
    .openedMenu {
        margin-right: unset;
    }

    .headerInner {
        width: 100%;
        top: 0;
        padding: .5% 0;
        position: relative;

        .logo {
            margin-top: unset;
        }

        @include md {
            background-color: rgba(0, 0, 0, 0);
            backdrop-filter: blur(3px);
        }



        a {
            img {
                height: 90%;
                object-fit: contain;
            }
        }
    }

    .menuItemsWrapper {
        justify-content: flex-start;
        width: 85%;
        margin-left: 2%;
        gap: 10px;

        // background-color: yellow;
        .menuItem {
            box-shadow: 0 19px 38px rgba(0, 0, 0, 0.10), 0 5px 12px rgba(0, 0, 0, 0.22);
        }

        .parentTmpButton {
            right: 0;
            position: absolute;
        }
    }
}