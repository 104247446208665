@import url("https://use.typekit.net/yva0fsu.css");
@import url("https://use.typekit.net/rop0csw.css");
@import url("https://use.typekit.net/rop0csw.css");
* {
  color: inherit;
  margin: 0;
  font-style: normal;
}

body {
  overflow-x: hidden;
  margin: 0;
  font-family: urbane, sans-serif;
  max-width: 100vw;
  width: 100vw;
  position: relative;
}

@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi) {
  /* Retina-specific stuff here */
}
button {
  font-family: urbane, sans-serif;
}

#smoothWrapper {
  max-width: 100vw;
  overflow: hidden;
}

#root {
  width: 100%;
  max-width: 100vw;
  overflow: hidden;
}

a {
  text-decoration: none;
}

ul {
  padding: 0;
  list-style-type: none;
}

.txt-oranje {
  color: #ff691d;
}

.txt-hover-oranje:hover {
  color: #ff691d;
}

.bg-oranje {
  background-color: #ff691d;
}

.txt-blauw {
  color: #42809B;
}

.txt-hover-blauw:hover {
  color: #42809B;
}

.bg-blauw {
  background-color: #42809B;
}

.txt-xlGreen {
  color: #f1f1f1;
}

.txt-hover-xlGreen:hover {
  color: #f1f1f1;
}

.bg-xlGreen {
  background-color: #f1f1f1;
}

html {
  color: black;
}

:root {
  --step--2: clamp(0.7rem, calc(0.69rem + 0.27vw), 0.97rem);
  --step--1: clamp(0.8rem, calc(0.85rem + 0.46vw), 1.20rem);
  --step-0: clamp(1.13rem, calc(0.99rem + 0.65vw), 1.50rem);
  --step-1: clamp(1.35rem, calc(1.17rem + 0.91vw), 1.88rem);
  --step-2: clamp(1.40rem, calc(1.27rem + 1.26vw), 2.34rem);
  --step-3: clamp(1.94rem, calc(1.60rem + 1vw), 2.93rem);
  --step-4: clamp(2.1rem, calc(2.25rem + 2.31vw), 3.66rem);
  --step-5: clamp(2.80rem, calc(2.51rem + 2.43vw), 4.20rem);
}

.staggerTitle div {
  overflow: hidden;
  vertical-align: top;
}

strong {
  font-weight: 600;
}
@media (min-width: 1399.98px) {
  strong {
    font-size: 4em;
    line-height: 1.25em;
  }
}

q {
  font-size: var(--step-0);
  padding-right: 15%;
}
q:after, q:before {
  color: #E60F14;
  font-family: auto;
  font-size: var(--step-5);
  position: absolute;
  margin-top: -0.1em;
}
q:before {
  margin-left: -0.55em;
}
@media (min-width: 1199.98px) {
  q:before {
    margin-left: -0.65em;
  }
}
q:after {
  margin-left: 0.25em;
}

h1 {
  font-size: 2.65em;
  line-height: 1.15em;
  font-weight: 600;
}
@media (min-width: 991.98px) {
  h1 {
    font-size: 5.5em;
  }
}

h2 {
  font-size: 2.5em;
  line-height: 1.1em;
  font-weight: 600;
}
@media (min-width: 991.98px) {
  h2 {
    font-size: 3em;
  }
}

h3 {
  font-size: 1.8em;
  line-height: 1.4em;
  font-weight: 600;
}

p {
  font-size: var(--step--2);
  line-height: 1.8em;
  font-weight: 300;
}

.phlatball-speelgoed-a > * .TextComponentCore,
.phlatball-speelgoed-b > * .TextComponentCore {
  text-align: center;
  align-items: center;
}

.SpeelgoedTemp #StandaardInfo2,
.SpeelgoedTemp #StandaardImageViewer {
  box-shadow: 0 4px 4px #3385DB;
}
.SpeelgoedTemp #standaardHero1 .inner {
  gap: 5vh;
  height: 90vh !important;
}
@media (min-width: 767.98px) {
  .SpeelgoedTemp #standaardHero1 .TextComponentCore h1 {
    margin-top: -5vh;
    font-size: 4em;
  }
}
@media (min-width: 991.98px) {
  .SpeelgoedTemp #standaardHero1 .TextComponentCore h1 {
    font-size: 5em;
  }
}
@media (min-width: 1399.98px) {
  .SpeelgoedTemp #standaardHero1 .TextComponentCore h1 {
    font-size: 6em;
  }
}
.SpeelgoedTemp > * .TextComponentCore {
  width: 95% !important;
  margin-left: 2.5%;
  margin-right: 2.5%;
}

.ImageComponentCore {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  border-radius: 0.8em;
}
@media (min-width: 991.98px) {
  .ImageComponentCore {
    border-radius: 1.4em;
  }
}
.ImageComponentCore a {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
.ImageComponentCore a img {
  height: 100%;
  width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.ImageComponentCore .bgLayer {
  background: radial-gradient(circle, rgba(100, 100, 100, 0) 20%, rgba(0, 0, 0, 0.15) 80%);
  position: absolute;
  width: 100%;
  height: 100%;
}
.ImageComponentCore .bgImg {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 0.8em;
  margin: 0;
}
.ImageComponentCore .parentTmpButton {
  position: absolute;
  z-index: 1;
  bottom: 1em;
  right: 1em;
}
.ImageComponentCore .parentTmpButton {
  z-index: 1;
  bottom: 0.5em;
  right: 0.5em;
}
@media (min-width: 991.98px) {
  .ImageComponentCore .parentTmpButton {
    bottom: 1em;
    right: 1em;
  }
}
.ImageComponentCore .parentTmpButton .dsRoundBtn {
  width: 2.5em;
  height: 2.5em;
}
@media (min-width: 991.98px) {
  .ImageComponentCore .parentTmpButton .dsRoundBtn {
    width: 3em;
    height: 3em;
  }
}
.ImageComponentCore .parentTmpButton .dsRoundBtn .material-icons-outlined {
  font-size: 1em;
}
@media (min-width: 991.98px) {
  .ImageComponentCore .parentTmpButton .dsRoundBtn .material-icons-outlined {
    font-size: 1.25em;
  }
}

.dsVideoFormSection {
  overflow: hidden;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #120b01;
  min-height: 90vh;
  position: relative;
  width: 92.5vw;
  padding: 7.5vh 3.75vw;
}
@media (min-width: 767.98px) {
  .dsVideoFormSection {
    width: 90vw;
    padding: 3em 5vw;
  }
}
@media (min-width: 1399.98px) {
  .dsVideoFormSection {
    width: 80vw;
    padding: 0vh 10vw;
  }
}
@media (min-width: 1699.98px) {
  .dsVideoFormSection {
    width: 75vw;
    padding: 0vh 12.5vw;
  }
}
@media (min-width: 2299.98px) {
  .dsVideoFormSection {
    width: 60vw;
    padding: 0vh 20vw;
  }
}
.dsVideoFormSection .vidIntroWrapper {
  position: relative;
  width: 92.5vw;
  padding: 7.5vh 3.75vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1em;
  padding-bottom: 1vh;
}
@media (min-width: 767.98px) {
  .dsVideoFormSection .vidIntroWrapper {
    width: 90vw;
    padding: 3em 5vw;
  }
}
@media (min-width: 1399.98px) {
  .dsVideoFormSection .vidIntroWrapper {
    width: 80vw;
    padding: 0vh 10vw;
  }
}
@media (min-width: 1699.98px) {
  .dsVideoFormSection .vidIntroWrapper {
    width: 75vw;
    padding: 0vh 12.5vw;
  }
}
@media (min-width: 2299.98px) {
  .dsVideoFormSection .vidIntroWrapper {
    width: 60vw;
    padding: 0vh 20vw;
  }
}
.dsVideoFormSection .vidIntroWrapper .timeFrame {
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 0.5em;
}
.dsVideoFormSection .vidIntroWrapper .vidIntroTitle {
  color: white;
  text-align: center;
}
.dsVideoFormSection .vidIntroWrapper #vidFormStartBtn {
  background-color: #FF303D;
  padding: 0.5em 1.15em;
  border-radius: 400px;
  color: white;
}
.dsVideoFormSection .vidVideoFormWrapper {
  height: 60vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  overflow: hidden;
}
.dsVideoFormSection .vidVideoFormWrapper .vidQuizExplainer {
  width: 80%;
  position: absolute;
  color: white;
  text-align: center;
}
.dsVideoFormSection .vidVideoFormWrapper .playContainer {
  position: absolute;
  z-index: 100;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 1;
}
.dsVideoFormSection .vidVideoFormWrapper .playContainer .playButton {
  width: 5.5em;
  height: 5.5em;
  position: absolute;
  background-color: #EDF95A;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 500em;
  box-shadow: 0 10px 20px rgba(42, 42, 42, 0.1), 0 3px 6px rgba(0, 0, 0, 0.066);
}
.dsVideoFormSection .vidVideoFormWrapper .playContainer .playButton svg {
  width: 100%;
  height: 40%;
  position: absolute;
  fill: #2E1B01;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-left: 0.5em;
}
.dsVideoFormSection .vidVideoFormWrapper .playContainer .playButton svg path {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: absolute;
}
.dsVideoFormSection .vidVideoFormWrapper .playContainer #svgCircles {
  width: 95%;
}
.dsVideoFormSection .vidVideoFormWrapper .vidFormCount {
  color: white;
  font-size: 10em;
  position: absolute;
  font-weight: 800;
}
.dsVideoFormSection .vidVideoFormWrapper video {
  height: 100%;
  position: absolute;
  -o-object-fit: cover;
     object-fit: cover;
  filter: blur(0px);
}
.dsVideoFormSection .vidVideoFormWrapper .gradient {
  width: 100%;
  position: absolute;
}
.dsVideoFormSection .vidVideoFormWrapper .gradient-bottom {
  bottom: -2px;
  height: 100%;
  transform-origin: bottom;
  background: linear-gradient(0deg, rgb(18, 11, 1) 0%, rgba(18, 11, 1, 0) 100%);
}
.dsVideoFormSection .vidVideoFormWrapper .gradient-top {
  top: -2px;
  transform-origin: top;
  height: 100%;
  background: linear-gradient(180deg, rgb(18, 11, 1) 0%, rgba(18, 11, 1, 0) 100%);
}
.dsVideoFormSection .vidFormTimeRangeIntro,
.dsVideoFormSection .vidFormTimeRange {
  width: 75%;
  height: 0.4em;
  border-radius: 400px;
  position: absolute;
  bottom: 3.5vh;
  background-color: rgba(255, 255, 255, 0.2509803922);
  z-index: 1;
}
.dsVideoFormSection .vidFormTimeRangeIntro .vidFormTimeRangeInnerIntro,
.dsVideoFormSection .vidFormTimeRangeIntro .vidFormTimeRangeInner,
.dsVideoFormSection .vidFormTimeRange .vidFormTimeRangeInnerIntro,
.dsVideoFormSection .vidFormTimeRange .vidFormTimeRangeInner {
  width: 0;
  height: 100%;
  background-color: #EDF95A;
  position: absolute;
  border-radius: 400px;
  box-shadow: 0 10px 20px rgba(42, 42, 42, 0.1), 0 3px 6px rgba(0, 0, 0, 0.066);
}
.dsVideoFormSection .vidQuestionFormWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  width: 92.5vw;
  padding: 7.5vh 3.75vw;
  height: 100%;
  padding-top: 1vh;
  padding-bottom: 2vh;
  overflow: hidden;
}
@media (min-width: 767.98px) {
  .dsVideoFormSection .vidQuestionFormWrapper {
    width: 90vw;
    padding: 3em 5vw;
  }
}
@media (min-width: 1399.98px) {
  .dsVideoFormSection .vidQuestionFormWrapper {
    width: 80vw;
    padding: 0vh 10vw;
  }
}
@media (min-width: 1699.98px) {
  .dsVideoFormSection .vidQuestionFormWrapper {
    width: 75vw;
    padding: 0vh 12.5vw;
  }
}
@media (min-width: 2299.98px) {
  .dsVideoFormSection .vidQuestionFormWrapper {
    width: 60vw;
    padding: 0vh 20vw;
  }
}
.dsVideoFormSection .vidQuestionFormWrapper .videoNextBtn {
  background-color: #fff;
  line-height: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 1em;
  height: 1em;
  padding: 1.25em;
  border-radius: 5px;
}
.dsVideoFormSection .vidQuestionFormWrapper .videoNextBtn .material-icons-outlined {
  font-size: 1.5em !important;
}
.dsVideoFormSection .vidQuestionFormWrapper .progress {
  color: white;
  width: 100%;
  text-align: center;
  padding: 2vh 0;
}
.dsVideoFormSection .vidQuestionFormWrapper .vidFormQuestionTitle {
  color: white;
  width: 100%;
  text-align: center;
}
.dsVideoFormSection .vidQuestionFormWrapper .buttonWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 0.75em;
  flex-wrap: wrap;
  width: 100%;
  padding: 5% 0;
}
.dsVideoFormSection .vidQuestionFormWrapper .buttonWrapper button {
  font-size: 1em;
  width: 45%;
}

.dsFromSection {
  background-color: rgb(231, 220, 255);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.dsFromSection input:-webkit-autofill,
.dsFromSection input:-webkit-autofill:hover,
.dsFromSection input:-webkit-autofill:focus,
.dsFromSection input:-webkit-autofill:active {
  -webkit-transition: background-color 5000s ease-in-out 0s, color 5000s ease-in-out 0s;
  transition: background-color 5000s ease-in-out 0s, color 5000s ease-in-out 0s;
  transition-delay: background-color 5000s, color 5000s;
}
.dsFromSection .dsFormSwiper {
  overflow: visible;
  width: 100%;
}
.dsFromSection .dsFormSwiper .bgImg {
  position: absolute;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.dsFromSection .dsFormSwiper label {
  flex-direction: row !important;
  border-radius: 0 !important;
  box-shadow: unset !important;
  left: 10px !important;
  z-index: 100;
  position: absolute !important;
  bottom: 10px !important;
  background-color: unset !important;
  height: -moz-fit-content;
  height: fit-content;
}
.dsFromSection .dsFormSwiper label:hover {
  background-color: unset !important;
}
.dsFromSection .dsFormSwiper label > * {
  font-size: 1.45em !important;
  font-family: "urbane" !important;
}
.dsFromSection .dsFormSwiper label .title {
  color: white;
}
.dsFromSection .dsFormSwiper .clickArea {
  width: 140vh;
  height: 140vh;
  position: absolute;
  z-index: 100;
  left: -10%;
  top: -500%;
}
.dsFromSection .dsFormSwiper .gradient {
  background: linear-gradient(0deg, rgb(0, 0, 0) 20%, rgba(0, 0, 0, 0) 100%);
  width: 100%;
  height: 50%;
  position: absolute;
  bottom: 0px;
  z-index: 100;
}
.dsFromSection .dsFormSwiper .swiper-slide {
  overflow: hidden;
  position: relative;
}
.dsFromSection .dsFormSwiper .swiper-button-next,
.dsFromSection .dsFormSwiper .swiper-button-prev {
  color: purple;
  margin-left: 1em;
  margin-right: 1em;
}
@media (min-width: 991.98px) {
  .dsFromSection .dsFormSwiper .swiper-button-next,
  .dsFromSection .dsFormSwiper .swiper-button-prev {
    margin: 0;
  }
}
.dsFromSection .dsFormSwiper .swiper-button-next:active,
.dsFromSection .dsFormSwiper .swiper-button-prev:active {
  background-color: purple;
}
@media (min-width: 991.98px) {
  .dsFromSection .dsFormSwiper .swiper-button-next:hover,
  .dsFromSection .dsFormSwiper .swiper-button-prev:hover {
    background-color: purple;
  }
}
.dsFromSection .dsForm,
.dsFromSection .nameFieldsWrapper {
  gap: 0.5em;
}
.dsFromSection .dsForm {
  position: relative;
  width: 92.5vw;
  padding: 7.5vh 3.75vw;
  padding-top: 0vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
@media (min-width: 767.98px) {
  .dsFromSection .dsForm {
    width: 90vw;
    padding: 3em 5vw;
  }
}
@media (min-width: 1399.98px) {
  .dsFromSection .dsForm {
    width: 80vw;
    padding: 0vh 10vw;
  }
}
@media (min-width: 1699.98px) {
  .dsFromSection .dsForm {
    width: 75vw;
    padding: 0vh 12.5vw;
  }
}
@media (min-width: 2299.98px) {
  .dsFromSection .dsForm {
    width: 60vw;
    padding: 0vh 20vw;
  }
}
.dsFromSection .dsForm .dsListItemWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 0.5em;
  font-family: "urbane" !important;
}
.dsFromSection .dsForm .dsLabelTitel {
  order: 1;
  text-align: center;
}
.dsFromSection .dsForm .dsListItemContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 0.5em;
  order: 2;
  overflow: hidden;
  height: 0;
}
.dsFromSection .dsForm .dsFormLabelActive {
  border: solid 1.5px #9c00ff !important;
  background-color: #FEFDFF !important;
  border-radius: 10px !important;
}
.dsFromSection .dsForm .dsCheckRadioInnerList {
  overflow: hidden;
  overflow-y: scroll;
}
@media (min-width: 991.98px) {
  .dsFromSection .dsForm .dsCheckRadioInnerList {
    overflow: unset;
  }
}
.dsFromSection .dsForm .dsCheckRadioInnerList .dsListItem {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 0.15em;
  font-family: "urbane" !important;
  width: 100%;
}
.dsFromSection .dsForm .dsCheckRadioInnerList .dsListItem .MuiFormControlLabel-root:hover {
  background-color: unset;
}
.dsFromSection .dsForm .dsCheckRadioInnerList .dsListItem label {
  padding: 0.5em 1em;
  padding-left: 0.25em;
  background: white;
}
.dsFromSection .dsForm .dsCheckRadioInnerList .dsListItem label:hover {
  background: #E5E5FC !important;
  border: solid 1px #9c00ff !important;
}
.dsFromSection .dsForm .dsCheckRadioInnerList .dsListItem label > * {
  font-family: "urbane" !important;
}
.dsFromSection .dsForm .dsCheckRadioInnerList label {
  width: 100% !important;
}
.dsFromSection .dsForm .dsCheckRadioInnerList label .dsListItemContainer {
  width: 100%;
}
.dsFromSection .dsForm .dsFormPh {
  width: 80%;
}
.dsFromSection .dsForm .dsFormNumber,
.dsFromSection .dsForm .dsSelect,
.dsFromSection .dsForm .dsCheckRadio,
.dsFromSection .dsForm .dsFormSlideRange,
.dsFromSection .dsForm .dsDatePicker,
.dsFromSection .dsForm .dsTextField {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.dsFromSection .dsForm .dsCheckRadioInner,
.dsFromSection .dsForm .dsDatePickerInner {
  display: flex;
  flex-direction: column;
}
.dsFromSection .dsForm .dsFormSlideRange {
  width: 80%;
}
@media (min-width: 991.98px) {
  .dsFromSection .dsForm .dsFormNumber {
    width: 40%;
  }
}
.dsFromSection .dsForm .dsCheckRadio .MuiFormGroup-root {
  gap: 1em;
  flex-direction: row;
}
.dsFromSection .dsForm .dsCheckRadio .dsFromCheckboxLabelContainer,
.dsFromSection .dsForm .dsCheckRadio .dsFromRadioLabelContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  width: -moz-fit-content;
  width: fit-content;
}
.dsFromSection .dsForm .dsCheckRadio .MuiFormControlLabel-root {
  margin: 0px;
  border-radius: 4px;
  padding: 0.75rem;
  background-color: rgba(255, 255, 255, 0.25);
  border: solid 1px rgba(0, 0, 0, 0.23);
}
.dsFromSection .dsForm .dsCheckRadio .MuiFormControlLabel-root:hover {
  background-color: rgb(255, 255, 255);
  box-shadow: 0 10px 20px rgba(42, 42, 42, 0.1), 0 3px 6px rgba(0, 0, 0, 0.066);
}
.dsFromSection .dsForm .dsCheckRadio .MuiFormControlLabel-root .dsLabelImage {
  width: 7.5em;
}
.dsFromSection .dsForm .nameFieldsWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
@media (min-width: 991.98px) {
  .dsFromSection .dsForm .nameFieldsWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    gap: 2em;
  }
}
.dsFromSection .dsCompletedFormWrap {
  padding: 10vh 10vw;
  margin: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #fff;
  border-radius: 1em;
  box-shadow: 0 10px 20px rgba(42, 42, 42, 0.1), 0 3px 6px rgba(0, 0, 0, 0.066);
  text-align: center;
}
.dsFromSection .dsCompletedFormWrap .dsFormCompleteIcon {
  color: rgb(108, 179, 0);
  font-size: 5em;
}

.dsFromSection-Pdf,
.dsFromSection-Newsletter {
  padding: 5vh 0vh;
}
.dsFromSection-Pdf h3,
.dsFromSection-Newsletter h3 {
  margin-bottom: 0.75em;
}
.dsFromSection-Pdf .dsForm,
.dsFromSection-Newsletter .dsForm {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  align-items: flex-start;
}
.dsFromSection-Pdf .dsForm .dsEmailInputField,
.dsFromSection-Newsletter .dsForm .dsEmailInputField {
  width: 50%;
}

.TextComponentCore {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  gap: 0.75em;
}
@media (min-width: 991.98px) {
  .TextComponentCore {
    gap: 1.25em;
  }
}
.TextComponentCore .btnWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1em;
}
@media (min-width: 991.98px) {
  .TextComponentCore .btnWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
  }
}
.TextComponentCore .paraStagger {
  max-width: 55em;
}
.TextComponentCore .retailWidget {
  height: 2em;
  border-radius: 0.4em;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  position: relative;
  gap: 1em;
  padding: 0.375rem 0.75rem;
}
.TextComponentCore .retailWidget img {
  width: 25%;
  max-width: 3em;
  max-height: 80%;
  -o-object-fit: contain;
     object-fit: contain;
}

.textComponentCenter {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}
.textComponentCenter .btnWrapper {
  align-items: center !important;
}

.SwiperComponentCoreWrapper {
  width: 100%;
  max-width: 100vw;
  position: relative;
  min-height: 1em;
  height: -moz-fit-content;
  height: fit-content;
}
.SwiperComponentCoreWrapper .bgLayer {
  background: radial-gradient(circle, rgba(100, 100, 100, 0) 20%, rgba(0, 0, 0, 0.15) 80%);
  position: absolute;
  width: 80%;
  margin-left: 10%;
  height: 50%;
  bottom: 0;
  border-radius: 0.8em;
}
@media (min-width: 991.98px) {
  .SwiperComponentCoreWrapper .bgLayer {
    border-radius: 1.4em;
  }
}

.SwiperComponentCore {
  width: 80%;
  height: 100%;
}
.SwiperComponentCore .swiper-button-next {
  margin-right: -2rem;
}
.SwiperComponentCore .swiper-button-next:after {
  position: absolute;
  margin-left: 0.15em;
}
.SwiperComponentCore .swiper-button-prev {
  margin-left: -2rem;
}
.SwiperComponentCore .swiper-button-prev:after {
  position: absolute;
  margin-left: -0.15em;
}
.SwiperComponentCore .gradientOverlay {
  width: 100%;
  height: 50%;
  position: absolute;
  bottom: -2px;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4374343487) 0%, rgba(0, 0, 0, 0) 100%);
}
.SwiperComponentCore .swiper-button-next,
.SwiperComponentCore .swiper-button-prev {
  color: #FB0207;
  background-color: white;
  width: 3rem;
  height: 3rem;
  font-size: 0.05em;
  padding: 2em;
  border-radius: 500em;
  margin-top: -2.5rem;
  transition: all 0.35s ease-in-out;
  box-shadow: 0 10px 20px rgba(42, 42, 42, 0.1), 0 3px 6px rgba(0, 0, 0, 0.066);
  transform: scale(0.8);
}
@media (min-width: 991.98px) {
  .SwiperComponentCore .swiper-button-next,
  .SwiperComponentCore .swiper-button-prev {
    width: 4rem;
    height: 4rem;
  }
}
.SwiperComponentCore .swiper-button-next::after,
.SwiperComponentCore .swiper-button-prev::after {
  font-size: 1.5rem;
}
@media (min-width: 991.98px) {
  .SwiperComponentCore .swiper-button-next::after,
  .SwiperComponentCore .swiper-button-prev::after {
    font-size: 2rem;
  }
}
.SwiperComponentCore .swiper-button-next:active,
.SwiperComponentCore .swiper-button-prev:active {
  background-color: #FB0207;
  color: white;
  transform: scale(0.9);
}
@media (min-width: 991.98px) {
  .SwiperComponentCore .swiper-button-next:hover,
  .SwiperComponentCore .swiper-button-prev:hover {
    background-color: #FB0207;
    color: white;
    transform: scale(0.9);
  }
}
.SwiperComponentCore .swiper-wrapper {
  width: 100%;
  padding: 5% 0;
}
.SwiperComponentCore .swiper-wrapper .swiperSlide {
  min-height: 1em;
  height: 40vh;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 0.8em;
  box-shadow: 0 10px 20px rgba(42, 42, 42, 0.1), 0 3px 6px rgba(0, 0, 0, 0.066);
  gap: 2.5vh;
}
.SwiperComponentCore .swiper-wrapper .swiperSlide .MuiFormControlLabel-root {
  border: solid transparent 1px !important;
}
.SwiperComponentCore .swiper-wrapper .swiperSlide .placeholder {
  width: 90%;
  height: 10em;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 0.8em;
  overflow: hidden;
}
.SwiperComponentCore .swiper-wrapper .swiperSlide .placeholder .img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  position: relative;
}
.SwiperComponentCore .swiper-wrapper .swiperSlide .title,
.SwiperComponentCore .swiper-wrapper .swiperSlide .parentTmpButton {
  z-index: 1;
}
.SwiperComponentCore .swiper-wrapper .swiperSlide .backgroundImg {
  width: 100%;
  height: 101%;
  position: absolute;
  -o-object-fit: cover;
     object-fit: cover;
}
.SwiperComponentCore .swiper-wrapper .swiperSlide .title {
  color: white;
}
.SwiperComponentCore .swiper-wrapper .swiperSlide .gradient {
  opacity: 0.75;
}

.swiperFullWidth {
  width: 100vw;
  overflow: visible;
}
.swiperFullWidth .swiper-button-prev {
  left: 5vw;
}
.swiperFullWidth .swiper-button-next {
  right: 5vw;
}
.swiperFullWidth .swiper-wrapper {
  padding: 0;
}
.swiperFullWidth .swiper-wrapper .swiperSlide {
  height: 400px;
}

.StandaardFooter {
  background-color: black;
  position: relative;
  width: 92.5vw;
  padding: 7.5vh 3.75vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  padding-top: 0.75em !important;
  padding-bottom: 0.75em !important;
  justify-content: flex-end;
  gap: 2em;
}
@media (min-width: 767.98px) {
  .StandaardFooter {
    width: 90vw;
    padding: 3em 5vw;
  }
}
@media (min-width: 1399.98px) {
  .StandaardFooter {
    width: 80vw;
    padding: 0vh 10vw;
  }
}
@media (min-width: 1699.98px) {
  .StandaardFooter {
    width: 75vw;
    padding: 0vh 12.5vw;
  }
}
@media (min-width: 2299.98px) {
  .StandaardFooter {
    width: 60vw;
    padding: 0vh 20vw;
  }
}
.StandaardFooter .columnWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  height: 100%;
  gap: 2em;
}
.StandaardFooter ul {
  color: rgba(255, 255, 255, 0.5);
  font-weight: 300;
}
.StandaardFooter ul > * {
  cursor: pointer;
}
.StandaardFooter ul > *:hover {
  color: white;
}
.StandaardFooter ul li {
  margin: 0.25em 0;
}
.StandaardFooter ul a {
  cursor: pointer;
  font-size: 0.8em;
}
.StandaardFooter ul a:hover {
  color: white;
}
.StandaardFooter .imageRow {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}
.StandaardFooter .imageRow .footerImgContainer {
  position: relative;
}
.StandaardFooter .imageRow .footerImgContainer img {
  width: 100%;
  max-width: 3.5em;
}

.videoPopUpContainer {
  background-color: #3385DB;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  width: 92.5vw;
  padding: 7.5vh 3.75vw;
  height: -moz-fit-content;
  height: fit-content;
  perspective: 1000px;
}
@media (min-width: 767.98px) {
  .videoPopUpContainer {
    width: 90vw;
    padding: 3em 5vw;
  }
}
@media (min-width: 1399.98px) {
  .videoPopUpContainer {
    width: 80vw;
    padding: 0vh 10vw;
  }
}
@media (min-width: 1699.98px) {
  .videoPopUpContainer {
    width: 75vw;
    padding: 0vh 12.5vw;
  }
}
@media (min-width: 2299.98px) {
  .videoPopUpContainer {
    width: 60vw;
    padding: 0vh 20vw;
  }
}
@media (min-width: 1199.98px) {
  .videoPopUpContainer {
    height: 100vh;
  }
}
.videoPopUpContainer .title {
  color: white;
}
.videoPopUpContainer .jwplayer {
  border-radius: 0.8em;
  overflow: hidden;
}
.videoPopUpContainer .videoPopUpWrapper {
  width: 100%;
  position: relative;
  height: -moz-fit-content;
  height: fit-content;
  overflow: hidden;
  border-radius: 1.6em;
  box-shadow: 0 10px 20px rgba(42, 42, 42, 0.1), 0 3px 6px rgba(0, 0, 0, 0.066);
  background-color: black;
  max-width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer !important;
}
.videoPopUpContainer .videoPopUpWrapper .videoPopUpElement {
  transform: scale(1.025);
}
.videoPopUpContainer .videoPopUpWrapper .parentTmpButton {
  position: absolute;
  transform: scale(2);
  z-index: 1;
  pointer-events: none;
}
.videoPopUpContainer .videoPopUpWrapper .parentTmpButton > * {
  pointer-events: none;
}
.videoPopUpContainer .videoPopUpWrapper .videoPopUpElement,
.videoPopUpContainer .videoPopUpWrapper .videoPopUpMfLayer {
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 0.8em;
}
.videoPopUpContainer .videoPopUpWrapper .videoPopUpMfLayer {
  z-index: 10;
  position: absolute;
  top: 0;
}
.videoPopUpContainer .videoPopUpBgExit {
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.7);
  height: 500vh;
  position: fixed;
  top: -250vh;
  z-index: 98;
  display: none;
}
.videoPopUpContainer .videoPopUpFixedOverlay {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  position: fixed;
  width: 97vw;
  height: 35vh;
  padding: 1.5vw;
  z-index: 99;
  margin-top: -5.5vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 0.8em;
  top: 110vh;
  display: none;
  background-color: black;
}
@media (min-width: 991.98px) {
  .videoPopUpContainer .videoPopUpFixedOverlay {
    margin-top: -2.5vh;
    height: 45vh;
  }
}
@media (min-width: 1199.98px) {
  .videoPopUpContainer .videoPopUpFixedOverlay {
    margin-top: 0vh;
    height: 90vh;
    width: 80vw;
    padding: 4vh;
  }
}
.videoPopUpContainer .videoPopUpFixedOverlay .exitBtn {
  cursor: pointer;
  top: 1.5vh;
  right: 2.5vh;
  position: absolute;
  z-index: 1001 !important;
}
@media (min-width: 1199.98px) {
  .videoPopUpContainer .videoPopUpFixedOverlay .exitBtn {
    top: 3.5vh;
    right: 3.5vh;
  }
}
.videoPopUpContainer .videoPopUpFixedOverlay .exitBtn .parentTmpButton .dsRoundBtn:hover {
  transform: scale(1.1);
}
.videoPopUpContainer .videoPopUpFixedOverlay .exitBtn .parentTmpButton .dsRoundBtn:active, .videoPopUpContainer .videoPopUpFixedOverlay .exitBtn .parentTmpButton .dsRoundBtn:focus {
  background-color: black;
}
.videoPopUpContainer .videoPopUpFixedOverlay .parentTmpButton .dsRoundBtn .dashedLine {
  visibility: hidden;
}
.videoPopUpContainer .videoPopUpFixedOverlay .overlayVideoWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 90%;
}
@media (min-width: 991.98px) {
  .videoPopUpContainer .videoPopUpFixedOverlay .overlayVideoWrapper {
    width: 90%;
  }
}
.videoPopUpContainer .videoPopUpFixedOverlay .overlayVideoWrapper .jw-wrapper {
  border-radius: 0.8em !important;
  overflow: hidden !important;
}

#ZetInAgendaModule {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
#ZetInAgendaModule .zetInAgendaBtnWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1em;
  padding: 1em 0;
}
@media (min-width: 991.98px) {
  #ZetInAgendaModule .zetInAgendaBtnWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    margin-bottom: 5em;
  }
}
#ZetInAgendaModule .zetInAgendaBtnWrapper .dfwBtn {
  font-size: 1.2em;
  padding: 1rem 1.5rem;
}

.standaardHero {
  width: 100vw;
  height: -moz-fit-content;
  height: fit-content;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  background-color: #3385DB;
  height: 100vh;
}
.standaardHero .tmpBtn {
  opacity: 0;
}
.standaardHero .scrollDownButton {
  z-index: 10;
  position: absolute;
  cursor: pointer;
  bottom: 0;
  margin-bottom: 15vh;
}
@media (min-width: 767.98px) {
  .standaardHero .scrollDownButton {
    margin-bottom: 15vh;
    transform: scale(1.35);
  }
}
@media (min-width: 1399.98px) {
  .standaardHero .scrollDownButton {
    margin-bottom: 5em;
    right: 7.5em;
  }
}
.standaardHero .svgOverlay {
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  pointer-events: none;
}
.standaardHero .svgOverlay svg {
  min-width: 105vw;
  height: -moz-fit-content;
  height: fit-content;
  position: absolute;
  bottom: 0vh;
  fill: #3385DB;
}
.standaardHero .parent {
  width: 100vw;
  background-color: #3385DB;
  position: relative;
  position: relative;
  width: 92.5vw;
  padding: 7.5vh 3.75vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-bottom: 0;
}
@media (min-width: 767.98px) {
  .standaardHero .parent {
    width: 90vw;
    padding: 3em 5vw;
  }
}
@media (min-width: 1399.98px) {
  .standaardHero .parent {
    width: 80vw;
    padding: 0vh 10vw;
  }
}
@media (min-width: 1699.98px) {
  .standaardHero .parent {
    width: 75vw;
    padding: 0vh 12.5vw;
  }
}
@media (min-width: 2299.98px) {
  .standaardHero .parent {
    width: 60vw;
    padding: 0vh 20vw;
  }
}
.standaardHero .parent .inner {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100% !important;
  width: -moz-fit-content;
  width: fit-content;
  gap: 2.5vh;
  height: 80%;
}
@media (min-width: 991.98px) {
  .standaardHero .parent .inner {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    height: 100%;
  }
}
@media (min-width: 1199.98px) {
  .standaardHero .parent .inner {
    height: 100%;
  }
}
.standaardHero .parent .inner .logo {
  width: 25%;
  left: 0;
}
@media (min-width: 991.98px) {
  .standaardHero .parent .inner aside {
    max-width: 80vw;
  }
}
.standaardHero .parent .inner .ph-2,
.standaardHero .parent .inner .ph-1 {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1em;
  max-height: 100vh;
}
@media (min-width: 991.98px) {
  .standaardHero .parent .inner .ph-2,
  .standaardHero .parent .inner .ph-1 {
    width: 50%;
  }
}
.standaardHero .parent .inner .ph-2 .SwiperComponentCoreWrapper,
.standaardHero .parent .inner .ph-1 .SwiperComponentCoreWrapper {
  width: 100%;
  max-width: 100vw;
  color: black;
}
.standaardHero .parent .overlay,
.standaardHero .parent .gradient {
  width: 100vw;
  height: 100%;
  position: absolute;
  bottom: 0;
}
.standaardHero .parent .gradient {
  background: linear-gradient(0deg, rgb(0, 0, 0) 0%, rgba(0, 0, 0, 0) 100%);
}
.standaardHero .parent .bgImgWrapper {
  width: 100%;
  height: 100%;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: absolute;
  overflow: hidden;
  justify-content: flex-end;
}
.standaardHero .parent .bgImgWrapper video {
  min-width: 100vw;
  min-height: 100vh;
  position: absolute;
  top: 0;
}
.standaardHero .parent .bgImgWrapper .bgImgContainer {
  width: 100vw;
  height: 100vh;
  position: absolute;
}
.standaardHero .parent .bgImgWrapper .bgImg {
  position: absolute;
  width: 100%;
}

#standaardHero0 {
  background-size: 100% 100%;
  background-position: 0px 0px, 0px 0px;
  background-image: radial-gradient(50% 50% at 87% 107%, #FB0207 -50%, rgba(251, 2, 7, 0.1882352941) 3%, rgba(7, 58, 255, 0) 100%), radial-gradient(100% 91% at 54% 72%, #d2e8ff 0%, #E5F2FF 100%);
  height: 95vh;
}
#standaardHero0 .scrollDownButton {
  display: none;
}
@media (min-width: 991.98px) {
  #standaardHero0 .scrollDownButton {
    display: flex;
  }
}
#standaardHero0 .parent {
  min-height: 100vh;
  justify-content: flex-start;
  background-color: transparent;
}
#standaardHero0 .parent .TextComponentCore {
  color: #0f3660;
}
#standaardHero0 .parent .inner {
  margin-top: -5vh;
  flex-direction: column;
}
@media (min-width: 991.98px) {
  #standaardHero0 .parent .inner {
    margin-top: -5vh;
    flex-direction: row-reverse;
  }
}
#standaardHero0 .parent .inner .swiperSlide {
  margin-top: 2.5vh;
  height: 45vh;
  max-height: 22.5em;
}
@media (min-width: 991.98px) {
  #standaardHero0 .parent .inner .swiperSlide {
    margin-top: 0vh;
    height: 50vh;
  }
}
#standaardHero0 .parent .inner .ph-1,
#standaardHero0 .parent .inner .ph-2 {
  max-width: 100vw;
}
#standaardHero0 .parent .inner .ph-2 {
  order: 1;
  width: 90%;
}
@media (min-width: 991.98px) {
  #standaardHero0 .parent .inner .ph-2 {
    height: 100%;
    width: 40%;
  }
}
#standaardHero0 .parent .inner .ph-1 {
  color: white;
  order: 2;
}
#standaardHero0 .parent .inner .ph-1 .TextComponentCore {
  align-items: center;
  text-align: center;
  width: 100%;
}
#standaardHero0 .parent .inner .ph-1 h1 {
  order: 1;
}
#standaardHero0 .parent .inner .ph-1 p {
  order: 3;
}
#standaardHero0 .parent .inner .ph-1 .btnWrapper {
  order: 2;
}
#standaardHero0 .parent .bgImgContainer {
  width: 100%;
  height: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
#standaardHero0 .parent .bgImgContainer .bgImg {
  width: 165vw;
  transform: rotate(-10deg);
  margin-top: -35vw;
  margin-right: -35vw;
}
#standaardHero0 .parent .gradient {
  background: linear-gradient(0deg, rgb(0, 0, 0) 0%, rgba(0, 0, 0, 0) 100%);
  height: 100%;
  opacity: 1;
  position: absolute;
}

#standaardHero1 .ImageComponentCore a {
  height: 85%;
}
#standaardHero1 .parent {
  min-height: 100vh;
  justify-content: flex-start;
}
#standaardHero1 .parent .inner {
  flex-direction: column;
  height: 100vh;
}
#standaardHero1 .parent .inner .swiperSlide {
  margin-top: 2.5vh;
  height: 45vh;
  max-height: 22.5em;
}
@media (min-width: 991.98px) {
  #standaardHero1 .parent .inner .swiperSlide {
    margin-top: 0vh;
    height: 50vh;
  }
}
#standaardHero1 .parent .inner .ph-1,
#standaardHero1 .parent .inner .ph-2 {
  max-width: 100vw;
}
#standaardHero1 .parent .inner .ph-2 {
  order: 1;
  max-height: 30vh;
}
@media (min-width: 767.98px) {
  #standaardHero1 .parent .inner .ph-2 {
    max-height: 25vh;
    margin-top: -35vh;
  }
}
@media (min-width: 1399.98px) {
  #standaardHero1 .parent .inner .ph-2 {
    margin-top: unset;
  }
}
#standaardHero1 .parent .inner .ph-1 {
  color: white;
  order: 2;
  width: 100%;
}
#standaardHero1 .parent .inner .ph-1 .TextComponentCore {
  align-items: center;
  text-align: center;
  gap: 0.5em;
}
#standaardHero1 .parent .gradient {
  background: linear-gradient(0deg, rgb(0, 0, 0) 0%, rgba(0, 0, 0, 0) 100%);
  height: 100%;
  position: absolute;
}

#standaardHero2 .parent {
  min-height: 100vh;
  justify-content: flex-start;
}
#standaardHero2 .parent .inner .swiperSlide {
  margin-top: 2.5vh;
  height: 45vh;
  max-height: 22.5em;
}
@media (min-width: 991.98px) {
  #standaardHero2 .parent .inner .swiperSlide {
    margin-top: 0vh;
    height: 50vh;
  }
}
#standaardHero2 .parent .inner .ph-1,
#standaardHero2 .parent .inner .ph-2 {
  max-width: 100vw;
}
#standaardHero2 .parent .inner .ph-2 {
  order: 1;
  height: 100%;
}
#standaardHero2 .parent .inner .ph-1 {
  color: white;
  order: 2;
  width: 100%;
}
#standaardHero2 .parent .inner .ph-1 .TextComponentCore {
  align-items: center;
  text-align: center;
}
#standaardHero2 .parent .gradient {
  background: linear-gradient(0deg, rgb(0, 0, 0) 0%, rgba(0, 0, 0, 0) 100%);
  height: 100%;
  position: absolute;
}

.StandaardInfo {
  width: 100vw;
  height: -moz-fit-content;
  height: fit-content;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #3385DB;
}
.StandaardInfo .parent {
  width: 100vw;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.StandaardInfo .parent .inner {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: -moz-fit-content;
  width: fit-content;
  gap: 5vw;
  position: relative;
}
@media (min-width: 767.98px) {
  .StandaardInfo .parent .inner {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
  }
}
.StandaardInfo .parent .inner .innerBg {
  width: 100%;
  height: 100%;
  position: absolute;
}
@media (min-width: 991.98px) {
  .StandaardInfo .parent .inner aside {
    max-width: 80vw;
  }
}
.StandaardInfo .parent .inner .ph-2,
.StandaardInfo .parent .inner .ph-1 {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}
@media (min-width: 991.98px) {
  .StandaardInfo .parent .inner .ph-2,
  .StandaardInfo .parent .inner .ph-1 {
    width: 50%;
  }
}
.StandaardInfo .parent .overlay,
.StandaardInfo .parent .gradient {
  width: 100vw;
  height: 100%;
  position: absolute;
  top: 0;
}
.StandaardInfo .parent .bgImgWrapper {
  width: 100%;
  height: 100%;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: absolute;
}
.StandaardInfo .parent .bgImgWrapper video {
  min-width: 100vw;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.StandaardInfo .parent .bgImgWrapper .bgImgContainer {
  width: 100vw;
  height: 100vh;
  position: relative;
}
.StandaardInfo .parent .bgImgWrapper .bgImg {
  position: absolute;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

#StandaardInfo0 {
  background-color: #3385DB;
}
#StandaardInfo0 .parent .inner {
  gap: 5vh;
  width: 82%;
  padding: 15% 7.5%;
  margin-top: -10vh;
  border-radius: 1.6em;
  overflow: hidden;
  background-color: #d2e8ff;
}
@media (min-width: 767.98px) {
  #StandaardInfo0 .parent .inner {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
}
@media (min-width: 991.98px) {
  #StandaardInfo0 .parent .inner {
    width: 65%;
    padding: 5% 7.5%;
    gap: 2.5vw;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row-reverse;
  }
}
#StandaardInfo0 .parent .inner > * {
  color: #0f3660;
}
#StandaardInfo0 .parent .inner .btnWrapper {
  align-items: flex-start;
}
#StandaardInfo0 .parent .inner .ph-2 {
  order: 1;
}
#StandaardInfo0 .parent .inner .ph-1 {
  order: 2;
  flex-direction: row;
}
#StandaardInfo0 .parent .inner .ph-1 .TextComponentCore {
  text-align: left;
  align-items: flex-start;
}
#StandaardInfo0 .parent .inner .ph-1 .image {
  width: 100%;
}

#StandaardInfo1 {
  background-color: #3385DB;
}
@media (min-width: 991.98px) {
  #StandaardInfo1 {
    padding-top: 5vh;
  }
}
@media (min-width: 1199.98px) {
  #StandaardInfo1 {
    padding: 10vh 0vh;
  }
}
#StandaardInfo1 .parent .inner {
  gap: 2.5vw;
  border-radius: 1.6em;
  position: relative;
  width: 92.5vw;
  padding: 7.5vh 3.75vw;
  flex-direction: row-reverse;
}
@media (min-width: 767.98px) {
  #StandaardInfo1 .parent .inner {
    width: 90vw;
    padding: 3em 5vw;
  }
}
@media (min-width: 1399.98px) {
  #StandaardInfo1 .parent .inner {
    width: 80vw;
    padding: 0vh 10vw;
  }
}
@media (min-width: 1699.98px) {
  #StandaardInfo1 .parent .inner {
    width: 75vw;
    padding: 0vh 12.5vw;
  }
}
@media (min-width: 2299.98px) {
  #StandaardInfo1 .parent .inner {
    width: 60vw;
    padding: 0vh 20vw;
  }
}
#StandaardInfo1 .parent .inner > * {
  color: #0f3660;
}
#StandaardInfo1 .parent .inner .ph-1 {
  flex-direction: row;
  color: white;
  width: 100%;
}
#StandaardInfo1 .parent .inner .ph-1 .image {
  width: 100%;
}

#StandaardInfo2 {
  background-color: #3385DB;
}
#StandaardInfo2 .ImageComponentCore a {
  padding: 10% 0;
}
#StandaardInfo2 .ImageComponentCore a img {
  width: 80%;
  height: 80%;
}
#StandaardInfo2 .TextComponentCore {
  text-align: start;
  align-items: flex-start;
}
#StandaardInfo2 .TextComponentCore .btnWrapper {
  flex-direction: column;
}
@media (min-width: 991.98px) {
  #StandaardInfo2 {
    padding-top: 5vh;
  }
}
@media (min-width: 1199.98px) {
  #StandaardInfo2 {
    padding: 10vh 0vh;
  }
}
#StandaardInfo2 .parent .inner {
  gap: 5vh;
  border-radius: 1.6em;
  position: relative;
  width: 92.5vw;
  padding: 7.5vh 3.75vw;
}
@media (min-width: 767.98px) {
  #StandaardInfo2 .parent .inner {
    width: 90vw;
    padding: 3em 5vw;
  }
}
@media (min-width: 1399.98px) {
  #StandaardInfo2 .parent .inner {
    width: 80vw;
    padding: 0vh 10vw;
  }
}
@media (min-width: 1699.98px) {
  #StandaardInfo2 .parent .inner {
    width: 75vw;
    padding: 0vh 12.5vw;
  }
}
@media (min-width: 2299.98px) {
  #StandaardInfo2 .parent .inner {
    width: 60vw;
    padding: 0vh 20vw;
  }
}
@media (min-width: 991.98px) {
  #StandaardInfo2 .parent .inner {
    gap: 2.5vw;
  }
}
#StandaardInfo2 .parent .inner > * {
  color: #0f3660;
}
#StandaardInfo2 .parent .inner .ph-2 {
  order: 1;
  width: 100%;
  height: 100%;
}
@media (min-width: 991.98px) {
  #StandaardInfo2 .parent .inner .ph-2 {
    width: 40%;
    min-height: 40vh;
  }
}
#StandaardInfo2 .parent .inner .ph-2 .ImageComponentCore {
  width: 90%;
  overflow: visible;
  box-shadow: 0 10px 20px rgba(42, 42, 42, 0.1), 0 3px 6px rgba(0, 0, 0, 0.066);
}
#StandaardInfo2 .parent .inner .ph-2 .ImageComponentCore .bgLayer {
  background-color: #d2e8ff;
  border-radius: 0.8em;
}
#StandaardInfo2 .parent .inner .ph-2 .ImageComponentCore img {
  z-index: 1;
}
#StandaardInfo2 .parent .inner .btnWrapper {
  align-items: flex-start;
}
#StandaardInfo2 .parent .inner .ph-1 {
  order: 2;
  min-height: 40vh;
  flex-direction: row;
  color: white;
}
#StandaardInfo2 .parent .inner .ph-1 .image {
  width: 100%;
}

.singleViewSwiper .SwiperComponentCoreWrapper {
  overflow: hidden !important;
  padding: 0 !important;
  border-radius: 1.6em;
  background-color: rgba(0, 0, 86, 0.2078431373);
  border: 0.45em rgba(0, 0, 86, 0.2078431373) solid;
}

.StandaardImageViewer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #3385DB;
  width: 100vw;
  position: relative;
  gap: 2.5em;
  position: relative;
  width: 92.5vw;
  padding: 7.5vh 3.75vw;
  padding-top: 2.5vh;
  padding-bottom: 2.5vh;
}
@media (min-width: 767.98px) {
  .StandaardImageViewer {
    width: 90vw;
    padding: 3em 5vw;
  }
}
@media (min-width: 1399.98px) {
  .StandaardImageViewer {
    width: 80vw;
    padding: 0vh 10vw;
  }
}
@media (min-width: 1699.98px) {
  .StandaardImageViewer {
    width: 75vw;
    padding: 0vh 12.5vw;
  }
}
@media (min-width: 2299.98px) {
  .StandaardImageViewer {
    width: 60vw;
    padding: 0vh 20vw;
  }
}
@media (min-width: 991.98px) {
  .StandaardImageViewer {
    position: relative;
    width: 92.5vw;
    padding: 7.5vh 3.75vw;
  }
}
@media (min-width: 991.98px) and (min-width: 767.98px) {
  .StandaardImageViewer {
    width: 90vw;
    padding: 3em 5vw;
  }
}
@media (min-width: 991.98px) and (min-width: 1399.98px) {
  .StandaardImageViewer {
    width: 80vw;
    padding: 0vh 10vw;
  }
}
@media (min-width: 991.98px) and (min-width: 1699.98px) {
  .StandaardImageViewer {
    width: 75vw;
    padding: 0vh 12.5vw;
  }
}
@media (min-width: 991.98px) and (min-width: 2299.98px) {
  .StandaardImageViewer {
    width: 60vw;
    padding: 0vh 20vw;
  }
}
.StandaardImageViewer .title {
  color: #0f3660;
  z-index: 1;
}
@media (min-width: 1399.98px) {
  .StandaardImageViewer .SwiperComponentCoreWrapper {
    padding-top: 15vh;
    padding-bottom: 10vh;
  }
}
.StandaardImageViewer .bgLayer {
  width: 100vw;
  position: absolute;
  height: 30vh;
  bottom: 0;
}
.StandaardImageViewer .SwiperComponentCore {
  width: 100%;
  max-width: 100em;
  border-radius: 1.6em;
}
.StandaardImageViewer .swiperFullWidth .swiper-wrapper .swiperSlide {
  height: 50vh;
}
@media (min-width: 991.98px) {
  .StandaardImageViewer .swiperFullWidth .swiper-wrapper .swiperSlide {
    height: 100vh;
  }
  .StandaardImageViewer .swiperFullWidth .swiper-wrapper .swiperSlide:hover .backgroundImg {
    transform: scale(1) rotate(0deg);
  }
}

.singleViewSwiper .swiper-wrapper {
  padding: 0 !important;
}
.singleViewSwiper .swiperSlide {
  height: 70vh !important;
  max-height: 20em;
}
@media (min-width: 991.98px) {
  .singleViewSwiper .swiperSlide {
    max-height: unset;
  }
}
.singleViewSwiper .swiper-button-prev {
  margin-left: 7em !important;
}
.singleViewSwiper .swiper-button-next {
  margin-right: 7em !important;
}

.StandaardImageViewer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #3385DB;
}
.StandaardImageViewer .articleHz,
.StandaardImageViewer .title {
  color: white;
}
@media (min-width: 991.98px) {
  .StandaardImageViewer .SwiperComponentCoreWrapper {
    padding: 5vh;
  }
}
.StandaardImageViewer .swiper {
  overflow: visible;
}
.StandaardImageViewer .swiper-wrapper .swiperSlide {
  overflow: hidden;
  border-radius: 0.8em;
  height: -moz-fit-content;
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 0px;
}
.StandaardImageViewer .swiper-wrapper .swiperSlide .backgroundImg {
  position: relative;
}

#StandaardVideoAll {
  background-color: #3385DB;
  /* width */
  /* Track */
  /* Handle */
  /* Handle on hover */
}
@media (min-width: 767.98px) {
  #StandaardVideoAll {
    padding-top: 5vh;
    padding-bottom: 5vh;
  }
}
#StandaardVideoAll .videoPopUpContainer {
  padding-top: 0vh;
}
@media (min-width: 991.98px) {
  #StandaardVideoAll .videoPopUpContainer {
    padding-top: 4vh;
  }
}
#StandaardVideoAll .TextPhVideoPopUpComponent {
  position: relative;
  width: 92.5vw;
  padding: 7.5vh 3.75vw;
}
@media (min-width: 767.98px) {
  #StandaardVideoAll .TextPhVideoPopUpComponent {
    width: 90vw;
    padding: 3em 5vw;
  }
}
@media (min-width: 1399.98px) {
  #StandaardVideoAll .TextPhVideoPopUpComponent {
    width: 80vw;
    padding: 0vh 10vw;
  }
}
@media (min-width: 1699.98px) {
  #StandaardVideoAll .TextPhVideoPopUpComponent {
    width: 75vw;
    padding: 0vh 12.5vw;
  }
}
@media (min-width: 2299.98px) {
  #StandaardVideoAll .TextPhVideoPopUpComponent {
    width: 60vw;
    padding: 0vh 20vw;
  }
}
#StandaardVideoAll ::-webkit-scrollbar {
  width: 10px;
}
#StandaardVideoAll ::-webkit-scrollbar-track {
  border: 1px solid rgba(0, 0, 0, 0);
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 10px;
}
#StandaardVideoAll ::-webkit-scrollbar-thumb {
  border: 1px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  border-radius: 9999px;
  background-color: white;
}
#StandaardVideoAll ::-webkit-scrollbar-thumb:hover {
  border: 2px solid rgba(0, 0, 0, 0);
  background: rgba(255, 255, 255, 0.768);
}
#StandaardVideoAll .TextComponentCore {
  color: white;
}
@media (min-width: 991.98px) {
  #StandaardVideoAll .TextComponentCore {
    width: 80%;
    padding-right: 20%;
  }
  #StandaardVideoAll .TextComponentCore h2 {
    width: 80%;
  }
}

.StandaardVideo {
  background-color: #3385DB;
  position: relative;
  width: 92.5vw;
  padding: 7.5vh 3.75vw;
}
@media (min-width: 767.98px) {
  .StandaardVideo {
    width: 90vw;
    padding: 3em 5vw;
  }
}
@media (min-width: 1399.98px) {
  .StandaardVideo {
    width: 80vw;
    padding: 0vh 10vw;
  }
}
@media (min-width: 1699.98px) {
  .StandaardVideo {
    width: 75vw;
    padding: 0vh 12.5vw;
  }
}
@media (min-width: 2299.98px) {
  .StandaardVideo {
    width: 60vw;
    padding: 0vh 20vw;
  }
}
@media (min-width: 991.98px) {
  .StandaardVideo {
    position: relative;
    width: 92.5vw;
    padding: 7.5vh 3.75vw;
  }
}
@media (min-width: 991.98px) and (min-width: 767.98px) {
  .StandaardVideo {
    width: 90vw;
    padding: 3em 5vw;
  }
}
@media (min-width: 991.98px) and (min-width: 1399.98px) {
  .StandaardVideo {
    width: 80vw;
    padding: 0vh 10vw;
  }
}
@media (min-width: 991.98px) and (min-width: 1699.98px) {
  .StandaardVideo {
    width: 75vw;
    padding: 0vh 12.5vw;
  }
}
@media (min-width: 991.98px) and (min-width: 2299.98px) {
  .StandaardVideo {
    width: 60vw;
    padding: 0vh 20vw;
  }
}
.StandaardVideo .singleVideoWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  perspective: 1000px;
  margin: 5vh 0vh;
}
.StandaardVideo .singleVideoWrapper .singleVideoPlaceholder {
  width: 100%;
  border-radius: 1.6em;
  overflow: hidden;
}
.StandaardVideo .textContainer {
  color: #0f3660;
}
@media (min-width: 991.98px) {
  .StandaardVideo .textContainer {
    width: 100%;
  }
}
.StandaardVideo .VideoComponent {
  width: 100%;
  min-height: 10em;
  height: -moz-fit-content;
  height: fit-content;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 5vh 0vh;
}
.StandaardVideo .wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  position: relative;
  width: 92.5vw;
  padding: 7.5vh 3.75vw;
  padding-bottom: 0;
}
@media (min-width: 767.98px) {
  .StandaardVideo .wrapper {
    width: 90vw;
    padding: 3em 5vw;
  }
}
@media (min-width: 1399.98px) {
  .StandaardVideo .wrapper {
    width: 80vw;
    padding: 0vh 10vw;
  }
}
@media (min-width: 1699.98px) {
  .StandaardVideo .wrapper {
    width: 75vw;
    padding: 0vh 12.5vw;
  }
}
@media (min-width: 2299.98px) {
  .StandaardVideo .wrapper {
    width: 60vw;
    padding: 0vh 20vw;
  }
}
@media (min-width: 991.98px) {
  .StandaardVideo .wrapper {
    width: unset;
  }
}
.StandaardVideo .titleContainer {
  z-index: 1;
  width: 100%;
  margin-left: 10%;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
  gap: 5%;
}
@media (min-width: 991.98px) {
  .StandaardVideo .titleContainer {
    gap: 1em;
    margin-left: unset;
  }
}
.StandaardVideo .titleContainer .aantal {
  font-size: 1em;
}
.StandaardVideo .backgroundImageContainer {
  width: 80vw;
  height: 50vw;
  top: 5vh;
  right: -20vw;
  opacity: 0.45;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: absolute;
  z-index: 0;
  overflow: hidden;
}
@media (min-width: 991.98px) {
  .StandaardVideo .backgroundImageContainer {
    width: 80vw;
    height: 50vw;
    right: 0%;
    opacity: 1;
    top: 2vw;
  }
}
@media (min-width: 1199.98px) {
  .StandaardVideo .backgroundImageContainer {
    width: 45vw;
    height: 40vw;
    right: 15%;
  }
}
.StandaardVideo .backgroundImageContainer img {
  position: absolute;
  width: 120%;
  height: 120%;
  -o-object-fit: cover;
     object-fit: cover;
  filter: blur(5px);
}
.StandaardVideo .backgroundImageContainer .gradient {
  position: absolute;
  width: 100%;
  top: 0%;
  height: 100%;
}
.StandaardVideo .videoPlayer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
  border-radius: 0.8em;
  z-index: 1;
  position: relative;
  padding: 2vh 0%;
  width: 105%;
  background: radial-gradient(circle, rgba(100, 100, 100, 0) 20%, rgba(0, 0, 0, 0.15) 80%);
}
@media (min-width: 991.98px) {
  .StandaardVideo .videoPlayer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    padding: unset;
    width: 100%;
  }
}
.StandaardVideo .videoPlayer .videoPlayerBackgroundColor {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  display: none;
  z-index: -1;
  background-color: rgba(62, 62, 62, 0.122);
}
@media (min-width: 1199.98px) {
  .StandaardVideo .videoPlayer .videoPlayerBackgroundColor {
    display: unset;
  }
}
.StandaardVideo .videoPlayer .players-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100vw;
  position: relative;
  height: 100%;
}
.StandaardVideo .videoPlayer .players-container .relatedVideos {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 7%;
  position: relative;
  margin-top: 3.5%;
}
.StandaardVideo .videoPlayer .players-container .relatedVideos .title {
  position: absolute;
  font-weight: 500;
  font-size: 1.25em;
  color: white;
}
.StandaardVideo .videoPlayer .players-container .innerScroll {
  overflow: hidden;
  overflow-y: scroll;
  height: 90%;
  margin: 0 1em;
  padding-right: 0.5em;
}
.StandaardVideo .videoPlayer .players-container .swiperWrapper {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #d2e8ff;
  box-shadow: 0 10px 20px rgba(42, 42, 42, 0.1), 0 3px 6px rgba(0, 0, 0, 0.066);
  width: 80%;
  border-radius: 0.8em;
  padding-bottom: 5%;
  padding-top: 2.5%;
  margin-top: -2.5em;
  gap: 1vh;
}
.StandaardVideo .videoPlayer .players-container .swiperWrapper .swiperTitleContainer {
  width: 80%;
  margin-bottom: 0.25em;
  text-align: center;
}
.StandaardVideo .videoPlayer .players-container .swiperWrapper .swiper-wrapper {
  align-items: center;
}
.StandaardVideo .videoPlayer .players-container .swiperWrapper h4 {
  color: #0f3660;
  font-weight: 300;
  font-size: 0.9em;
}
.StandaardVideo .videoPlayer .players-container .swiperWrapper .swiper-button-next,
.StandaardVideo .videoPlayer .players-container .swiperWrapper .swiper-button-prev {
  color: #FB0207 !important;
  margin-top: -10%;
  transform: scale(0.75);
}
.StandaardVideo .videoPlayer .players-container .swiperWrapper .swiper-pagination {
  bottom: -1em !important;
}
.StandaardVideo .videoPlayer .players-container .swiperWrapper .vidSwiper {
  height: 7em;
  width: 100%;
}
.StandaardVideo .videoPlayer .players-container .swiperWrapper .vidSwiper .swiper-slide {
  transition: all none;
  background-color: #fff;
}
.StandaardVideo .videoPlayer .players-container .swiperWrapper .vidSwiper .swiper-slide .slideThumb {
  position: absolute;
  min-width: 110%;
  min-height: 110%;
  -o-object-fit: cover;
     object-fit: cover;
}
.StandaardVideo .videoPlayer .players-container .swiperWrapper .vidSwiper .swiper-slide .dsRoundBtn {
  height: 2.4em;
  width: 2.4em;
}
.StandaardVideo .videoPlayer .players-container .swiperWrapper .vidSwiper .swiper-slide .dsRoundBtn .dashedLine {
  width: 90%;
  height: 90%;
}
.StandaardVideo .videoPlayer .players-container .swiperWrapper .vidSwiper .swiper-slide .dsRoundBtn .arrowInside {
  font-size: 0.8em;
}
.StandaardVideo .videoPlayer .players-container .swiperWrapper .vidSwiper .swiper-slide .parentTmpButton {
  pointer-events: none !important;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}
.StandaardVideo .videoPlayer .players-container .swiperWrapper .vidSwiper .swiper-slide .parentTmpButton > * {
  pointer-events: none !important;
}
.StandaardVideo .videoPlayer .players-container .swiperWrapper .vidSwiper .swiper-slide .parentTmpButton a {
  pointer-events: none !important;
}
.StandaardVideo .videoPlayer .players-container .swiperWrapper .vidSwiper .swiper-slide .parentTmpButton a .dashedLine {
  pointer-events: none;
}
.StandaardVideo .videoPlayer .players-container .swiperWrapper .vidSwiper .swiper-slide .parentTmpButton a div {
  pointer-events: none !important;
}
.StandaardVideo .videoPlayer .mainPlayerPlaceholder {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: -moz-fit-content;
  width: fit-content;
  background-color: black;
  border-radius: 0.8em;
  overflow: hidden;
  position: relative;
}
@media (min-width: 991.98px) {
  .StandaardVideo .videoPlayer .mainPlayerPlaceholder {
    padding: unset;
    overflow: unset;
  }
}
@media (min-width: 575.98px) {
  .StandaardVideo .videoPlayer .mainPlayerPlaceholder .jwplayer {
    width: 480px !important;
  }
}
@media (min-width: 767.98px) {
  .StandaardVideo .videoPlayer .mainPlayerPlaceholder .jwplayer {
    width: 640px !important;
  }
}
@media (min-width: 991.98px) {
  .StandaardVideo .videoPlayer .mainPlayerPlaceholder .jwplayer {
    width: 640px !important;
  }
}
@media (min-width: 1399.98px) {
  .StandaardVideo .videoPlayer .mainPlayerPlaceholder .jwplayer {
    width: 854px !important;
  }
}
@media (min-width: 1699.98px) {
  .StandaardVideo .videoPlayer .mainPlayerPlaceholder .jwplayer {
    width: 1024px !important;
  }
}
.StandaardVideo .videoPlayer .mainPlayerPlaceholder .jwplayer .jw-display-controls {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}
.StandaardVideo .videoPlayer .mainPlayerPlaceholder .jwplayer .jw-display-controls .jw-display-icon-next,
.StandaardVideo .videoPlayer .mainPlayerPlaceholder .jwplayer .jw-display-controls .jw-display-icon-rewind,
.StandaardVideo .videoPlayer .mainPlayerPlaceholder .jwplayer .jw-display-controls .jw-icon-rewind {
  display: none;
}
.StandaardVideo .videoPlayer .mainPlayerPlaceholder .jwplayer .jw-display-controls .jw-button-color {
  background-color: #FB0207;
  padding: 1.125rem;
  border-radius: 4000px;
}
@media (min-width: 575.98px) {
  .StandaardVideo .videoPlayer .players-container,
  .StandaardVideo .videoPlayer .jwplayer {
    height: 270px !important;
    width: 90%;
  }
}
@media (min-width: 767.98px) {
  .StandaardVideo .videoPlayer .players-container,
  .StandaardVideo .videoPlayer .jwplayer {
    height: 360px !important;
    width: 90%;
  }
}
@media (min-width: 991.98px) {
  .StandaardVideo .videoPlayer .players-container,
  .StandaardVideo .videoPlayer .jwplayer {
    height: 405px !important;
  }
}
@media (min-width: 1399.98px) {
  .StandaardVideo .videoPlayer .players-container,
  .StandaardVideo .videoPlayer .jwplayer {
    height: 480px !important;
  }
}
@media (min-width: 1699.98px) {
  .StandaardVideo .videoPlayer .players-container,
  .StandaardVideo .videoPlayer .jwplayer {
    height: 576px !important;
  }
}
.StandaardVideo .videoPlayer .thumbWrapper {
  width: 100%;
  position: relative;
  border-radius: 0.8em;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  justify-content: normal;
  gap: 1.5em;
  padding: 5% 0;
}
.StandaardVideo .videoPlayer .thumbWrapper .thumbItem {
  min-width: 15em;
  width: 15vw;
}
@media (min-width: 1199.98px) {
  .StandaardVideo .videoPlayer .thumbWrapper .thumbItem {
    min-width: 20em;
    width: 20vw;
  }
}
.StandaardVideo .videoPlayer .thumbWrapper .thumbContainer {
  width: 100%;
  height: 12.5em;
}
@media (min-width: 1199.98px) {
  .StandaardVideo .videoPlayer .thumbWrapper .thumbContainer {
    height: 15em;
  }
}
.StandaardVideo .videoPlayer .thumbWrapper .thumbnail {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 9.5em;
  overflow: hidden;
  position: relative;
  border-radius: 0.8em;
  box-shadow: 0 10px 20px rgba(42, 42, 42, 0.1), 0 3px 6px rgba(0, 0, 0, 0.066);
  transition: all 0.25s;
  cursor: pointer;
  background-color: black;
  transform: scale(0.925) translateY(0.25em);
}
@media (min-width: 1199.98px) {
  .StandaardVideo .videoPlayer .thumbWrapper .thumbnail {
    height: 12em;
  }
}
.StandaardVideo .videoPlayer .thumbWrapper .thumbnail:hover {
  opacity: 0.5;
  transform: scale(0.94) translateY(0.25em);
}
.StandaardVideo .videoPlayer .thumbWrapper .thumbnail .parentTmpButton {
  pointer-events: none !important;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}
.StandaardVideo .videoPlayer .thumbWrapper .thumbnail .parentTmpButton a {
  pointer-events: none !important;
}
.StandaardVideo .videoPlayer .thumbWrapper .thumbnail .parentTmpButton a div {
  pointer-events: none !important;
}
.StandaardVideo .videoPlayer .thumbWrapper .thumbnail .thumbImg {
  min-width: 120%;
  min-height: 120%;
  -o-object-fit: cover;
     object-fit: cover;
  -webkit-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
}
.StandaardVideo .videoPlayer .thumbWrapper .thumbSubDescription {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  width: 90%;
  margin-top: 0.5em;
  margin-left: 5%;
  margin-bottom: 2em;
}

.StandaardVideo {
  background-color: #3385DB;
  padding-top: 5vh;
  padding-bottom: 5vh;
}
.StandaardVideo .titleContainer .title {
  color: white;
}
.StandaardVideo .titleContainer .aantal {
  color: #FB0207;
}
.StandaardVideo .thumbnail {
  background-color: black;
}
.StandaardVideo .thumbContainer {
  background-color: #d2e8ff;
  border-radius: 0.8em;
}
.StandaardVideo .thumbSubDescription {
  color: #0f3660;
}
.StandaardVideo .thumbSubDescription .duration {
  color: #0f3660;
  background-color: rgba(255, 255, 255, 0.25);
  border-radius: 0.2em;
  padding: 0.1875rem 0.75rem;
}

.StandaardRetail {
  position: relative;
  width: 92.5vw;
  padding: 7.5vh 3.75vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 10em;
  background-color: #e60f13;
  gap: 2.5em;
  padding-top: 10vh !important;
  padding-bottom: 17.5vh !important;
}
@media (min-width: 767.98px) {
  .StandaardRetail {
    width: 90vw;
    padding: 3em 5vw;
  }
}
@media (min-width: 1399.98px) {
  .StandaardRetail {
    width: 80vw;
    padding: 0vh 10vw;
  }
}
@media (min-width: 1699.98px) {
  .StandaardRetail {
    width: 75vw;
    padding: 0vh 12.5vw;
  }
}
@media (min-width: 2299.98px) {
  .StandaardRetail {
    width: 60vw;
    padding: 0vh 20vw;
  }
}
.StandaardRetail .TextComponentCore {
  color: white;
}
@media (min-width: 991.98px) {
  .StandaardRetail .TextComponentCore {
    width: 80%;
  }
  .StandaardRetail .TextComponentCore h2 {
    width: 80%;
  }
}
.StandaardRetail .gridWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
  gap: 1em;
}
@media (min-width: 991.98px) {
  .StandaardRetail .gridWrapper {
    width: 90vw;
  }
}
@media (min-width: 1199.98px) {
  .StandaardRetail .gridWrapper {
    position: relative;
    width: 92.5vw;
    padding: 7.5vh 3.75vw;
    justify-content: flex-start;
  }
}
@media (min-width: 1199.98px) and (min-width: 767.98px) {
  .StandaardRetail .gridWrapper {
    width: 90vw;
    padding: 3em 5vw;
  }
}
@media (min-width: 1199.98px) and (min-width: 1399.98px) {
  .StandaardRetail .gridWrapper {
    width: 80vw;
    padding: 0vh 10vw;
  }
}
@media (min-width: 1199.98px) and (min-width: 1699.98px) {
  .StandaardRetail .gridWrapper {
    width: 75vw;
    padding: 0vh 12.5vw;
  }
}
@media (min-width: 1199.98px) and (min-width: 2299.98px) {
  .StandaardRetail .gridWrapper {
    width: 60vw;
    padding: 0vh 20vw;
  }
}
.StandaardRetail .gridWrapper .gridItem {
  width: 20%;
  min-width: 15em;
  background-color: #fff;
  border-radius: 0.8em;
  padding: 1.875rem 1.125rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1em;
  min-height: 18em;
  cursor: pointer;
  box-shadow: 0 10px 20px rgba(42, 42, 42, 0.1), 0 3px 6px rgba(0, 0, 0, 0.066);
}
.StandaardRetail .gridWrapper .gridItem:hover {
  background-color: #d2e8ff;
}
.StandaardRetail .gridWrapper .gridItem .imagePlaceholder {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}
.StandaardRetail .gridWrapper .gridItem .gridItemTitle {
  font-size: 1.5em;
  font-weight: 600;
}
.StandaardRetail .gridWrapper .gridItem img {
  width: 80%;
  max-width: 60%;
  max-height: 5em;
  -o-object-fit: contain;
     object-fit: contain;
}
.StandaardRetail .SwiperComponentCore {
  overflow: visible;
  width: 100%;
  margin: 5vh 0vh;
}
.StandaardRetail .SwiperComponentCore .swiper-wrapper {
  width: 100%;
}
.StandaardRetail .SwiperComponentCore .swiper-wrapper .swiper-pagination-bullets {
  opacity: 0 !important;
}
.StandaardRetail .SwiperComponentCore .swiper-wrapper .swiperSlide .title {
  font-size: 1.5em;
  font-weight: 600;
  color: #0f3660;
}
.StandaardRetail .SwiperComponentCore .swiper-wrapper .swiperSlide .placeholder {
  background-color: transparent;
}
.StandaardRetail .SwiperComponentCore .swiper-wrapper .swiperSlide .placeholder img {
  width: 80%;
  max-width: 60%;
  max-height: 5em;
  -o-object-fit: contain;
     object-fit: contain;
}

.gridWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  min-height: 50vh;
}

#sec1,
#sec2,
#sec7,
#sec3 {
  width: 100vw;
  height: 70vh;
  opacity: 0.15;
}

.basicHeader {
  width: 100vw;
  height: 5em;
  position: fixed;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  z-index: 100;
  top: 0;
  max-width: 100vw;
}
.basicHeader > * {
  -webkit-tap-highlight-color: transparent;
}
.basicHeader .headerInner {
  width: 97%;
  padding: 2%;
  padding-left: 1%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  height: 5em;
}
@media (min-width: 991.98px) {
  .basicHeader .headerInner {
    width: 95%;
    top: 1em;
  }
}
@media (min-width: 1399.98px) {
  .basicHeader .headerInner {
    width: 100%;
    top: 1em;
  }
}
.basicHeader .logoWrapper {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: -moz-fit-content;
  width: fit-content;
  transform-origin: top top;
}
.basicHeader .logoWrapper .logo {
  -o-object-fit: contain;
     object-fit: contain;
  cursor: pointer;
  transform-origin: top top;
  height: 5em;
  padding: 5%;
  margin-top: 2em;
}
@media (min-width: 991.98px) {
  .basicHeader .logoWrapper .logo {
    margin-top: unset;
    height: 3em;
    width: 120%;
    height: 120%;
  }
}
.basicHeader .menuItemsWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}
.basicHeader .menuItemsWrapper .logoWrapper {
  left: 0;
}
.basicHeader .menuItemsWrapper .menuItem {
  color: #0f3660;
  cursor: pointer;
  background-color: transparent;
  padding: 0.75rem 1.3125rem;
  border-radius: 0.4em;
  background-color: #ebf5ff;
  font-size: 1em;
  text-transform: uppercase;
  letter-spacing: 0.75px;
}
.basicHeader .menuItemsWrapper .menuItem:hover {
  background-color: #2975c0;
  color: white;
  box-shadow: 0 10px 20px rgba(42, 42, 42, 0.1), 0 3px 6px rgba(0, 0, 0, 0.066);
  transition: all 350ms ease-out;
}
.basicHeader .menuItemsWrapper .active {
  background-color: #000056;
  box-shadow: 0 10px 20px rgba(42, 42, 42, 0.1), 0 3px 6px rgba(0, 0, 0, 0.066);
  color: white;
}

.closeMenuIcon,
.hamburgerMenuIcon {
  cursor: pointer;
}

.hamburgerMenuIcon {
  font-size: 3em !important;
  color: white;
  text-shadow: rgba(0, 0, 0, 0.379) 1px 0 10px;
  position: fixed;
  right: 22px;
  z-index: 99;
}

.closeMenuIcon {
  color: #000056;
  top: 20px;
  right: 20px;
  position: absolute;
  font-size: 4em !important;
  position: absolute;
  left: 0;
  text-align: left;
  width: -moz-fit-content;
  width: fit-content;
  margin: 0.5em;
}

.openedMenu {
  height: 100vh;
  width: 100vw;
  position: fixed;
  z-index: 10000;
  top: 0;
  margin-right: 100vw;
  pointer-events: none;
  overflow: hidden;
}
.openedMenu .menuContent {
  position: relative;
  height: 100%;
  width: 100%;
  text-align: center;
  background-color: #d2e8ff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  pointer-events: auto;
  transform: translateX(100vw);
}
.openedMenu .menuContent .menuItemList {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1em;
  margin-top: -10vh;
  cursor: pointer;
}
.openedMenu .menuContent .menuItemList .menuItem {
  color: #000056;
  font-size: 1.75em;
  padding: 0.9375rem 1.3125rem;
  border-radius: 0.4em;
  width: 50vw;
}
@media (min-width: 991.98px) {
  .openedMenu .menuContent .menuItemList .menuItem:hover {
    color: #ebf5ff;
    background-color: #2975c0;
  }
}
.openedMenu .menuContent .menuItemList .active {
  background-color: #000056;
  color: #ebf5ff;
}

#basicHeader0 .headerInner {
  justify-content: space-between;
}
#basicHeader0 .menuItemsWrapper {
  padding: 0 1.5vw;
  height: 100%;
  background-color: #d2e8ff;
  border-radius: 0.6em;
  right: 0;
  justify-content: space-around;
  gap: 1.25em;
  overflow: hidden;
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.1), 0 5px 12px rgba(0, 0, 0, 0.22);
}
#basicHeader0 .menuItemsWrapper .parentTmpButton {
  margin-left: 2.5vw;
}

#basicHeader1 .headerInner {
  justify-content: space-between;
}
#basicHeader1 .closeMenuIcon {
  margin: 0.55rem;
  margin-left: 0.9625rem;
}
#basicHeader1 #sideMenu1 {
  position: absolute;
  background-color: #d2e8ff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-width: 30em;
  height: 95vh;
  top: 1em;
  margin-top: 2.5vh;
  right: 1em;
  margin-right: 2.5vh;
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.1), 0 5px 12px rgba(0, 0, 0, 0.22);
  border-radius: 2em;
}
#basicHeader1 #sideMenu1 .menuItemList {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1em;
  margin-top: -10vh;
  cursor: pointer;
}
#basicHeader1 #sideMenu1 .menuItemList .menuItem {
  color: #000056;
  background-color: #ebf5ff;
  font-size: 1.5em;
  padding: 0.9375rem 1.3125rem;
  border-radius: 0.4em;
  width: -moz-fit-content;
  width: fit-content;
  min-width: 100%;
  text-align: center;
}
@media (min-width: 991.98px) {
  #basicHeader1 #sideMenu1 .menuItemList .menuItem:hover {
    color: #ebf5ff;
    background-color: #2975c0;
  }
}
#basicHeader1 #sideMenu1 .menuItemList .active {
  background-color: #000056;
  color: #ebf5ff;
}
#basicHeader1 .menuItemsWrapper {
  padding: 0 1em;
  height: 100%;
  background-color: #d2e8ff;
  border-radius: 5000px;
  right: 0;
  justify-content: flex-end;
  gap: 1.5em;
  overflow: hidden;
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.1), 0 5px 12px rgba(0, 0, 0, 0.22);
  max-width: 3em;
  min-width: 3em;
}
#basicHeader1 .menuItemsWrapper .tmpBtn {
  margin-left: 1em;
}
#basicHeader1 .menuItemsWrapper .hamburgerMenuIcon {
  position: relative;
  padding: 0.25em;
  margin: 0;
  margin-right: -0.25em;
  right: unset;
  border-radius: 5000px;
  background: #ebf5ff;
  color: black;
}
#basicHeader1 .menuItemsWrapper .hamburgerMenuIcon:hover {
  background: #2975c0;
  color: white;
}

#basicHeader2 {
  align-items: flex-start;
}
#basicHeader2 .hamburgerMenuIcon {
  color: #000056;
}
#basicHeader2 .openedMenu {
  margin-right: unset;
}
#basicHeader2 .headerInner {
  width: 100%;
  top: 0;
  padding: 0.5% 0;
  position: relative;
}
#basicHeader2 .headerInner .logo {
  margin-top: unset;
}
@media (min-width: 991.98px) {
  #basicHeader2 .headerInner {
    background-color: rgba(0, 0, 0, 0);
    -webkit-backdrop-filter: blur(3px);
            backdrop-filter: blur(3px);
  }
}
#basicHeader2 .headerInner a img {
  height: 90%;
  -o-object-fit: contain;
     object-fit: contain;
}
#basicHeader2 .menuItemsWrapper {
  justify-content: flex-start;
  width: 85%;
  margin-left: 2%;
  gap: 10px;
}
#basicHeader2 .menuItemsWrapper .menuItem {
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.1), 0 5px 12px rgba(0, 0, 0, 0.22);
}
#basicHeader2 .menuItemsWrapper .parentTmpButton {
  right: 0;
  position: absolute;
}

.mf-cursor-test {
  z-index: 10000000;
}

.DFWCOREVIDEO .releaseTitle {
  text-align: left !important;
}

.DFWCOREBASIC #DfwTicketModule {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.DFWCOREBASIC #DfwTicketModule h2 {
  margin: 0px;
  padding: 0px;
}
.DFWCOREBASIC #DfwTicketModule p {
  text-align: center !important;
}
.DFWCOREBASIC #extraInfo {
  background: #000;
  color: #fff;
}
.DFWCOREBASIC #DfwTagline {
  font-family: baileywick-jf-gothic, sans-serif;
  font-size: 2em;
  font-weight: 300;
  color: #f5f1ff;
  text-align: left !important;
  filter: drop-shadow(0px 3px 10px rgba(0, 0, 0, 0.4));
}
.DFWCOREBASIC .DfwStandaardInfo {
  padding-top: 5vh !important;
  background: linear-gradient(180deg, #3b778d 0%, #3b778d 100%) !important;
}
.DFWCOREBASIC .DfwStandaardInfo .inner {
  padding-top: 5vh !important;
}
@media (min-width: 1199.98px) {
  .DFWCOREBASIC .DfwStandaardInfo .inner {
    justify-content: space-between !important;
  }
  .DFWCOREBASIC .DfwStandaardInfo .inner .ph-1 {
    width: 65% !important;
  }
}
.DFWCOREBASIC .DfwStandaardInfo .TextComponentCore {
  text-align: left !important;
}
.DFWCOREBASIC .DfwStandaardInfo #DfwHeaderBasic {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  position: relative;
  width: 92.5vw;
  padding: 7.5vh 3.75vw;
  width: 100vw;
  position: relative;
  height: -moz-fit-content;
  height: fit-content;
  gap: 1.25em;
  font-size: 1.5em;
  padding: 0;
}
@media (min-width: 767.98px) {
  .DFWCOREBASIC .DfwStandaardInfo #DfwHeaderBasic {
    width: 90vw;
    padding: 3em 5vw;
  }
}
@media (min-width: 1399.98px) {
  .DFWCOREBASIC .DfwStandaardInfo #DfwHeaderBasic {
    width: 80vw;
    padding: 0vh 10vw;
  }
}
@media (min-width: 1699.98px) {
  .DFWCOREBASIC .DfwStandaardInfo #DfwHeaderBasic {
    width: 75vw;
    padding: 0vh 12.5vw;
  }
}
@media (min-width: 2299.98px) {
  .DFWCOREBASIC .DfwStandaardInfo #DfwHeaderBasic {
    width: 60vw;
    padding: 0vh 20vw;
  }
}
@media (min-width: 991.98px) {
  .DFWCOREBASIC .DfwStandaardInfo #DfwHeaderBasic {
    margin-top: 1.1rem;
    justify-content: flex-end;
  }
}
.DFWCOREBASIC .DfwStandaardInfo #DfwHeaderBasic .DfwHeaderlinkItem {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  width: -moz-fit-content;
  width: fit-content;
  cursor: pointer !important;
  color: #fcf9ef;
  text-shadow: rgba(0, 0, 0, 0.1) 1px 0 10px;
  font-family: santelia-rough-two, sans-serif;
  font-weight: 600;
  letter-spacing: 4px;
  font-size: 1.2em;
  font-variation-settings: "opsz" 48, "wght" 600;
}
.DFWCOREBASIC .DfwStandaardInfo #DfwHeaderBasic .vidMenuLinkUnderline {
  width: 0%;
  background-color: #fcf9ef;
  height: 2px;
  position: absolute;
  bottom: -3px;
}

.dfwTempCore > * {
  font-family: baileywick-jf-gothic, sans-serif;
}
.dfwTempCore .introLayerdWrapper {
  z-index: 10000000000 !important;
}
.dfwTempCore .introLayerdWrapper .introInner {
  background: #db010b !important;
}
.dfwTempCore .introLayerdWrapper .introOuter {
  background: #fecc14 !important;
}
.dfwTempCore :root {
  --backgroundColor: #e9e4e5;
  --primairColor: #05444f;
  --primairTextColor: #000000;
  --secundairTextColor: #000000;
  --buttonTextColor: #ffffff;
  --fontHeading: "Lato";
  --fontText: "Lato";
  --primairColorOpacity33: #05444f33;
  --primairColorOpacity44: #05444f44;
}
.dfwTempCore .dsRoundBtn,
.dfwTempCore .dfwBtn {
  border-radius: 10px;
  background-color: #db010b;
  box-shadow: 0 0px 28px rgba(117, 99, 255, 0.05), 0 0px 25px rgba(211, 204, 255, 0.05);
}
.dfwTempCore .dsRoundBtn .btnArrowContainer .arrowInside,
.dfwTempCore .dfwBtn .btnArrowContainer .arrowInside {
  color: #000000;
}
.dfwTempCore .dsRoundBtn .externalIconOuter i,
.dfwTempCore .dfwBtn .externalIconOuter i {
  color: #fcf9ef;
}
.dfwTempCore .dsRoundBtn .btnArrowContainer,
.dfwTempCore .dfwBtn .btnArrowContainer {
  background-color: #fecc14;
}
.dfwTempCore .dsRoundBtn:hover,
.dfwTempCore .dfwBtn:hover {
  background-color: #fecc14;
}
.dfwTempCore .dsRoundBtn:hover .externalIconOuter i,
.dfwTempCore .dfwBtn:hover .externalIconOuter i {
  color: #fcf9ef;
  opacity: 1;
}
.dfwTempCore p {
  font-size: 15px;
}
@media (min-width: 991.98px) {
  .dfwTempCore p {
    font-size: 1.1em;
  }
}
.dfwTempCore h3,
.dfwTempCore h2 {
  color: #fcf9ef;
}
.dfwTempCore h1,
.dfwTempCore h2,
.dfwTempCore h3 {
  font-family: santelia-rough-two, sans-serif;
  font-weight: 600;
  font-style: normal;
}
.dfwTempCore h1 {
  font-size: 2em;
}
@media (min-width: 991.98px) {
  .dfwTempCore h1 {
    font-size: 2.5em;
  }
}
.dfwTempCore h3 {
  font-size: 1.5em;
}
@media (min-width: 991.98px) {
  .dfwTempCore h3 {
    font-size: 1.5em;
  }
}
.dfwTempCore h2 {
  text-align: center;
  font-size: 2em;
  padding: 1.5rem 0;
  font-weight: 600 !important;
}
@media (min-width: 991.98px) {
  .dfwTempCore h2 {
    font-size: 2.5em;
  }
}
.dfwTempCore .dfwHeader {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  position: fixed;
  background-color: #04044e;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  height: 5.5em;
  border-radius: 1000em;
  justify-content: center;
  margin-top: 0.5vh;
  transform: scale(0.85);
  color: white;
  pointer-events: none;
  overflow: hidden;
  width: 95vw;
  margin: 5vw 2.5vw;
}
@media (min-width: 1199.98px) {
  .dfwTempCore .dfwHeader {
    width: 90vw;
    margin: 5vw 5vw;
  }
}
@media (min-width: 1399.98px) {
  .dfwTempCore .dfwHeader {
    width: 80vw;
    margin: 5vw 10vw;
  }
}
.dfwTempCore .dfwHeader > * {
  pointer-events: auto;
}
@media (min-width: 991.98px) {
  .dfwTempCore .dfwHeader {
    transform: scale(1);
    margin-top: 1.5vh;
  }
}
.dfwTempCore .dfwHeader .headerInfo {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 100%;
  gap: 2em;
  margin-left: -10vw;
}
.dfwTempCore .dfwHeader .headerInfo h4 {
  display: none;
  font-weight: 500;
  font-size: 1em;
  text-align: center;
  max-width: 40%;
}
@media (min-width: 991.98px) {
  .dfwTempCore .dfwHeader .headerInfo h4 {
    display: flex;
  }
}
.dfwTempCore .dfwHeader .socialMediaWrapper {
  display: none;
  right: 0px;
}
@media (min-width: 575.98px) {
  .dfwTempCore .dfwHeader .socialMediaWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    position: absolute;
    justify-content: flex-end;
    min-width: 25%;
  }
}
.dfwTempCore .dfwHeader .socialMediaWrapper .socialMediaIcon {
  width: 4em;
  height: 10em;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  background-color: pink;
  border-radius: 0.8em;
  box-shadow: 0 10px 20px rgba(42, 42, 42, 0.1), 0 3px 6px rgba(0, 0, 0, 0.066);
  cursor: pointer;
  transition: 0.3s;
}
@media (min-width: 1199.98px) {
  .dfwTempCore .dfwHeader .socialMediaWrapper .socialMediaIcon {
    width: 5em;
  }
}
.dfwTempCore .dfwHeader .socialMediaWrapper .socialMediaIcon:hover {
  filter: brightness(0.7);
}
.dfwTempCore .dfwHeader .socialMediaWrapper .fbIcon {
  background: linear-gradient(180deg, rgb(104, 155, 244) 0%, rgb(39, 65, 201) 100%);
}
.dfwTempCore .dfwHeader .socialMediaWrapper .instaIcon {
  background: radial-gradient(circle at 30% 110%, #ffdb8b 0%, #ee653d 25%, #d42e81 50%, #a237b6 75%, #3e5fbc 100%);
}
.dfwTempCore .dfwHeader .socialMediaWrapper .ytbIcon {
  background-color: #ff0000;
}
.dfwTempCore .dfwHeader .socialMediaWrapper .tiktokIcon {
  background: linear-gradient(0deg, #000000, #ff0066);
}
.dfwTempCore #DfwHero {
  overflow: hidden;
}
.dfwTempCore #DfwHero .bgImage {
  position: absolute;
  width: 100vw;
  height: 100vh;
  -o-object-fit: cover;
     object-fit: cover;
}
.dfwTempCore #DfwHero .videoPopUpFixedOverlay .exitBtn {
  opacity: 1 !important;
}
.dfwTempCore #DfwHero .videoPopUpFixedOverlay .exitBtn .dsRoundBtn {
  background-color: white;
  color: black;
}
.dfwTempCore #DfwHero .videoPopUpContainer {
  background: linear-gradient(0deg, #080883 25%, rgba(219, 1, 12, 0.4117647059) 75%) !important;
}
.dfwTempCore #DfwHero #StandaardVideo {
  z-index: 1;
}
.dfwTempCore #DfwHero #StandaardOverlay,
.dfwTempCore #DfwHero #StandaardVideo {
  width: unset;
  padding: unset;
  width: 80vw;
  padding: 0vw 10vw;
  padding-top: 5vh;
}
@media (min-width: 991.98px) {
  .dfwTempCore #DfwHero #StandaardOverlay,
  .dfwTempCore #DfwHero #StandaardVideo {
    height: 100vh;
    padding: 0vw 10vw;
  }
}
.dfwTempCore #DfwHero #StandaardOverlay .videoPopUpBgExit,
.dfwTempCore #DfwHero #StandaardVideo .videoPopUpBgExit {
  background-color: rgba(0, 0, 0, 0.98);
}
.dfwTempCore #DfwHero #StandaardOverlay .parentTmpButton,
.dfwTempCore #DfwHero #StandaardOverlay .vidMenuLink,
.dfwTempCore #DfwHero #StandaardVideo .parentTmpButton,
.dfwTempCore #DfwHero #StandaardVideo .vidMenuLink {
  display: none;
}
@media (min-width: 991.98px) {
  .dfwTempCore #DfwHero #StandaardOverlay .parentTmpButton,
  .dfwTempCore #DfwHero #StandaardOverlay .vidMenuLink,
  .dfwTempCore #DfwHero #StandaardVideo .parentTmpButton,
  .dfwTempCore #DfwHero #StandaardVideo .vidMenuLink {
    display: flex;
  }
}
.dfwTempCore #DfwHero #StandaardOverlay .vidPlayMenuWrapper,
.dfwTempCore #DfwHero #StandaardVideo .vidPlayMenuWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  position: absolute;
  right: 0px;
  gap: 2em;
  justify-content: space-between;
}
.dfwTempCore #DfwHero #StandaardOverlay .vidPlayMenuWrapper .vidMenuLink,
.dfwTempCore #DfwHero #StandaardVideo .vidPlayMenuWrapper .vidMenuLink {
  font-size: 1.5em;
  color: #fcf9ef;
}
.dfwTempCore #DfwHero #StandaardOverlay .vidPlayMenuWrapper .vidMenuLink:hover,
.dfwTempCore #DfwHero #StandaardVideo .vidPlayMenuWrapper .vidMenuLink:hover {
  color: #db010b;
}
.dfwTempCore #DfwHero #StandaardOverlay .vidPlayMenuWrapper .vidMenuLinkUnderline,
.dfwTempCore #DfwHero #StandaardVideo .vidPlayMenuWrapper .vidMenuLinkUnderline {
  background-color: #db010b;
}
.dfwTempCore #DfwHero #StandaardOverlay .vidMenuLink,
.dfwTempCore #DfwHero #StandaardVideo .vidMenuLink {
  display: none;
  color: white;
  font-size: 16px;
  cursor: pointer !important;
}
@media (min-width: 991.98px) {
  .dfwTempCore #DfwHero #StandaardOverlay .vidMenuLink,
  .dfwTempCore #DfwHero #StandaardVideo .vidMenuLink {
    display: flex;
  }
}
.dfwTempCore #DfwHero #StandaardOverlay .vidMenuLinkUnderline,
.dfwTempCore #DfwHero #StandaardVideo .vidMenuLinkUnderline {
  width: 0%;
  background-color: white;
  height: 2px;
  position: absolute;
  bottom: -3px;
}
.dfwTempCore #DfwHero #StandaardOverlay .videoPopUpWrapper .parentTmpButton,
.dfwTempCore #DfwHero #StandaardVideo .videoPopUpWrapper .parentTmpButton {
  opacity: 0.5;
}
.dfwTempCore #DfwHero #StandaardOverlay .videoPopUpWrapper .parentTmpButton .dsRoundBtn,
.dfwTempCore #DfwHero #StandaardVideo .videoPopUpWrapper .parentTmpButton .dsRoundBtn {
  background-color: rgba(255, 255, 255, 0.2);
}
.dfwTempCore #DfwHero #StandaardOverlay .videoPopUpWrapper .parentTmpButton .dsRoundBtn div,
.dfwTempCore #DfwHero #StandaardVideo .videoPopUpWrapper .parentTmpButton .dsRoundBtn div {
  color: rgba(255, 255, 255, 0.75);
}
.dfwTempCore #DfwHero #StandaardOverlay #innerStandaardOverlay,
.dfwTempCore #DfwHero #StandaardOverlay .videoPopUpWrapper,
.dfwTempCore #DfwHero #StandaardVideo #innerStandaardOverlay,
.dfwTempCore #DfwHero #StandaardVideo .videoPopUpWrapper {
  width: 90vw;
  height: 60vh;
  border-radius: 1.6em;
}
@media (min-width: 991.98px) {
  .dfwTempCore #DfwHero #StandaardOverlay #innerStandaardOverlay,
  .dfwTempCore #DfwHero #StandaardOverlay .videoPopUpWrapper,
  .dfwTempCore #DfwHero #StandaardVideo #innerStandaardOverlay,
  .dfwTempCore #DfwHero #StandaardVideo .videoPopUpWrapper {
    width: 90vw;
    height: 90vh;
  }
}
.dfwTempCore #DfwHero #StandaardOverlay #innerStandaardOverlay #videoPopUpElement,
.dfwTempCore #DfwHero #StandaardOverlay .videoPopUpWrapper #videoPopUpElement,
.dfwTempCore #DfwHero #StandaardVideo #innerStandaardOverlay #videoPopUpElement,
.dfwTempCore #DfwHero #StandaardVideo .videoPopUpWrapper #videoPopUpElement {
  -o-object-fit: cover;
     object-fit: cover;
  width: 110%;
  min-height: 120vh;
}
.dfwTempCore #DfwHero #StandaardOverlay {
  z-index: 1;
  position: relative;
  top: 0;
  pointer-events: none;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
@media (min-width: 1199.98px) {
  .dfwTempCore #DfwHero #StandaardOverlay {
    position: absolute;
  }
}
.dfwTempCore #DfwHero #StandaardOverlay #mobileHeroVideo {
  height: 100%;
  position: absolute;
}
@media (min-width: 1199.98px) {
  .dfwTempCore #DfwHero #StandaardOverlay #mobileHeroVideo {
    display: none;
  }
}
.dfwTempCore #DfwHero #StandaardOverlay #innerStandaardOverlay {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
  background: radial-gradient(circle, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 86%);
}
.dfwTempCore #DfwHero #StandaardOverlay #vidPlayHeader {
  position: absolute;
  top: 0px;
  width: 90%;
  height: -moz-fit-content;
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  pointer-events: auto;
  padding: 0 2em;
  height: 4em;
  margin-top: 1em;
}
@media (min-width: 991.98px) {
  .dfwTempCore #DfwHero #StandaardOverlay #vidPlayHeader {
    justify-content: space-between;
    align-items: flex-start;
    width: 90%;
    padding: 1em;
  }
}
.dfwTempCore #DfwHero #StandaardOverlay #vidPlayHeader #logoFilmRelease {
  width: 70vw;
  max-width: 15em;
  max-height: 4em;
  min-height: 50%;
  -o-object-fit: contain;
     object-fit: contain;
}
@media (min-width: 991.98px) {
  .dfwTempCore #DfwHero #StandaardOverlay #vidPlayHeader #logoFilmRelease {
    width: 25vw;
    max-width: 27.5em;
    max-height: 9em;
    margin-top: -1em;
  }
}
.dfwTempCore #DfwHero #StandaardOverlay #vidDescription {
  width: 90%;
  pointer-events: auto;
  bottom: 5%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  color: white;
}
@media (min-width: 991.98px) {
  .dfwTempCore #DfwHero #StandaardOverlay #vidDescription {
    width: 85%;
    justify-content: space-between;
    bottom: 0%;
    padding: 2.5% 7.5%;
  }
}
.dfwTempCore #DfwHero #StandaardOverlay #vidDescription .heroInfo {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 0.75em;
  width: 100%;
}
@media (min-width: 991.98px) {
  .dfwTempCore #DfwHero #StandaardOverlay #vidDescription .heroInfo {
    align-items: flex-start;
    width: 40%;
    left: 0;
  }
}
@media (min-width: 991.98px) {
  .dfwTempCore #DfwHero #StandaardOverlay #vidDescription .heroInfo .parentTmpButton {
    order: 100;
  }
}
.dfwTempCore #DfwHero #StandaardOverlay #vidDescription .heroInfo .releaseTitle {
  text-align: center;
}
.dfwTempCore #DfwHero #StandaardOverlay #vidDescription .heroInfo .filmInfoIconWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 1em;
  order: 1;
}
.dfwTempCore #DfwHero #StandaardOverlay #vidDescription .heroInfo .filmInfoIconWrapper .filmInfoContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  gap: 0.25em;
}
@media (min-width: 1199.98px) {
  .dfwTempCore #DfwHero #StandaardOverlay #vidDescription .heroInfo .filmInfoIconWrapper .filmInfoContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
  }
}
.dfwTempCore #DfwHero #StandaardOverlay #vidDescription .heroInfo .filmInfoIconWrapper .filmInfoContainer p {
  font-size: 0.75em;
}
.dfwTempCore #DfwHero #StandaardOverlay #vidDescription article {
  display: none;
  position: relative;
  width: 60%;
}
.dfwTempCore #DfwHero #StandaardOverlay #vidDescription article .oneliner {
  font-size: 0.75em;
}
.dfwTempCore #DfwHero #StandaardOverlay #vidDescription article q {
  padding: 0;
}
.dfwTempCore #DfwHero #StandaardOverlay #vidDescription article q p {
  margin-top: 0.35em;
}
.dfwTempCore #DfwHero #StandaardOverlay #vidDescription article q:after {
  color: #db010b;
  right: -0.5em;
}
.dfwTempCore #DfwHero #StandaardOverlay #vidDescription article q:before {
  margin-top: -0.5em;
  color: #db010b;
}
@media (min-width: 991.98px) {
  .dfwTempCore #DfwHero #StandaardOverlay #vidDescription article {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 0.5em;
    width: 45%;
  }
}
.dfwTempCore #DfwHero #StandaardOverlay #vidDescription span {
  display: none;
  width: 1px;
  height: 150px;
  background-color: #fff;
  opacity: 0.5;
}
@media (min-width: 991.98px) {
  .dfwTempCore #DfwHero #StandaardOverlay #vidDescription span {
    display: flex;
  }
}
.dfwTempCore #DfwCoreJwPlaceHodler {
  padding-top: 2.5vh !important;
  background: linear-gradient(180deg, #080883 0%, #7a1f30 75%) !important;
  position: relative;
  width: 92.5vw;
  padding: 7.5vh 3.75vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
@media (min-width: 767.98px) {
  .dfwTempCore #DfwCoreJwPlaceHodler {
    width: 90vw;
    padding: 3em 5vw;
  }
}
@media (min-width: 1399.98px) {
  .dfwTempCore #DfwCoreJwPlaceHodler {
    width: 80vw;
    padding: 0vh 10vw;
  }
}
@media (min-width: 1699.98px) {
  .dfwTempCore #DfwCoreJwPlaceHodler {
    width: 75vw;
    padding: 0vh 12.5vw;
  }
}
@media (min-width: 2299.98px) {
  .dfwTempCore #DfwCoreJwPlaceHodler {
    width: 60vw;
    padding: 0vh 20vw;
  }
}
@media (min-width: 991.98px) {
  .dfwTempCore #DfwCoreJwPlaceHodler {
    padding-top: 5vh !important;
    padding-bottom: 5vh !important;
  }
}
.dfwTempCore #DfwCoreJwPlaceHodler .wrapper {
  padding: 1em 0em;
  width: 100%;
  max-width: 65em;
}
@media (min-width: 991.98px) {
  .dfwTempCore #DfwCoreJwPlaceHodler .wrapper {
    padding: 5em 0em;
  }
}
.dfwTempCore #DfwCoreJwPlaceHodler .wrapper .container-overflow {
  overflow: hidden;
  border-radius: 0.8em;
}
.dfwTempCore #DfwCoreJwPlaceHodler .wrapper .container-overflow .jw-preview {
  background-size: 100% 100% !important;
  background-size: cover !important;
  transform: scale(1.4);
}
@media (min-width: 991.98px) {
  .dfwTempCore #DfwCoreJwPlaceHodler .wrapper .container-overflow {
    border-radius: 1.6em;
  }
}
@media (min-width: 1199.98px) {
  .dfwTempCore #DfwCoreJwPlaceHodler .wrapper .container-overflow {
    border-radius: 2.4em;
  }
}
.dfwTempCore #DfwTicketModule {
  background: linear-gradient(180deg, #2b4ed4 0%, #080883 100%) !important;
  position: relative;
  width: 92.5vw;
  padding: 7.5vh 3.75vw;
}
@media (min-width: 767.98px) {
  .dfwTempCore #DfwTicketModule {
    width: 90vw;
    padding: 3em 5vw;
  }
}
@media (min-width: 1399.98px) {
  .dfwTempCore #DfwTicketModule {
    width: 80vw;
    padding: 0vh 10vw;
  }
}
@media (min-width: 1699.98px) {
  .dfwTempCore #DfwTicketModule {
    width: 75vw;
    padding: 0vh 12.5vw;
  }
}
@media (min-width: 2299.98px) {
  .dfwTempCore #DfwTicketModule {
    width: 60vw;
    padding: 0vh 20vw;
  }
}
@media (min-width: 991.98px) {
  .dfwTempCore #DfwTicketModule {
    padding-top: 7.5vh;
    padding-bottom: 7.5vh;
  }
}
.dfwTempCore #ZetInAgendaModule p {
  color: white;
  text-align: center;
}
.dfwTempCore #ZetInAgendaModule .dfwBtn {
  min-width: 15em;
  color: #fcf9ef;
}
.dfwTempCore #ZetInAgendaModule .dfwBtn img {
  filter: drop-shadow(0 0 0.4rem rgba(53, 53, 53, 0.545));
}
.dfwTempCore .DfwStandaardInfo {
  background: linear-gradient(180deg, #3b778d 0%, #3b778d 100%) !important;
}
.dfwTempCore .DfwStandaardInfo p {
  text-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
}
.dfwTempCore .DfwStandaardInfo .ImageComponentCore a img {
  box-shadow: 0 0px 28px rgba(255, 255, 255, 0.25), 0 10px 50px rgba(255, 255, 255, 0.15);
}
.dfwTempCore .DfwStandaardInfo .gradientBgImage {
  width: 100vw;
  bottom: 0px;
  height: 200%;
  position: absolute;
  background: linear-gradient(0deg, #2b4ed4 0%, #080883 80%);
}
@media (min-width: 991.98px) {
  .dfwTempCore .DfwStandaardInfo .gradientBgImage {
    height: 150%;
  }
}
@media (min-width: 1199.98px) {
  .dfwTempCore .DfwStandaardInfo .gradientBgImage {
    height: 125%;
  }
}
.dfwTempCore .DfwStandaardInfo .bgImage {
  position: absolute;
  height: 100%;
  opacity: 0.85;
  width: 100vw;
  -o-object-fit: cover;
     object-fit: cover;
  top: 0px;
}
.dfwTempCore .DfwStandaardInfo .innerBg {
  pointer-events: none !important;
}
.dfwTempCore .DFWINFOBASIC {
  background: linear-gradient(0deg, #080883 25%, rgba(219, 1, 12, 0.4117647059) 75%) !important;
}
.dfwTempCore .DFWINFOBASIC h2 {
  display: none;
}
.dfwTempCore .DFWINFOBASIC .parent .inner {
  flex-direction: column-reverse !important;
}
@media (min-width: 991.98px) {
  .dfwTempCore .DFWINFOBASIC .parent .inner {
    flex-direction: row !important;
  }
}
.dfwTempCore .DFWINFOBASIC .infoFilmReleaseLogo {
  max-width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  max-height: 25vh;
  margin-bottom: 1em;
}
@media (min-width: 991.98px) {
  .dfwTempCore .DFWINFOBASIC .infoFilmReleaseLogo {
    max-width: 90%;
  }
}
@media (min-width: 1199.98px) {
  .dfwTempCore .DFWINFOBASIC .infoFilmReleaseLogo {
    max-width: 28em;
  }
}
.dfwTempCore .DFWINFOVIDEO h2 {
  display: unset;
}
.dfwTempCore .DfwStandaardInfo h2,
.dfwTempCore .DfwStandaardInfo h3 {
  padding-bottom: 0rem !important;
}
.dfwTempCore .DfwStandaardInfo .inner {
  gap: 5vh !important;
  flex-direction: column !important;
}
@media (min-width: 991.98px) {
  .dfwTempCore .DfwStandaardInfo .inner {
    flex-direction: row !important;
  }
}
.dfwTempCore .DfwStandaardInfo p,
.dfwTempCore .DfwStandaardInfo h3 {
  color: #fcf9ef;
}
.dfwTempCore .DfwStandaardInfo .ph-1 {
  flex-direction: column !important;
  align-items: flex-start !important;
}
.dfwTempCore .DfwStandaardInfo .ph-1 .TextComponentCore {
  text-align: center;
  align-items: flex-start !important;
}
@media (min-width: 767.98px) {
  .dfwTempCore .DfwStandaardInfo .ph-1 .TextComponentCore {
    text-align: left !important;
    align-items: flex-start !important;
  }
}
@media (min-width: 991.98px) {
  .dfwTempCore .DfwStandaardInfo .ph-1 {
    width: 50% !important;
  }
}
.dfwTempCore .DfwStandaardInfo .ph-2 {
  width: -moz-fit-content !important;
  width: fit-content !important;
}
@media (min-width: 991.98px) {
  .dfwTempCore .DfwStandaardInfo .ph-2 {
    width: 50%;
  }
}
.dfwTempCore .DfwStandaardInfo .ImageComponentCore {
  height: 100%;
  width: 100%;
}
.dfwTempCore .DfwStandaardInfo .ImageComponentCore .bgLayer {
  opacity: 0;
}
.dfwTempCore .DfwStandaardInfo .ImageComponentCore img {
  margin: 0;
  max-height: 30em;
  width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
@media (min-width: 991.98px) {
  .dfwTempCore .DfwStandaardInfo .ImageComponentCore img {
    max-height: 75vh;
  }
}
.dfwTempCore .DfwImageViewer {
  background: #080883 !important;
  gap: 0;
}
.dfwTempCore .DfwImageViewer .SwiperComponentCore,
.dfwTempCore .DfwImageViewer .swiper-wrapper,
.dfwTempCore .DfwImageViewer .SwiperComponentCoreWrapper {
  padding: 0;
}
.dfwTempCore .DfwImageViewer .swiper-button-prev,
.dfwTempCore .DfwImageViewer .swiper-button-next {
  background-color: #db010b;
}
.dfwTempCore .DfwImageViewer .swiper-button-prev:after,
.dfwTempCore .DfwImageViewer .swiper-button-next:after {
  color: #fecc14;
}
.dfwTempCore .DfwImageViewer .swiper-button-prev:hover,
.dfwTempCore .DfwImageViewer .swiper-button-next:hover {
  background-color: #fecc14;
  color: black;
}
.dfwTempCore .DfwImageViewer .swiper-button-prev:hover:after,
.dfwTempCore .DfwImageViewer .swiper-button-next:hover:after {
  color: white;
}
.dfwTempCore .DfwImageViewer .swiper-button-next {
  transform: translateX(-8vw);
}
@media (min-width: 991.98px) {
  .dfwTempCore .DfwImageViewer .swiper-button-next {
    transform: translateX(-2.5vw);
  }
}
.dfwTempCore .DfwImageViewer .swiper-button-next:hover {
  transform: translateX(-8vw);
}
@media (min-width: 991.98px) {
  .dfwTempCore .DfwImageViewer .swiper-button-next:hover {
    transform: translateX(-2.5vw);
  }
}
.dfwTempCore .DfwImageViewer .swiper-button-prev {
  transform: translateX(8vw);
}
@media (min-width: 991.98px) {
  .dfwTempCore .DfwImageViewer .swiper-button-prev {
    transform: translateX(2.5vw);
  }
}
.dfwTempCore .DfwImageViewer .swiper-button-prev:hover {
  transform: translateX(8vw);
}
@media (min-width: 991.98px) {
  .dfwTempCore .DfwImageViewer .swiper-button-prev:hover {
    transform: translateX(2.5vw);
  }
}
.dfwTempCore .DfwImageViewer .swiperSlide {
  height: -moz-fit-content !important;
  height: fit-content !important;
  gap: 0 !important;
}
.dfwTempCore .DfwImageViewer .swiperSlide .img,
.dfwTempCore .DfwImageViewer .swiperSlide .backgroundImg {
  padding: 0;
  height: 50vh !important;
  min-width: 100%;
  min-height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  width: 100% !important;
  position: relative !important;
}
@media (min-width: 991.98px) {
  .dfwTempCore .DfwImageViewer .swiperSlide .img,
  .dfwTempCore .DfwImageViewer .swiperSlide .backgroundImg {
    height: -moz-fit-content !important;
    height: fit-content !important;
  }
}
.dfwTempCore #DfwSingleImageSection {
  position: relative;
  width: 92.5vw;
  padding: 7.5vh 3.75vw;
  background: linear-gradient(0deg, #c30065 0%, #c30065 80%);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 5vh !important;
  padding-bottom: 10vh !important;
  gap: 5vw;
}
@media (min-width: 767.98px) {
  .dfwTempCore #DfwSingleImageSection {
    width: 90vw;
    padding: 3em 5vw;
  }
}
@media (min-width: 1399.98px) {
  .dfwTempCore #DfwSingleImageSection {
    width: 80vw;
    padding: 0vh 10vw;
  }
}
@media (min-width: 1699.98px) {
  .dfwTempCore #DfwSingleImageSection {
    width: 75vw;
    padding: 0vh 12.5vw;
  }
}
@media (min-width: 2299.98px) {
  .dfwTempCore #DfwSingleImageSection {
    width: 60vw;
    padding: 0vh 20vw;
  }
}
@media (min-width: 991.98px) {
  .dfwTempCore #DfwSingleImageSection {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
  }
}
.dfwTempCore #DfwSingleImageSection h3 {
  text-align: center;
}
@media (min-width: 991.98px) {
  .dfwTempCore #DfwSingleImageSection h3 {
    width: 65%;
  }
}
.dfwTempCore .dfwFooter {
  flex-direction: column;
  gap: 1em;
  padding-top: 2em !important;
}
@media (min-width: 991.98px) {
  .dfwTempCore .dfwFooter {
    flex-direction: column;
  }
}
.dfwTempCore .dfwFooter .linkFooter {
  cursor: pointer !important;
  font-size: 10px;
}
.dfwTempCore .dfwFooter .imageRow {
  flex-wrap: wrap;
  gap: 1em;
}
.dfwTempCore .dfwFooter .imageRow img {
  height: 5vh;
  max-width: 6.5em;
  max-height: 1em;
  margin: 0 1em;
  -o-object-fit: contain;
     object-fit: contain;
}
@media (min-width: 991.98px) {
  .dfwTempCore .dfwFooter .imageRow img {
    max-height: 3em;
  }
}

.c-ticketmodule input#Location.ctic-datapicker_location-field.w-input {
  background: transparent !important;
}
.c-ticketmodule > * {
  font-family: baileywick-jf-gothic, sans-serif !important;
}
.c-ticketmodule .ctic-datapicker {
  width: 96% !important;
  margin-left: 2% !important;
}
.c-ticketmodule .ctic-introcontent_header {
  font-size: 1em !important;
  margin-top: 0px;
  font-family: baileywick-jf-gothic, sans-serif !important;
}
@media (min-width: 1199.98px) {
  .c-ticketmodule .ctic-introcontent_header {
    font-size: 1.5em !important;
  }
}
.c-ticketmodule .ctic-introcontent {
  padding-top: 0px;
  text-align: left !important;
}
.c-ticketmodule .ctic-datapicker_location-wrapper {
  padding: 17px 30px !important;
  padding-right: 20px !important;
}
.c-ticketmodule .ctic-datapicker_location-wrapper.active {
  width: 45% !important;
}
.c-ticketmodule .ctic-datapicker_submit-wrapper {
  padding: 10px !important;
}
.c-ticketmodule .ctic-datapicker_date-wrapper {
  padding: 17px 0px 17px 17px !important;
}
.c-ticketmodule .ctic-introcontent_text,
.c-ticketmodule .ctic-showtimes_dates_month,
.c-ticketmodule .ctic-cinemadetails_address {
  font-family: baileywick-jf-gothic, sans-serif !important;
}
.c-ticketmodule .ctic-entry_showtime_time,
.c-ticketmodule .ctic-cinemadetails_name,
.c-ticketmodule .ctic-showtimes_dates_date {
  font-family: santelia-rough-two, sans-serif !important;
}
.c-ticketmodule .ctic-cta,
.c-ticketmodule .ctic-introcontent_text {
  color: black !important;
}
.c-ticketmodule :root {
  --backgroundColor: #e9e4e5;
  --primairColor: #000;
  --primairTextColor: #000000;
  --secundairTextColor: #000000;
  --buttonTextColor: #ffffff;
  --fontHeading: "Lato";
  --fontText: "Lato";
  --primairColorOpacity33: #05444f33;
  --primairColorOpacity44: #05444f44;
}
.c-ticketmodule .mobile-search-btn {
  border-style: none;
}
.c-ticketmodule .ctic-datapicker,
.c-ticketmodule .ctic-datapicker_location-wrapper {
  border-width: 2px;
}
.c-ticketmodule .ctic-wrapper {
  background-color: #ffeacf !important;
  border: 2px solid rgba(255, 255, 255, 0.3411764706);
}
.c-ticketmodule .ctic-button {
  background-color: #db010b !important;
  color: #fcf9ef !important;
}
.c-ticketmodule .ctic-button:hover {
  background-color: #fecc14 !important;
  color: #fcf9ef !important;
}
.c-ticketmodule input {
  outline: none;
  background: transparent;
  background-color: transparent;
}
.c-ticketmodule input:hover {
  outline: none;
}
.c-ticketmodule .ctic-datapicker_location-field,
.c-ticketmodule .ctic-datapicker_date-field {
  margin-left: 10px;
  font-family: baileywick-jf-gothic, sans-serif;
  font-weight: 500;
  font-size: 1em;
}
.c-ticketmodule .ctic-icon {
  transform: scale(0.8);
}
.c-ticketmodule .ctic-button {
  font-family: unset;
  background-color: #db010b;
  border: none;
}
.c-ticketmodule .ctic-button:hover {
  background-color: #fecc14;
}
.c-ticketmodule .ctic-introcontent_cinemacallout {
  font-family: unset;
}

#ui-datepicker-div {
  margin-top: 24px !important;
  margin-left: -35px !important;
  padding: 1.25em !important;
  border-color: lightgrey !important;
  box-shadow: 0 10px 20px rgba(42, 42, 42, 0.1), 0 3px 6px rgba(0, 0, 0, 0.066) !important;
  border-radius: 1em;
}
#ui-datepicker-div > * {
  font-family: baileywick-jf-gothic, sans-serif !important;
}

.ui-widget-header {
  border: unset;
  background: unset;
  color: unset;
  font-weight: unset;
}

.ui-datepicker td {
  padding: 0.25em !important;
}

.ui-datepicker td span,
.ui-datepicker td a {
  text-align: center !important;
}

.ui-state-default {
  color: black;
  border: none !important;
  background-color: unset !important;
  border-radius: 0.25em !important;
  padding: 0.25em !important;
  font-weight: 400 !important;
}

.ui-state-highlight {
  background: #db010b !important;
  opacity: 0.6 !important;
  color: white !important;
}

.ctic-date-overlay,
.ctic-location-overlay {
  visibility: none !important;
  display: none !important;
}

.ui-state-active {
  background: #fecc14 !important;
}

.ui-widget {
  font-family: baileywick-jf-gothic, sans-serif !important;
}

.ui-widget-header {
  border: none;
  background-color: unset;
}

.ui-datepicker {
  width: 25em;
}

#placeHolderTicketModule {
  width: 80vw;
  width: 100%;
  position: relative;
}

#placeHolderTicketModule .ctic-poster {
  max-width: 100%;
  vertical-align: middle;
  display: inline-block;
}

#buyTickets .placeHolderAgenda,
#buyTickets .title {
  order: 100;
}

#buyTicketsInner {
  order: 1;
}

.ctic-entry_cinema_icon img {
  width: 55px;
}

.ctic-showtimes {
  margin-top: 1em;
}

.ctic-shotimes_date {
  background-color: #db010b !important;
}
.ctic-shotimes_date .ctic-showtimes_dates_month,
.ctic-shotimes_date .ctic-showtimes_dates_date {
  color: white !important;
}

.ctic-shotimes_date .w-inline-block .active {
  background-color: #fecc14 !important;
}

.ctic-entry_showtime_time {
  color: black !important;
}

.ctic-title {
  line-height: 1em;
  text-align: left !important;
  font-size: 2.5em !important;
  margin-top: 15px;
  font-family: santelia-rough-two, sans-serif !important;
  font-family: 800 !important;
}

.ctic-cinemadetails_name,
.ctic-cinemadetails_address {
  color: #fcf9ef !important;
}

.ctic-cinemacallout_cinemas {
  justify-content: flex-start;
}

.ctic-cinemaicon_wrapper {
  width: 50px !important;
  height: 50px !important;
}
@media (min-width: 1199.98px) {
  .ctic-cinemaicon_wrapper {
    width: 72px !important;
    height: 72px !important;
  }
}

#data-response-api > * {
  font-family: baileywick-jf-gothic, sans-serif !important;
  color: #fcf9ef !important;
}

.defaultCardSwiper {
  width: 20em;
  height: 20em;
  overflow: visible !important;
}
.defaultCardSwiper .swiper-slide {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.8em;
  font-size: 22px;
  font-weight: bold;
  color: #fff;
  width: 1em;
  overflow: hidden;
}
.defaultCardSwiper .swiper-slide img {
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

.swiper-slide {
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}

.dfltSwiperSettings {
  width: 100vw;
  height: 20em;
  color: #000000;
}
.dfltSwiperSettings .swiper-slide {
  width: 5em;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 8px;
  font-size: 22px;
  font-weight: bold;
  color: #fff;
  width: 1em;
  overflow: hidden;
}
.dfltSwiperSettings .swiper-slide img {
  height: 100%;
  width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}

.smallSlideWrapper {
  width: 100%;
  min-height: 5em;
  overflow: visible !important;
  padding-bottom: 2em !important;
  padding-top: 1em !important;
}
.smallSlideWrapper .swiper-pagination {
  bottom: -0.5em;
}
.smallSlideWrapper .swiper-slide {
  overflow: hidden;
  border-radius: 0.8em;
  box-shadow: 0 10px 20px rgba(42, 42, 42, 0.1), 0 3px 6px rgba(0, 0, 0, 0.066);
}
.smallSlideWrapper .swiper-slide .smallSlider {
  border-radius: 0.8em;
  width: 100%;
  height: 100%;
  background-color: white;
  border: solid 1px #D3E4F5;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 1em;
}
.smallSlideWrapper .swiper-slide .smallSlider .storeBuyContainer {
  width: 50%;
}
.smallSlideWrapper .swiper-slide .smallSlider .storeBuyContainer h4 {
  color: #ff4c4f;
}
.smallSlideWrapper .swiper-slide .smallSlider .storeLogoContainer {
  width: 40%;
  height: 85%;
  margin: 2.5%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #cddded;
  border-radius: 0.8em;
  border: solid 1px #D3E4F5;
}
.smallSlideWrapper .swiper-slide .smallSlider .storeLogoContainer img {
  -o-object-fit: contain;
     object-fit: contain;
  padding: 5%;
  width: 70%;
  height: 60%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  transition: all 0.3s;
  cursor: pointer;
}

.verticalSwiper {
  height: 85vh;
  pointer-events: none !important;
}
.verticalSwiper .swiper-slide {
  width: 90%;
  margin-left: 5%;
  background: black;
  transition: all 550ms ease-out;
}
.verticalSwiper .swiper-slide-active {
  z-index: 4;
}
.verticalSwiper .swiper-button-prev,
.verticalSwiper .swiper-button-next {
  pointer-events: none;
  -webkit-user-select: none !important;
     -moz-user-select: none !important;
          user-select: none !important;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  right: 0;
  left: 0;
  width: 100%;
  top: 0 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.verticalSwiper .swiper-button-prev:after,
.verticalSwiper .swiper-button-next:after {
  width: 0.8em;
  height: 0.8em;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 100em;
  background: #000;
  border: solid white 1px;
  font-size: 2em;
  line-height: 0 !important;
  color: white;
  position: absolute;
  pointer-events: auto !important;
}
.verticalSwiper .swiper-button-next {
  margin-top: 10vh;
}
.verticalSwiper .swiper-button-next:after {
  transform: rotate(-90deg);
  padding: 0.5em;
  padding-left: 0.55em;
}
.verticalSwiper .swiper-button-prev {
  margin-top: 70vh;
}
.verticalSwiper .swiper-button-prev:after {
  transform: rotate(-90deg);
  padding: 0.5em;
  padding-right: 0.55em;
}
.verticalSwiper .swiper-slide-next {
  transform: scale(0.25) translateY(30vh) !important;
  z-index: 0;
  opacity: 0;
  filter: blur(7px);
}
.verticalSwiper .swiper-slide-prev {
  transform: scale(0.25) !important;
  z-index: 0;
  opacity: 0;
  filter: blur(7px);
}

.productSwiperHero {
  width: 100vw;
  left: 0;
  position: absolute;
  overflow: visible !important;
}
.productSwiperHero .swiper-slide {
  box-shadow: 0 10px 20px rgba(42, 42, 42, 0.1), 0 3px 6px rgba(0, 0, 0, 0.066);
  background-color: #E5F2FF;
  transition: 0.7s all;
  gap: 10%;
}
.productSwiperHero .swiper-slide:hover {
  background-color: white;
  transform: translateY(-10px);
}
.productSwiperHero .swiper-slide:hover .productSliderBg {
  background-color: #E5F2FF;
  opacity: 0.1;
}
.productSwiperHero .swiper-slide:hover .productSliderBtn {
  color: #E5F2FF;
}
.productSwiperHero .swiper-slide:hover .productSliderImg {
  transform: scale(1.1);
}
.productSwiperHero .swiper-slide .productSliderImg {
  width: 80%;
  height: 80%;
  -o-object-fit: contain;
     object-fit: contain;
  z-index: 1;
  transition: 0.8s all;
}
.productSwiperHero .swiper-slide .productSliderBtn {
  color: white;
  font-weight: 400;
}
.productSwiperHero .swiper-slide .productSliderBg {
  background-color: white;
  opacity: 0.025;
  width: 100%;
  height: 100%;
  position: absolute;
  pointer-events: none;
}
.productSwiperHero .swiper-slide .productSliderImgPlaceHolder {
  width: 80%;
  border-radius: 0.4em;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
  position: relative;
}

.mf-cursor {
  display: none;
}

@media (min-width: 991.98px) {
  .mf-cursor {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 250000000 !important;
    direction: ltr;
    contain: layout style size;
    pointer-events: none;
    transition: opacity 0.3s, color 0.4s;
  }
  .mf-cursor:before {
    content: "";
    position: absolute;
    top: -24px;
    left: -24px;
    display: block;
    width: 48px;
    height: 48px;
    transform: scale(0.2);
    background: currentColor;
    border-radius: 50%;
    transition: transform 0.25s ease-in-out, opacity 0.1s;
  }
  .mf-cursor.-inverse {
    color: white;
  }
  @supports (mix-blend-mode: exclusion) {
    .mf-cursor.-exclusion {
      mix-blend-mode: exclusion;
    }
    .mf-cursor.-exclusion:before {
      background: white;
    }
  }
  .mf-cursor.-pointer:before {
    transform: scale(0.15);
  }
  .mf-cursor.-text:before {
    opacity: 0.85;
    transform: scale(1.7);
  }
  .mf-cursor.-text.-active:before {
    transform: scale(1.6);
    transition-duration: 0.2s;
  }
  .mf-cursor.-icon:before {
    transform: scale(1.5);
  }
  .mf-cursor.-icon.-active:before {
    transform: scale(1.4);
  }
  .mf-cursor.-hidden:before {
    transform: scale(0);
  }
  .mf-cursor-text {
    position: absolute;
    top: -18px;
    left: -18px;
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: scale(0) rotate(10deg);
    opacity: 0;
    color: #fff;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    transition: opacity 0.4s, transform 0.3s;
  }
  .mf-cursor.-text .mf-cursor-text, .mf-cursor.-icon .mf-cursor-text {
    opacity: 1;
    transform: scale(1);
  }
  .mf-cursor-media {
    position: absolute;
    width: 400px;
    height: 400px;
    margin: -200px 0 0 -200px;
  }
  .mf-cursor-media img,
  .mf-cursor-media video {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
  }
  @supports ((-o-object-fit: cover) or (object-fit: cover)) {
    .mf-cursor-media img,
    .mf-cursor-media video {
      position: static;
      width: 100%;
      height: 100%;
      -o-object-fit: cover;
         object-fit: cover;
      transform: translateZ(0);
    }
  }
  .mf-cursor-media-box {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    transform: scale(0) translateZ(0);
    padding: 1px;
    opacity: 0;
    border-radius: 50%;
    transition: transform 0.35s, opacity 0.2s 0.2s;
  }
  .mf-cursor.-media .mf-cursor-media-box {
    opacity: 1;
    transform: scale(0.696);
    transition-duration: 0.4s, 0.4s;
    transition-delay: 0s, 0s;
  }
}
.createDsBtn {
  margin: 0.5em 0;
}

.dsBtn {
  position: relative;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  width: -moz-fit-content;
  width: fit-content;
  text-align: center;
  cursor: pointer;
  border-radius: 0.4em;
  font-family: urbane, sans-serif;
  font-size: 1.3125rem;
  border: 0;
  margin: 0.55rem 0;
  letter-spacing: 1px;
  color: black;
  text-align: left;
  border-radius: 0;
  padding: 0.35em;
  pointer-events: auto !important;
  cursor: pointer !important;
  box-shadow: 1px 0px 50px 20px rgba(89, 114, 88, 0.0901960784);
  transition-duration: 0.5s;
  filter: brightness(1);
  background-color: #ffd9d9;
  border-radius: 0.32em;
  overflow: hidden;
  height: -moz-fit-content;
  height: fit-content;
  margin: 0;
}
.dsBtn:hover {
  background-color: #ffd9d9;
  color: white;
}
.dsBtn .externalIconOuter {
  display: inline-flex;
  padding: 0.5625rem 0.9375rem;
  align-items: center;
  border-radius: 0.16em;
  gap: 0.65625rem;
}
.dsBtn .externalIconOuter .externalIcon {
  background-color: white;
  font-size: 1.859375rem;
  display: inline-flex;
  vertical-align: top;
  color: white;
  padding: 0.15em;
  border-radius: 0.08em;
}
.dsBtn .externalIconOuter i {
  transition-duration: 0.5s;
  font-size: 0.9625rem;
  letter-spacing: normal;
  display: flex;
  width: -moz-fit-content;
  width: fit-content;
  margin: 0 0.55rem;
  justify-content: center;
  text-align: center;
  color: black;
}

.backContainer {
  z-index: 399;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: fixed;
  left: 0;
  margin: 0%;
  transform: scale(0.7);
}
@media (min-width: 767.98px) {
  .backContainer {
    margin: 2%;
    transform: scale(1);
  }
}
.backContainer .glowGreen {
  position: absolute;
  opacity: 0.75;
  height: 0.25em;
  width: 0.25em;
  left: auto;
  top: auto;
  transform: scale(0.2);
}
.backContainer button {
  position: absolute;
  z-index: 1;
}

.closeBtnOutline {
  width: 5.5em;
  height: 5.5em;
  border-radius: 4000em;
  border: 1px #ff4c4f solid;
}

.closeBtn {
  z-index: 1000;
  background-color: black;
  color: white;
  padding: 0.5em;
  transition-duration: 0.5s;
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 100em;
  font-size: 1.75em !important;
  cursor: pointer !important;
  box-shadow: 0 10px 20px rgba(42, 42, 42, 0.1), 0 3px 6px rgba(0, 0, 0, 0.066);
}
@media (min-width: 1199.98px) {
  .closeBtn {
    padding: 0.75em;
  }
}
.closeBtn:hover {
  background-color: grey;
  color: black;
}
.closeBtn:active {
  background-color: black;
  color: white;
}

.lightBtn {
  padding: 0.375rem 0.9375rem;
  border-radius: 0.8em;
  background-color: #E5F2FF;
  width: -webkit-fill-available;
  border: 1px solid #D3E4F5;
}
@media (min-width: 991.98px) {
  .lightBtn:hover {
    background-color: #D3E4F5;
  }
}
.lightBtn:hover .dsRoundBtn {
  background-color: white;
  transform: scale(0.9);
}
.lightBtn:hover .dsRoundBtn .dashedLine {
  transform: rotate(180deg);
  opacity: 0.7;
}

.vl-button {
  font-size: 1.1em;
  padding: 0.65rem 1.25rem;
  gap: 0.5em;
  border: 1px solid transparent;
  border-radius: 0.4em;
  box-sizing: border-box;
  cursor: pointer;
  text-decoration: none;
  color: #fff;
  font-weight: 600;
  text-align: center;
  transition: all 0.3s ease-in-out;
  display: inline-block;
  vertical-align: top;
  white-space: nowrap;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  box-shadow: 0 10px 20px rgba(42, 42, 42, 0.1), 0 3px 6px rgba(0, 0, 0, 0.066);
}
.vl-button .material-icons-outlined {
  height: 1em;
}
@media (min-width: 991.98px) {
  .vl-button {
    padding: 0.75rem 1.5rem;
    min-width: 160px;
    gap: 1em;
    font-size: 1em;
  }
  .vl-button .material-icons-outlined {
    height: 1em;
    transform: scale(1.2);
  }
}

.vl-button-small {
  font-size: 0.85em;
  padding: 0.4rem 1rem;
  gap: 0.5em;
}
.vl-button-small .material-icons-outlined {
  height: 1em;
  transform: scale(1);
}

.dsRoundBtn {
  pointer-events: auto;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 3em;
  height: 3em;
  border-radius: 100em;
  color: #E5F2FF;
  background-color: #FB0207;
  position: relative;
  transition: 0.15s all;
}
.dsRoundBtn:hover {
  background-color: #ff4c4f;
}
.dsRoundBtn > * {
  cursor: pointer;
}
@media (min-width: 991.98px) {
  .dsRoundBtn {
    width: 4.5em;
    height: 4.5em;
  }
}
.dsRoundBtn .material-icons-outlined {
  font-size: 1.25em;
}
@media (min-width: 991.98px) {
  .dsRoundBtn .material-icons-outlined {
    font-size: 1.25em;
  }
}
.dsRoundBtn .dashedLine {
  position: absolute;
  width: 90%;
  height: 90%;
  transition: 0.5s all;
}

.parentTmpButton {
  width: -moz-fit-content;
  width: fit-content;
}

.tmpBtn {
  font-size: 1.1em;
  padding: 0.65rem 1.25rem;
  gap: 0.5em;
  border: 1px solid transparent;
  border-radius: 0.4em;
  box-sizing: border-box;
  cursor: pointer;
  text-decoration: none;
  color: #fff;
  font-weight: 600;
  text-align: center;
  transition: all 0.3s ease-in-out;
  display: inline-block;
  vertical-align: top;
  white-space: nowrap;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  box-shadow: 0 10px 20px rgba(42, 42, 42, 0.1), 0 3px 6px rgba(0, 0, 0, 0.066);
}
.tmpBtn .material-icons-outlined {
  height: 1em;
}
@media (min-width: 991.98px) {
  .tmpBtn {
    padding: 0.75rem 1.5rem;
    min-width: 160px;
    gap: 1em;
    font-size: 1em;
  }
  .tmpBtn .material-icons-outlined {
    height: 1em;
    transform: scale(1.2);
  }
}

.tmpBtn {
  background-color: #FB0207;
}
.tmpBtn:hover {
  background-color: #ff4c4f;
}
.tmpBtn:hover i {
  opacity: 0.5;
}
.tmpBtn .arrowInside1,
.tmpBtn .arrowInside2,
.tmpBtn i {
  color: white;
}
.tmpBtn i {
  font-weight: 600;
}
.tmpBtn .btnArrowContainer {
  background: #ff4c4f;
  width: -moz-fit-content;
  width: fit-content;
  color: white;
  width: 2em;
  height: 2em;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 0.2em;
  overflow: hidden;
  position: relative;
  pointer-events: none;
}
.tmpBtn .btnArrowContainer .arrowInside {
  position: absolute;
  font-size: 1.1em;
  font-weight: 500;
}

.InfoIconRight {
  background-color: #FB0207;
  width: 2.5em;
  height: 2.5em;
  padding-top: 0.4em;
  padding-left: 0.4em;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: white;
  border-radius: 3em 0 0 0;
  bottom: 0;
  right: 0;
  transform-origin: bottom right;
}

.tmpCloseBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0;
  padding: 0.75em;
  background-color: #FB0207;
  color: white;
  box-shadow: 0 10px 20px rgba(42, 42, 42, 0.1), 0 3px 6px rgba(0, 0, 0, 0.066);
  border-radius: 10000em;
  z-index: 1;
  cursor: pointer;
}
.tmpCloseBtn div {
  font-size: 1.75em;
}

.CookiePolicyPP {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  top: 0;
  z-index: 1000;
}
.CookiePolicyPP .CookiePolicy-exitbg {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.75);
}
.CookiePolicyPP .CookiePolicy-inner {
  width: 75vw;
  display: flex;
  flex-direction: column;
  max-width: 50em;
  gap: 1em;
  position: relative;
  background-color: #fff;
  border-radius: 1.2em;
  box-shadow: 0 10px 20px rgba(42, 42, 42, 0.1), 0 3px 6px rgba(0, 0, 0, 0.066);
  padding: 3rem 1.875rem;
}
@media (min-width: 991.98px) {
  .CookiePolicyPP .CookiePolicy-inner {
    padding: 3rem;
  }
}
.CookiePolicyPP .CookiePolicy-inner .CookiePolicy-title {
  font-size: 1.75em;
  width: 80%;
}
.CookiePolicyPP .closeBtn {
  right: 1.5rem;
  top: 1.5rem;
  position: absolute;
  float: right;
}
@media (min-width: 991.98px) {
  .CookiePolicyPP .closeBtn {
    right: 2.25rem;
  }
}

.introLayerdSection {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  position: fixed;
  z-index: 1000;
}
.introLayerdSection .introLayerdWrapper {
  position: absolute;
  position: fixed;
  background-color: rgba(50, 10, 10, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.introLayerdSection .introLayerdWrapper .introOuter {
  width: 100vw;
  height: 100vh;
  background: black;
  position: absolute;
  transform-origin: top;
  top: 0;
}
.introLayerdSection .introLayerdWrapper .introOuter .introInner {
  top: 0;
  transform-origin: top;
  background: #fff;
  height: 100vh;
  width: 100vw;
  position: absolute;
}

.countDownCounterDUMS {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 0.5em;
}
.countDownCounterDUMS .countDownItemWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 0.15em;
}
.countDownCounterDUMS .countDownItemWrapper .countDownItem {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 1.65em;
  height: 1.65em;
  background-color: #e8e8e8;
  border: solid 1px lightgrey;
  border-radius: 0.2em;
  font-size: 2em;
}
@media (min-width: 991.98px) {
  .countDownCounterDUMS .countDownItemWrapper .countDownItem {
    font-size: 2.5em;
  }
}
.countDownCounterDUMS .countDownItemWrapper p {
  font-size: 0.85em;
}

.p-0 {
  padding: 0;
}

.p-1 {
  padding: 0.75rem;
}

.p-2 {
  padding: 1.5rem;
}

.p-3 {
  padding: 3rem;
}

.p-4 {
  padding: 4.5rem;
}

.p-5 {
  padding: 6rem;
}

.pl-0 {
  padding-left: 0;
}

.pl-1 {
  padding-left: 0.75rem;
}

.pl-2 {
  padding-left: 1.5rem;
}

.pl-3 {
  padding-left: 3rem;
}

.pl-4 {
  padding-left: 4.5rem;
}

.pl-5 {
  padding-left: 6rem;
}

.pr-0 {
  padding-right: 0;
}

.pr-1 {
  padding-right: 0.75rem;
}

.pr-2 {
  padding-right: 1.5rem;
}

.pr-3 {
  padding-right: 3rem;
}

.pr-4 {
  padding-right: 4.5rem;
}

.pr-5 {
  padding-right: 6rem;
}

.pt-0 {
  padding-top: 0;
}

.pt-1 {
  padding-top: 0.75rem;
}

.pt-2 {
  padding-top: 1.5rem;
}

.pt-3 {
  padding-top: 3rem;
}

.pt-4 {
  padding-top: 4.5rem;
}

.pt-5 {
  padding-top: 6rem;
}

.pb-0 {
  padding-bottom: 0;
}

.pb-1 {
  padding-bottom: 0.75rem;
}

.pb-2 {
  padding-bottom: 1.5rem;
}

.pb-3 {
  padding-bottom: 3rem;
}

.pb-4 {
  padding-bottom: 4.5rem;
}

.pb-5 {
  padding-bottom: 6rem;
}

.m-0 {
  margin: 0;
}

.m-1 {
  margin: 0.55rem;
}

.m-2 {
  margin: 1.1rem;
}

.m-3 {
  margin: 2.2rem;
}

.m-4 {
  margin: 3.3rem;
}

.m-5 {
  margin: 4.4rem;
}

.ml-0 {
  margin-left: 0;
}

.ml-1 {
  margin-left: 0.55rem;
}

.ml-2 {
  margin-left: 1.1rem;
}

.ml-3 {
  margin-left: 2.2rem;
}

.ml-4 {
  margin-left: 3.3rem;
}

.ml-5 {
  margin-left: 4.4rem;
}

.mr-0 {
  margin-right: 0;
}

.mr-1 {
  margin-right: 0.55rem;
}

.mr-2 {
  margin-right: 1.1rem;
}

.mr-3 {
  margin-right: 2.2rem;
}

.mr-4 {
  margin-right: 3.3rem;
}

.mr-5 {
  margin-right: 4.4rem;
}

.mt-0 {
  margin-top: 0;
}

.mt-1 {
  margin-top: 0.55rem;
}

.mt-2 {
  margin-top: 1.1rem;
}

.mt-3 {
  margin-top: 2.2rem;
}

.mt-4 {
  margin-top: 3.3rem;
}

.mt-5 {
  margin-top: 4.4rem;
}

.mb-0 {
  margin-bottom: 0;
}

.mb-1 {
  margin-bottom: 0.55rem;
}

.mb-2 {
  margin-bottom: 1.1rem;
}

.mb-3 {
  margin-bottom: 2.2rem;
}

.mb-4 {
  margin-bottom: 3.3rem;
}

.mb-5 {
  margin-bottom: 4.4rem;
}

.o-0 {
  opacity: 0;
}

.o-10 {
  opacity: 0.1;
}

.o-20 {
  opacity: 0.2;
}

.o-30 {
  opacity: 0.3;
}

.o-40 {
  opacity: 0.4;
}

.o-50 {
  opacity: 0.5;
}

.o-60 {
  opacity: 0.6;
}

.o-70 {
  opacity: 0.7;
}

.o-80 {
  opacity: 0.8;
}

.o-90 {
  opacity: 0.9;
}

.o-100 {
  opacity: 1;
}

.br {
  border-radius: 0.8em;
}

.br-none {
  border-radius: 0;
}

.br-lg {
  border-radius: 1.6em;
}

.br-full {
  border-radius: 50%;
}

.font-sm {
  font-size: 0.74375rem;
}

.font-md {
  font-size: 0.875rem;
}

.font-lg {
  font-size: 1.3125rem;
}

.font-xl {
  font-size: 1.4875rem;
}

.font-xxl {
  font-size: 2.625rem;
}

.articleHz {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  align-items: flex-start;
  width: 95%;
  padding: 0 2.5%;
  gap: 1em;
}
@media (min-width: 1199.98px) {
  .articleHz {
    width: 100%;
    padding: 0;
    flex-direction: row;
    text-align: left;
    max-width: 100em;
  }
}
@media (min-width: 1699.98px) {
  .articleHz {
    gap: 2em;
  }
}
.articleHz article {
  display: flex;
  flex-direction: column;
  gap: 0.85em;
}
.articleHz > :nth-child(1) {
  width: 100%;
}
.articleHz > :nth-child(2) {
  width: 100%;
}
.articleHz > :nth-child(3) {
  width: 100%;
}
@media (min-width: 1199.98px) {
  .articleHz > :nth-child(1) {
    width: -moz-fit-content;
    width: fit-content;
    padding: 0.75rem;
    max-width: 12.5em;
  }
  .articleHz > :nth-child(2) {
    width: 50%;
    padding: 0.75rem;
    padding-top: 0.9375rem;
  }
}

.articleVt {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  align-items: flex-start;
  width: 95%;
  padding: 0 2.5%;
  gap: 1em;
}
@media (min-width: 1199.98px) {
  .articleVt {
    width: 100%;
    padding: 0;
    flex-direction: row;
    text-align: left;
    max-width: 100em;
  }
}
@media (min-width: 1699.98px) {
  .articleVt {
    gap: 2em;
  }
}
@media (min-width: 1199.98px) {
  .articleVt {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    align-items: flex-start;
  }
}

.articleBorderTop {
  border-top: solid white 1px;
}

.motionPathContainer {
  width: 100vw;
  pointer-events: none;
}
.motionPathContainer .cmpPlane {
  visibility: hidden;
  width: 5em;
}
@media (min-width: 1199.98px) {
  .motionPathContainer .cmpPlane {
    width: 8em;
  }
}
.motionPathContainer path {
  stroke: #ff4c4f;
  stroke-width: 1.5;
  stroke-dasharray: 8;
}
@media (min-width: 767.98px) {
  .motionPathContainer path {
    stroke-width: 1.1;
    stroke-dasharray: 5;
  }
}
@media (min-width: 1199.98px) {
  .motionPathContainer path {
    stroke-width: 0.35;
    stroke-dasharray: 1.5;
  }
}

#mpWerkwijze,
#mpAnalytics {
  position: absolute;
}

.placeHolder {
  display: flex;
  justify-content: center;
  align-items: center;
}

.container {
  position: relative;
}/*# sourceMappingURL=index.css.map */