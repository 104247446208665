//colors
$button: #FB0207;
$buttonSecondary: #ff4c4f;

$menuButton: #ebf5ff;
$menuButtonHover:#2975c0;
$menuButtonActive:#000056;
$menuBgc: #d2e8ff;

$contrastColor: #e60f13;

$bgc: #3385DB;
$bgcSecondary: #e60f13;
$bgcThird: #d2e8ff;
$bgcFourth: #00005635;
$bgcTransparent: radial-gradient(circle, rgba(100, 100, 100, 0) 20%, rgba(0, 0, 0, 0.15) 80%);


$txtColor: white;
$txtColorSecondary: #0f3660;
.phlatball-speelgoed-a,
.phlatball-speelgoed-b {
    >* {
        .TextComponentCore {
            text-align: center;
            align-items: center;
        }
    }
}

.SpeelgoedTemp {
    #StandaardInfo2,
    #StandaardImageViewer {
        box-shadow: 0 4px 4px $bgc;
    }
    #standaardHero1 {
        .inner{
            gap: 5vh;
            height: 90vh !important;
        }

        .TextComponentCore {

            h1 {
                @include sm {
                    margin-top: -5vh;
                    font-size: 4em;
                }

                @include md {
                    font-size: 5em;
                }
                @include xl{
                    font-size: 6em;

                }
            }
        }
    }

    >* {
        .TextComponentCore {
            width: 95% !important;
            margin-left: 2.5%;
            margin-right: 2.5%;
        }
    }

}




// Core components.
@import '../../../components/ImageComponent/ImageComponentCore';
// @import '../../../components/formComponents/formComponentCore';
@import '../../../components/formComponents/formBase/formBaseCore';
@import '../../../components/formComponents//preMadeTemplates/preMadeFormTemplates';
@import '../../../components/textComponent/textComponentCore';
@import '../../../components/swiperComponent/swiperComponentCore';
@import '../../../components/footerComponent/StandaardFooterCore';
@import '../../../components/videoComponent/videoPopUpComponent';
@import '../../../components/zetInAgendaComponent/zetInAgendaComponent';
    
//Modules
@import '../../../core/StandaardHero/core/StandaardHeroCore';
@import '../../../core/StandaardHero/StandaardHeroC';
@import '../../../core/StandaardHero/StandaardHeroB';
@import '../../../core/StandaardHero/StandaardHero2';

@import '../../../core/StandaardInfo/core/StandaardInfoCore';
@import '../../../core/StandaardInfo/StandaardInfo0';
@import '../../../core/StandaardInfo/StandaardInfo1';
@import '../../../core/StandaardInfo/StandaardInfo2';

@import '../../../core/StandaardImageViewer/core/StandaardImageViewerCore';
// @import '../../../core/StandaardImageViewer/StandaardImageViewer';
@import '../../../core/StandaardImageViewer/StandaardImageViewerB';

@import '../../../core/StandaardVideo/core/StandaardVideoCore';
@import '../../../core/StandaardVideo/StandaardVideo';

@import '../../../core/StandaardRetail/core/StandaardRetailCore';
@import '../../../core/StandaardRetail/StandaardRetail';

@import '../../../core/StandaardHeaderMenu/core/StandaardHeaderMenuCore';
@import '../../../core/StandaardHeaderMenu/StandaardHeaderMenu0';
@import '../../../core/StandaardHeaderMenu/StandaardHeaderMenu1';
@import '../../../core/StandaardHeaderMenu/StandaardHeaderMenu2';