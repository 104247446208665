.productSwiperHero {
    width: 100vw;
    left: 0;
    position: absolute;
    overflow: visible !important;

    .swiper-slide {
        box-shadow: $base-box-shadow;
        background-color: $bgColor;
        transition: .7s all;
        gap: 10%;


       

        &:hover {
            background-color: $txtColor;
            transform: translateY(-10px);

            .productSliderBg {
                background-color: $bgColor;
                opacity: .1;
            }

            .productSliderBtn {
                color: $bgColor;
            }

            .productSliderImg {
                transform: scale(1.1);
            }
        }

        .productSliderImg {
            width: 80%;
            height: 80%;
            object-fit: contain;
            z-index: 1;
            transition: .8s all;
        }

        .productSliderBtn {
            color: $txtColor;
            font-weight: 400;
        }

        .productSliderBg {
            background-color: $txtColor;
            opacity: .025;
            width: 100%;
            height: 100%;
            position: absolute;
            pointer-events: none;
        }

        .productSliderImgPlaceHolder {
            width: 80%;
            border-radius: $base-border-radius *.5;
            @include flexCenter(column);
            overflow: hidden;
            position: relative;
        }
    }


}