$dfw-Bg:#fff2c7;
$dfw-Bg-Gradient: rgb(64, 49, 0);
$dfw-Button: #1387cf;
$dfw-Button-Font: #f6f6ff;
$dfw-Button-Hover: #044c7b;
$dfw-Button-Hover-Font: #c2c2ff;
$dfw-Font: #000000;
$dfw-Header: #002a46;


$gradientLight: radial-gradient(at 40% 20%, hsla(7, 78%, 59%, 1) 0px, transparent 50%),
radial-gradient(at 80% 100%, hsla(0, 100%, 88%, 1) 0px, transparent 50%),
radial-gradient(at 0% 0%, hsla(343, 100%, 76%, 1) 0px, transparent 50%);
$gradientDark:linear-gradient(180deg, #000000 7%, #1a1a1a 100%);




// color pallete
$colors: ("oranje": $orange,
    "blauw": $blue,
    "xlGreen":$xlGreen,
);