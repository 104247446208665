.swiper-slide {
    user-select: none;
}

.dfltSwiperSettings {
    width: 100vw;
    height: 20em;
    color: $dfw-Font;

    .swiper-slide {
        width: 5em;
        @include flexCenter(column);
        border-radius: 8px;
        font-size: 22px;
        font-weight: bold;
        color: #fff;
        // background-color: purple;
        width: 1em;
        overflow: hidden;

        img {
            height: 100%;
            width: 100%;
            object-fit: contain;
        }
    }
}