.StandaardVideo {
    background-color: $bgc;
    padding-top: 5vh;
    padding-bottom: 5vh;
    .titleContainer {
        .title {
            color: $txtColor;
        }
        .aantal{
            color: $button;
        }
    }
    .thumbnail{
        background-color: black;
    }
    .thumbContainer{
        background-color: $bgcThird;
        border-radius: $base-border-radius;
    }
    .thumbSubDescription {
        color: $txtColorSecondary;

        .duration {
            color: $txtColorSecondary;
            background-color: rgba(255, 255, 255, 0.25);
            border-radius: $base-border-radius *.25;
            padding: $base-padding *.25  $base-padding;
        }
    }
}