#ZetInAgendaModule {
    @include flexCenter(column);

    .zetInAgendaBtnWrapper {
        @include flexCenter(column);

        @include md {
            @include flexCenter(row);
            margin-bottom: 5em;
        }
        .dfwBtn{
            font-size: 1.2em;
            padding: 1rem 1.5rem;
        }
        gap: 1em;
        padding: 1em 0;
    }
}