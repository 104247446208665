.dsFromSection-Pdf,
.dsFromSection-Newsletter {
    padding: 5vh 0vh;

    h3 {
        margin-bottom: .75em;
    }

    .dsForm {
        @include flexCenter(row);
        align-items: flex-start;

        .dsEmailInputField {
            width: 50%;
        }
    }
}