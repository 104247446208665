// VERSIE 1.0
// PAS DEZE FILE NIET ZOMAAR AAN 

.standaardHero {
    width: 100vw;
    height: fit-content;
    // overflow: hidden;
    position: relative;
    @include flexCenter(column);
    justify-content: flex-start;
    background-color: $bgc;
    height: 100vh;

    .tmpBtn {
        opacity: 0;
    }
    .scrollDownButton {
        z-index: 10;
        position: absolute;
        cursor: pointer;
        bottom: 0;
        margin-bottom: 15vh;

        @include sm {
            margin-bottom: 15vh;
            transform: scale(1.35);
        }

        @include xl {
            margin-bottom: 5em;
            right: 7.5em;
        }
    }

    .svgOverlay {
        width: 100%;
        height: 100%;
        position: absolute;
        bottom: 0;
        @include flexCenter(column);
        pointer-events: none;

        svg {
            min-width: 105vw;
            height: fit-content;
            position: absolute;
            bottom: 0vh;
            fill: $bgc;
        }
    }

    .parent {
        width: 100vw;
        background-color: $bgc;
        position: relative;
        @include fullWidth();
        @include flexCenter(column);
        padding-bottom: 0;

        .inner {
            @include flexCenter(column);
            width: 100% !important;
            width: fit-content;
            gap: 2.5vh;
            height: 80%;
            @include md {
                @include flexCenter(row);
                height: 100%;
            }


            @include lg{
                height: 100%;
            }

            .logo {
                width: 25%;
                left: 0;
            }

            aside {
                @include md {
                    max-width: 80vw;
                }
            }

            .ph-2,
            .ph-1 {
                position: relative;
                @include flexCenter(column);
                gap: 1em;
                max-height: 100vh;

                @include md {
                    width: 50%;
                }



                .SwiperComponentCoreWrapper {
                    width: 100%;
                    max-width: 100vw;
                    color: black;
                }
            }
        }

        .overlay,
        .gradient {
            width: 100vw;
            height: 100%;
            position: absolute;
            bottom: 0;
        }

        // .overlay {
        //     background-color: rgba(0, 255, 255, 0.25);
        // }

        .gradient {
            background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
        }

        .bgImgWrapper {
            width: 100%;
            height: 100%;
            top: 0;
            @include flexCenter(column);
            position: absolute;
            overflow: hidden;
            justify-content: flex-end;

            video {
                min-width: 100vw;
                // object-fit: cover;
                min-height: 100vh;
                position: absolute;
                top: 0;
            }

            .bgImgContainer {
                width: 100vw;
                height: 100vh;
                position: absolute;
            }

            .bgImg {
                position: absolute;
                width: 100%;
            }
        }
    }
}