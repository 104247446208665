
@mixin articleHz() {
    align-items: flex-start;
    width: 95%;
    // text-align: center;
    padding: 0 2.5%;

    @include lg {
        width: 100%;
        padding: 0;
        flex-direction: row;
        text-align: left;
        max-width: 100em;
    }

    @include xxl {
        gap: 2em;
    }
}

.articleHz {
    @include flexCenter(column);
    @include articleHz();
    gap: 1em;

    article {
        display: flex;
        flex-direction: column;
        gap: .85em;
    }

    > :nth-child(1) {
        width: 100%;
    }

    > :nth-child(2) {
        width: 100%;
    }

    > :nth-child(3) {
        width: 100%;
    }

    @include lg {
        > :nth-child(1) {
            width: fit-content;
            padding: $base-padding;
            max-width: 12.5em;
        }

        > :nth-child(2) {
            width: 50%;
            padding: $base-padding;
            padding-top: $base-padding *1.25;
        }
    }
}

.articleVt {
    @include flexCenter(column);
    @include articleHz();
    gap: 1em;

    @include lg {
        @include flexCenter(column);
        align-items: flex-start;

    }
}

.articleBorderTop {
    border-top: solid white 1px;
}