* {
    color: inherit;
    margin: 0;
    font-style: normal;
}

body {
    overflow-x: hidden;
    margin: 0;
    font-family: urbane, sans-serif;
    max-width: 100vw;
    width: 100vw;
    position: relative;
}
@media 
(-webkit-min-device-pixel-ratio: 1.5), 
(min-resolution: 144dpi){ 
    /* Retina-specific stuff here */
}
button{
    font-family: urbane, sans-serif;
}
#smoothWrapper{
    max-width: 100vw;
    overflow: hidden;
}

#root {
    width: 100%;
    max-width: 100vw;
    overflow: hidden;

    // display: flex;
    // align-items: center;
    // flex-direction: column;
}

a {
    text-decoration: none;
}

ul {
    padding: 0;
    list-style-type: none;
}


