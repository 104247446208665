#StandaardInfo0 {
    background-color: $bgc;

    .parent {
        .inner {
            gap: 5vh;
            width: 82%;
            padding: 15% 7.5%;
            margin-top: -10vh;
            border-radius: $base-border-radius *2;
            overflow: hidden;
            background-color: $bgcThird;
            @include sm{
                @include flexCenter(column);
            }

            @include md {
                width: 65%;
                padding: 5% 7.5%;
                gap: 2.5vw;
                @include flexCenter(row-reverse);
            }

            >* {
                color: $txtColorSecondary;
            }

            .btnWrapper {
                align-items: flex-start;
            }

            .ph-2 {
                order: 1;

            }

            .ph-1 {
                order: 2;
                flex-direction: row;

                .TextComponentCore {
                    text-align: left;
                    align-items: flex-start;
                }

                .image {
                    width: 100%;
                }
            }

        }
    }
}