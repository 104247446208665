// VERSIE 1.0
// PAS DEZE FILE NIET ZOMAAR AAN 
#standaardHero1 {
    .ImageComponentCore {
        a {
            height: 85%;
        }
    }

    .parent {
        min-height: 100vh;
        justify-content: flex-start;

        .inner {
            flex-direction: column;
            height: 100vh;

            .swiperSlide {
                margin-top: 2.5vh;
                height: 45vh;
                max-height: 22.5em;

                @include md {
                    margin-top: 0vh;
                    height: 50vh;
                }
            }

            .ph-1,
            .ph-2 {
                max-width: 100vw;
            }

            .ph-2 {
                order: 1;
                // height: 100%;
                max-height: 30vh;
                @include sm{
                    max-height: 25vh;
                    margin-top: -35vh;
                }
                @include xl{
                    margin-top: unset;
                }
            }

            .ph-1 {
                // align-items: flex-start;
                color: white;
                order: 2;

                .TextComponentCore {
                    align-items: center;
                    text-align: center;
                    gap: .5em;
                }

                width: 100%;
            }
        }

        // .overlay {
        //     background-color: rgba(0, 255, 255, 0.25);
        // }
        .gradient {
            background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
            height: 100%;
            position: absolute;
        }
    }
}