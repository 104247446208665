.verticalSwiper {
    height: 85vh;
    pointer-events: none !important;

    .swiper-slide {
        width: 90%;
        margin-left: 5%;
        background: black;
        transition: all 550ms ease-out;
    }



    .swiper-slide-active {
        z-index: 4;
    }

    .swiper-button-prev,
    .swiper-button-next {
        pointer-events: none;
        user-select: none !important;
        -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
        right: 0;
        left: 0;
        width: 100%;
        top: 0 !important;
        @include flexCenter(column);

        &:after {
            width: .8em;
            height: .8em;
            @include flexCenter(column);
            border-radius: 100em;
            background: #000;
            border: solid white 1px;
            font-size: 2em;
            line-height: 0 !important;
            color: white;
            position: absolute;
            pointer-events: auto !important;

        }
    }

    .swiper-button-next {
        margin-top: 10vh;

        &:after {
            transform: rotate(-90deg);
            padding: .5em;
            padding-left: .55em;
        }
    }

    .swiper-button-prev {
        margin-top: 70vh;

        &:after {
            transform: rotate(-90deg);
            padding: .5em;
            padding-right: .55em;
        }
    }

    .swiper-slide-next {
        transform: scale(.25) translateY(30vh) !important;
        z-index: 0;
        opacity: 0;
        filter: blur(7px);
    }

    .swiper-slide-prev {
        transform: scale(.25) !important;
        z-index: 0;
        opacity: 0;
        filter: blur(7px);
    }
}