#basicHeader0 {
    .headerInner {
        justify-content: space-between;
        
    }

    .menuItemsWrapper {
        padding: 0 1.5vw;
        height: 100%;
        background-color: $bgcThird;
        border-radius: $base-border-radius *.75;
        right: 0;
        justify-content: space-around;
        gap: 1.25em;
        overflow: hidden;
        box-shadow: 0 19px 38px rgba(0, 0, 0, 0.10), 0 5px 12px rgba(0, 0, 0, 0.22);

        .parentTmpButton {
            margin-left: 2.5vw;
        }
    }
}