.StandaardFooter {
    background-color: black;
    @include fullWidth();
    @include flexCenter(row);
    padding-top: .75em !important;
    padding-bottom: .75em !important;
    // justify-content: space-between;
    justify-content: flex-end;
    gap: 2em;

    .columnWrapper {
        @include flexCenter(row);
        // width: 100%;
        height: 100%;
        gap: 2em;

    }

    ul {
        color: rgba(255, 255, 255, 0.5);
        font-weight: 300;

        >* {
            cursor: pointer;
            &:hover{
                color: white;
            }
        }

        li {
            margin: .25em 0;
        }

        a {
            cursor: pointer;
            font-size: .8em;

            &:hover {
                color: white;
            }
        }
    }

    .imageRow {
        @include flexCenter(row);

        .footerImgContainer {
            position: relative;

            img {
                width: 100%;
                max-width: 3.5em;
            }
        }
    }
}