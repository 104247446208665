.StandaardRetail {
    @include fullWidth();
    @include flexCenter(column);
    min-height: 10em;
    background-color: $bgcSecondary;
    gap: 2.5em;
    padding-top: 10vh !important;
    padding-bottom: 17.5vh !important;

    .TextComponentCore {
        color: $txtColor;


        @include md {
            width: 80%;
            // padding-right: 20%;

            h2 {
                width: 80%;
            }
        }

    }

    .gridWrapper {
        @include flexCenter(row);
        width: 100%;
        flex-wrap: wrap;
        gap: 1em;

        @include md {
            width: 90vw;
        }
        @include lg{
            @include fullWidth();
            justify-content: flex-start;

        }
        .gridItem {
            width: 20%;
            min-width: 15em;
            background-color: #fff;
            border-radius: $base-border-radius;
            padding: $base-padding*2.5 $base-padding*1.5;
            @include flexCenter(column);
            gap: 1em;
            min-height: 18em;
            cursor: pointer;
            box-shadow: $base-box-shadow;

            &:hover {
                background-color: $bgcThird;
            }

            .imagePlaceholder {
                @include flexCenter(column);
                width: 100%;
            }

            .gridItemTitle {
                font-size: 1.5em;
                font-weight: 600;
            }

  

            img {
                width: 80%;
                max-width: 60%;
                max-height: 5em;
                object-fit: contain;
            }
        }
    }

    .SwiperComponentCore {
        overflow: visible;
        width: 100%;
        margin: 5vh 0vh;

        .swiper-wrapper {
            width: 100%;

            .swiper-pagination-bullets {
                opacity: 0 !important;
            }

            .swiperSlide {
                .title {
                    font-size: 1.5em;
                    font-weight: 600;
                    color: $txtColorSecondary;
                }

                .placeholder {
                    background-color: transparent;

                    img {
                        width: 80%;
                        max-width: 60%;
                        max-height: 5em;
                        object-fit: contain;
                    }
                }
            }
        }
    }
}