$breakpoints: ("xs": 575.98px, // X-Small devices (portrait phones, less than 576px)
    "sm": 767.98px, // Small devices (landscape phones, less than 768px)
    "md": 991.98px, // Medium devices (tablets, less than 992px)
    "lg": 1199.98px, // Large devices (desktops, less than 1200px)
    "xl": 1399.98px, // X-Large devices (large desktops, less than 1400px)
    "xxl": 1699.98px, // XX-Large devices (larger desktops)
    "xxxl": 2299.98px, // XX-Large devices (larger desktops),
    "mobileLandscape": "landscape", // Mobile landscape devices (smaller dan 925px)
    "maxWidthLandscape": 1199.98px,
    "tabletPortrait": "portrait", // Tablet portrait (of desktop)(breeder dan sm)
);

@mixin pt {
    @media (orientation: map-get($breakpoints, "tabletPortrait")) {
        @media (min-width: map-get($breakpoints, "sm")) {
            @content;
        }
    }
}

@mixin lc {
        @media (orientation: map-get($breakpoints, "mobileLandscape")) {
        @media (max-width: map-get($breakpoints, "maxWidthLandscape")) {
            @content;
        }
    }
}

@mixin xs {
    @media (min-width: map-get($breakpoints, "xs")) {
        @content;
    }
}

@mixin sm {
    @media (min-width: map-get($breakpoints, "sm")) {
        @content;
    }
}

@mixin md {
    @media (min-width: map-get($breakpoints, "md")) {
        @content;
    }
}

@mixin lg {
    @media (min-width: map-get($breakpoints, "lg")) {
        @content;
    }
}

@mixin xl {
    @media (min-width: map-get($breakpoints, "xl")) {
        @content;
    }
}

@mixin xxl {
    @media (min-width: map-get($breakpoints, "xxl")) {
        @content;
    }
}
@mixin xxxl {
    @media (min-width: map-get($breakpoints, "xxxl")) {
        @content;
    }
}
@mixin breakpoint($bp: 0) {
    @media (min-width: $bp) {
        @content;
    }
}