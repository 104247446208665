$dsFormFont: 'urbane';
@import '../formBase/formVideo/dsVideoFormBase';

.dsFromSection {
    background-color: rgb(231, 220, 255);
    @include flexCenter(column);

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
        transition: background-color 5000s ease-in-out 0s, color 5000s ease-in-out 0s;
        transition-delay: background-color 5000s, color 5000s;
    }

    .dsFormSwiper {
        overflow: visible;
        width: 100%;

        .bgImg {
            position: absolute;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        label {
            flex-direction: row !important;
            border-radius: 0 !important;
            box-shadow: unset !important;
            left: 10px !important;
            z-index: 100;
            position: absolute !important;
            bottom: 10px !important;
            background-color: unset !important;
            // width: 100% !important;
            // height: 100% !important;
            height: fit-content;

            &:hover {
                background-color: unset !important;
            }

            >* {
                font-size: 1.45em !important;
                font-family: $dsFormFont !important;
            }

            .title {
                color: white;

            }
        }

        .clickArea {
            width: 140vh;
            height: 140vh;
            // background-color: blue;
            position: absolute;
            z-index: 100;
            left: -10%;
            top: -500%;
        }

        .gradient {
            background: linear-gradient(0deg, rgba(0, 0, 0, 1) 20%, rgba(0, 0, 0, 0) 100%);
            width: 100%;
            height: 50%;
            position: absolute;
            bottom: 0px;
            z-index: 100;
        }

        .swiper-slide {
            overflow: hidden;
            position: relative;
        }

        .swiper-button-next,
        .swiper-button-prev {
            color: purple;
            margin-left: 1em;
            margin-right: 1em;

            @include md {
                margin: 0;
            }

            &:active {
                background-color: purple;
            }

            &:hover {
                @include md {
                    background-color: purple;
                }
            }
        }
    }

    .dsForm,
    .nameFieldsWrapper {
        gap: .5em;
    }

    .dsForm {
        @include fullWidth();
        padding-top: 0vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .dsListItemWrapper {
            @include flexCenter(column);
            gap: .5em;
            font-family: $dsFormFont !important;
        }

        .dsLabelTitel {
            order: 1;
            text-align: center
        }

        .dsListItemContainer {
            @include flexCenter(column);
            gap: .5em;
            order: 2;
            overflow: hidden;
            // background-color: red;
            height: 0;
        }

        .dsFormLabelActive {
            border: solid 1.5px #9c00ff !important;
            background-color: #FEFDFF !important;
            border-radius: 10px !important;
        }

        .dsCheckRadioInnerList {
            // max-height: 50vh;
            overflow: hidden;
            overflow-y: scroll;

            @include md {
                overflow: unset
            }

            .dsListItem {
                @include flexCenter(row);
                gap: .15em;
                font-family: $dsFormFont !important;
                width: 100%;

                .MuiFormControlLabel-root:hover {
                    background-color: unset;
                }

                label {
                    padding: .5em 1em;
                    padding-left: .25em;
                    background: white;

                    &:hover {
                        background: #E5E5FC !important;
                        border: solid 1px #9c00ff !important;

                    }

                    >* {
                        font-family: $dsFormFont !important;
                    }


                }
            }

            label {
                width: 100% !important;

                .dsListItemContainer {
                    width: 100%;
                }
            }
        }

        .dsFormPh {
            width: 80%;
        }

        .dsFormNumber,
        .dsSelect,
        .dsCheckRadio,
        .dsFormSlideRange,
        .dsDatePicker,
        .dsTextField {
            width: 100%;
            display: flex;
            flex-direction: column;
        }

        .dsCheckRadioInner,
        .dsDatePickerInner {
            display: flex;
            flex-direction: column;
        }

        .dsFormSlideRange {
            width: 80%
        }

        .dsFormNumber {
            @include md {
                width: 40%;
            }
        }

        .dsCheckRadio {
            .MuiFormGroup-root {
                gap: 1em;
                flex-direction: row;
            }

            .dsFromCheckboxLabelContainer,
            .dsFromRadioLabelContainer {
                @include flexCenter(column);
                justify-content: flex-start;
                width: fit-content;
            }

            .MuiFormControlLabel-root {
                margin: 0px;
                border-radius: 4px;
                padding: $base-padding;
                // box-shadow: $base-box-shadow;
                background-color: rgba(255, 255, 255, 0.25);
                border: solid 1px rgba(0, 0, 0, 0.23);

                &:hover {
                    background-color: rgb(255, 255, 255);
                    box-shadow: $base-box-shadow;
                }

                .dsLabelImage {
                    width: 7.5em;
                }
            }
        }


        .nameFieldsWrapper {
            @include flexCenter(column);

            @include md {
                @include flexCenter(row);
                gap: 2em;
            }
        }
    }

    .dsCompletedFormWrap {
        // width: 75vw;
        // height: 50vh;
        padding: 10vh 10vw;
        margin: 1em;
        @include flexCenter(column);
        background-color: #fff;
        border-radius: 1em;
        box-shadow: $base-box-shadow;
        text-align: center;

        .dsFormCompleteIcon {
            color: rgb(108, 179, 0);
            font-size: 5em;
        }
    }


}