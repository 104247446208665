.smallSlideWrapper {
    width: 100%;
    min-height: 5em;
    overflow: visible !important;
    padding-bottom: 2em !important;
    padding-top: 1em !important;

    .swiper-pagination {
        bottom: -.5em;
    }

    .swiper-slide {
        overflow: hidden;
        border-radius: $base-border-radius;
        box-shadow: $base-box-shadow;

        .smallSlider {
            border-radius: $base-border-radius;
            width: 100%;
            height: 100%;
            background-color: white;
            border: solid 1px $lightcontrastborder;
            @include flexCenter(row);
            gap: 1em;

            .storeBuyContainer {
                width: 50%;

                h4 {
                    color: $buttonSecondary;
                }
            }

            .storeLogoContainer {
                width: 40%;
                height: 85%;
                margin: 2.5%;
                @include flexCenter(column);
                background-color: $lightcontrast;
                border-radius: $base-border-radius;
                border: solid 1px $lightcontrastborder;

                img {
                    object-fit: contain;
                    padding: 5%;
                    width: 70%;
                    height: 60%;
                    @include flexCenter(row);
                    transition: all .3s;
                    cursor: pointer;
                }
            }
        }
    }
}