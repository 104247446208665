#sec1,
#sec2,
#sec7,
#sec3 {
    width: 100vw;
    height: 70vh;
    opacity: .15;
}

.basicHeader {
    width: 100vw;
    height: 5em;
    // padding: 0 5vw;
    position: fixed;
    left: 0;
    @include flexCenter(row);
    z-index: 100;
    top: 0;
    max-width: 100vw;

    >* {
        -webkit-tap-highlight-color: transparent;
    }

    .headerInner {
        width: 97%;
        padding: 2%;
        padding-left: 1%;
        position: relative;
        @include flexCenter(row);
        height: 5em;

        @include md {
            width: 95%;
            top: 1em;
        }

        @include xl {
            width: 100%;
            top: 1em;
        }
    }

    .logoWrapper {
        height: 100%;
        @include flexCenter(column);
        width: fit-content;
        transform-origin: top top;

        .logo {
            object-fit: contain;
            cursor: pointer;
            transform-origin: top top;
            height: 5em;
            padding: 5%;
            margin-top: 2em;

            @include md {
                margin-top: unset;
                height: 3em;
                width: 120%;
                height: 120%;
            }
        }
    }

    .menuItemsWrapper {
        @include flexCenter(row);

        .logoWrapper {
            left: 0;
        }

        .menuItem {
            color: $txtColorSecondary;
            cursor: pointer;
            background-color: transparent;
            padding: $base-padding $base-padding*1.75;
            border-radius: $base-border-radius *.5;
            background-color: $menuButton;
            font-size: 1em;
            text-transform: uppercase;
            letter-spacing: .75px;

            &:hover {
                background-color: $menuButtonHover;
                color: $txtColor;
                box-shadow: $base-box-shadow;
                transition: all 350ms ease-out;
            }
        }

        .active {
            background-color: $menuButtonActive;
            box-shadow: $base-box-shadow;
            color: $txtColor;
        }
    }
}


.closeMenuIcon,
.hamburgerMenuIcon {
    cursor: pointer;
}

.hamburgerMenuIcon {
    font-size: 3em !important;
    color: white;
    text-shadow: rgba(0, 0, 0, 0.379) 1px 0 10px;
    position: fixed;
    right: 22px;
    z-index: 99;
}

.closeMenuIcon {
    color: $menuButtonActive;
    top: 20px;
    right: 20px;
    position: absolute;
    font-size: 4em !important;
    position: absolute;
    left: 0;
    text-align: left;
    width: fit-content;
    margin: 0.5em;
}


.openedMenu {
    height: 100vh;
    width: 100vw;
    position: fixed;
    z-index: 10000;
    top: 0;
    margin-right: 100vw;
    pointer-events: none;
    overflow: hidden;

    .menuContent {
        position: relative;
        height: 100%;
        width: 100%;
        text-align: center;
        background-color: $bgcThird;
        @include flexCenter(column);
        pointer-events: auto;
        transform: translateX(100vw);

        .menuItemList {
            @include flexCenter(column);
            gap: 1em;
            margin-top: -10vh;
            cursor: pointer;

            .menuItem {
                color: $menuButtonActive;
                font-size: 1.75em;
                padding: $base-padding*1.25 $base-padding*1.75;
                border-radius: $base-border-radius *.5;
                width: 50vw;

                @include md {
                    &:hover {
                        color: $menuButton;
                        background-color: $menuButtonHover;
                    }
                }

            }

            .active {
                background-color: $menuButtonActive;
                color: $menuButton;
            }
        }
    }
}