// VERSIE 1.0
// PAS DEZE FILE NIET ZOMAAR AAN 

.StandaardInfo {
    width: 100vw;
    height: fit-content;
    // overflow: hidden;
    position: relative;
    @include flexCenter(column);
    background-color: $bgc;

    .parent {
        width: 100vw;
        position: relative;
        @include flexCenter(column);

        .inner {
            @include flexCenter(column);
            @include sm{
                @include flexCenter(row);
            }
            width: fit-content;
            gap: 5vw;
            position: relative;

            .innerBg {
                width: 100%;
                height: 100%;
                position: absolute;
            }

            aside {
                @include md {
                    max-width: 80vw;
                }
            }

            .ph-2,
            .ph-1 {
                position: relative;
                @include flexCenter(column);
                width: 100%;

                @include md {
                    width: 50%;
                }
            }
        }

        .overlay,
        .gradient {
            width: 100vw;
            height: 100%;
            position: absolute;
            top: 0;
        }


        .bgImgWrapper {
            width: 100%;
            height: 100%;
            top: 0;
            @include flexCenter(column);
            position: absolute;


            video {
                min-width: 100vw;
                height: 100%;
                object-fit: cover;
            }

            .bgImgContainer {
                width: 100vw;
                height: 100vh;
                position: relative;
            }

            .bgImg {
                position: absolute;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        // .overlay {
        //     background-color: rgba(0, 255, 255, 0.25);
        // }

        // .gradient {
        //     background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
        // }
    }
}