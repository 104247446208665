$utilities: ("padding": ("prefix": "p",
        "values": ("0": 0,
            "1": $base-padding,
            "2": $base-padding * 2,
            "3": $base-padding * 4,
            "4": $base-padding * 6,
            "5": $base-padding * 8,
        ),
    ),
    "padding-left": ("prefix": "pl",
        "values": ("0": 0,
            "1": $base-padding,
            "2": $base-padding * 2,
            "3": $base-padding * 4,
            "4": $base-padding * 6,
            "5": $base-padding * 8,
        ),
    ),
    "padding-right": ("prefix": "pr",
        "values": ("0": 0,
            "1": $base-padding,
            "2": $base-padding * 2,
            "3": $base-padding * 4,
            "4": $base-padding * 6,
            "5": $base-padding * 8,
        ),
    ),
    "padding-top": ("prefix": "pt",
        "values": ("0": 0,
            "1": $base-padding,
            "2": $base-padding * 2,
            "3": $base-padding * 4,
            "4": $base-padding * 6,
            "5": $base-padding * 8,
        ),
    ),
    "padding-bottom": ("prefix": "pb",
        "values": ("0": 0,
            "1": $base-padding,
            "2": $base-padding * 2,
            "3": $base-padding * 4,
            "4": $base-padding * 6,
            "5": $base-padding * 8,
        ),
    ),

    "margin": ("prefix": "m",
        "values": ("0": 0,
            "1": $base-margin,
            "2": $base-margin * 2,
            "3": $base-margin * 4,
            "4": $base-margin * 6,
            "5": $base-margin * 8,
        ),
    ),
    "margin-left": ("prefix": "ml",
        "values": ("0": 0,
            "1": $base-margin,
            "2": $base-margin * 2,
            "3": $base-margin * 4,
            "4": $base-margin * 6,
            "5": $base-margin * 8,
        ),
    ),
    "margin-right": ("prefix": "mr",
        "values": ("0": 0,
            "1": $base-margin,
            "2": $base-margin * 2,
            "3": $base-margin * 4,
            "4": $base-margin * 6,
            "5": $base-margin * 8,
        ),
    ),
    "margin-top": ("prefix": "mt",
        "values": ("0": 0,
            "1": $base-margin,
            "2": $base-margin * 2,
            "3": $base-margin * 4,
            "4": $base-margin * 6,
            "5": $base-margin * 8,
        ),
    ),
    "margin-bottom": ("prefix": "mb",
        "values": ("0": 0,
            "1": $base-margin,
            "2": $base-margin * 2,
            "3": $base-margin * 4,
            "4": $base-margin * 6,
            "5": $base-margin * 8,
        ),
    ),
    "opacity": ("prefix": "o",
        "values": ("0": 0,
            "10": .1,
            "20": .2,
            "30": .3,
            "40": .4,
            "50": .5,
            "60": .6,
            "70": .7,
            "80": .8,
            "90": .9,
            "100": 1,
        ),
    ),
    "border-radius": ("prefix": "br",
        "values": ("default": $base-border-radius,
            "none": 0,
            "lg": $base-border-radius * 2,
            "full": 50%,
        ),
    ),

    "font-size": ("prefix": "font",
        "values": ("sm": $font-size-sm,
            "md": $base-font-size,
            "lg": $font-size-lg,
            "xl": $font-size-xl,
            "xxl": $font-size-xxl,
        ),
    ),
);

// property = padding margin etc.

@each $property,
$map in $utilities {
    $prefix: map-get($map, "prefix");
    $values: map-get($map, "values");

    @each $key,
    $val in $values {
        @if($key=="default") {
            .#{$prefix} {
                #{$property}: $val
            }
        }

        @else {
            .#{$prefix}-#{$key} {
                #{$property}: $val
            }
        }
    }
}