.introLayerdSection {
    width: 100vw;
    height: 100vh;
    @include flexCenter(column);
    justify-content: flex-start;

    position: fixed;
    z-index: 1000;

    .introLayerdWrapper {
        position: absolute;
        position: fixed;
        background-color: rgba(50, 10, 10, 0.8);
        @include flexCenter(column);
        justify-content: flex-start;

        .introOuter {
            width: 100vw;
            height: 100vh;
            background: black;
            position: absolute;
            transform-origin: top;
            top: 0;

            .introInner {
                top: 0;
                transform-origin: top;
                background: #fff;
                height: 100vh;
                width: 100vw;
                position: absolute;
            }
        }
    }
}