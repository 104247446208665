// Adobe Font library (fonts.adobe.com)
//fontFamTitles
@import url("https://use.typekit.net/rop0csw.css");

//fontFamTxt
@import url("https://use.typekit.net/rop0csw.css");

$fontFamTitles: santelia-rough-two, sans-serif;
$fontFamTxt: baileywick-jf-gothic, sans-serif;

//colors
// $dfwButton: #82eab2;
$dfwButton: #db010b;
$dfwButtonTxt: #fcf9ef;
$dfwButtonTxtHover: #fcf9ef;
$dfwButtonSecondary: #fecc14;
$dfwButtonIcon: #000000;
$dfwHeaderLinks: #fcf9ef;
$DFWtxtColor: #fcf9ef;
// $DFWbackgroundColor: #fff2c7;
$dfwTitles: #fcf9ef;
$dfwTaglineColor: #f5f1ff;

// Backgrounds per section (https://cssgradient.io/)
$backgroundColorHeader: #04044e;
$gradientHero: linear-gradient(0deg, #080883 25%, #db010c69 75%);
$gradientBgImageOverlay: linear-gradient(0deg, #2b4ed4 0%, #080883 80%);
$gradientInfo: linear-gradient(180deg, #3b778d 0%, #3b778d 100%);
$gradientTickets: linear-gradient(180deg, #2b4ed4 0%, #080883 100%);
$bgTicketModule: #ffeacf;
$gradientImageViewer: #080883;
$gradientTrailer: linear-gradient(180deg, #080883 0%, #7a1f30 75%);
$gradientSingleImage: linear-gradient(0deg, #c30065 0%, #c30065 80%);

// fontSizes
$dfwh1: 2.5em;
$dfwh1-sm: 2em;
$dfwh2: 2.5em;
$dfwh2-sm: 2em;
$dfwh3: 1.5em;
$dfwh3-sm: 1.5em;
$dfwOneliner: 0.75em;
$dfwP: 1.1em;
$dfwP-sm: 15px;

.mf-cursor-test {
  z-index: 10000000;
}
.DFWCOREVIDEO {
  .releaseTitle {
    text-align: left !important;
  }
}

.DFWCOREBASIC {
  #DfwTicketModule {
    @include flexCenter(column);
    h2 {
      // color: #270625;
      margin: 0px;
      padding: 0px;
    }

    p {
      text-align: center !important;
    }
  }

  #extraInfo {
    background: #000;
    color: #fff;
    
  }

  #DfwTagline {
    font-family: $fontFamTxt;
    font-size: 2em;
    font-weight: 300;
    color: $dfwTaglineColor;
    text-align: left !important;
    filter: drop-shadow(0px 3px 10px rgba(0, 0, 0, 0.4));
  }

  .DfwStandaardInfo {
    padding-top: 5vh !important;
    background: $gradientInfo !important;

    .inner {
      padding-top: 5vh !important;

      @include lg {
        justify-content: space-between !important;

        .ph-1 {
          width: 65% !important;
        }
      }
    }

    .TextComponentCore {
      text-align: left !important;
    }

    #DfwHeaderBasic {
      @include flexCenter(row);
      @include fullWidth();
      // justify-content: flex-end;
      width: 100vw;
      position: relative;
      // background-color: yellow;
      height: fit-content;
      gap: 1.25em;
      font-size: 1.5em;
      padding: 0;

      @include md {
        margin-top: $base-margin * 2;
        justify-content: flex-end;
      }

      .DfwHeaderlinkItem {
        @include flexCenter(column);
        position: relative;
        width: fit-content;
        // background-color: purple;
        cursor: pointer !important;
        color: $dfwHeaderLinks;
        text-shadow: rgba(0, 0, 0, 0.1) 1px 0 10px;
        font-family: $fontFamTitles;
        font-weight: 600;
        letter-spacing: 4px;
        font-size: 1.2em;
        font-variation-settings: "opsz" 48, "wght" 600;
      }

      .vidMenuLinkUnderline {
        width: 0%;
        background-color: $dfwTitles;
        height: 2px;
        position: absolute;
        bottom: -3px;
      }
    }
  }
}

.dfwTempCore {
  > * {
    font-family: $fontFamTxt;
  }

  .introLayerdWrapper {
    z-index: 10000000000 !important;

    .introInner {
      background: $dfwButton !important;
    }

    .introOuter {
      background: $dfwButtonSecondary !important;
    }
  }

  :root {
    --backgroundColor: #e9e4e5;
    --primairColor: #05444f;
    --primairTextColor: #000000;
    --secundairTextColor: #000000;
    --buttonTextColor: #ffffff;
    --fontHeading: "Lato";
    --fontText: "Lato";
    --primairColorOpacity33: #05444f33;
    --primairColorOpacity44: #05444f44;
  }

  .dsRoundBtn,
  .dfwBtn {
    border-radius: 10px;
    background-color: $dfwButton;
    box-shadow: 0 0px 28px rgba(117, 99, 255, 0.05),
      0 0px 25px rgba(211, 204, 255, 0.05);

    .btnArrowContainer {
      .arrowInside {
        color: $dfwButtonIcon;
      }
    }

    .externalIconOuter {
      i {
        color: $dfwButtonTxt;
      }
    }

    .btnArrowContainer {
      background-color: $dfwButtonSecondary;
    }

    &:hover {
      background-color: $dfwButtonSecondary;

      .externalIconOuter {
        i {
          color: $dfwButtonTxtHover;
          opacity: 1;
        }
      }
    }
  }

  p {
    font-size: $dfwP-sm;

    @include md {
      font-size: $dfwP;
    }
  }

  h3,
  h2 {
    color: $dfwTitles;
  }

  h1,
  h2,
  h3 {
    font-family: $fontFamTitles;
    font-weight: 600;
    font-style: normal;
  }

  h1 {
    font-size: $dfwh1-sm;

    @include md {
      font-size: $dfwh1;
    }
  }

  h3 {
    font-size: $dfwh3-sm;

    @include md {
      font-size: $dfwh3;
    }
  }

  h2 {
    text-align: center;
    font-size: $dfwh2-sm;

    @include md {
      font-size: $dfwh2;
    }

    padding: $base-padding * 2 0;
    font-weight: 600 !important;
  }

  .dfwHeader {
    @include flexCenter(row);
    position: fixed;
    background-color: $backgroundColorHeader;
    z-index: 1000;
    @include flexCenter(row);

    height: 5.5em;
    border-radius: 1000em;
    justify-content: center;
    margin-top: 0.5vh;
    transform: scale(0.85);
    color: white;
    pointer-events: none;
    overflow: hidden;
    width: 95vw;
    margin: 5vw 2.5vw;

    @include lg {
      width: 90vw;
      margin: 5vw 5vw;
    }

    @include xl {
      width: 80vw;
      margin: 5vw 10vw;
    }

    > * {
      pointer-events: auto;
    }

    @include md {
      transform: scale(1);
      margin-top: 1.5vh;
    }

    .headerInfo {
      @include flexCenter(row);
      width: 100%;
      gap: 2em;
      margin-left: -10vw;

      h4 {
        display: none;
        font-weight: 500;
        font-size: 1em;
        text-align: center;
        max-width: 40%;

        @include md {
          display: flex;
        }
      }
    }

    .socialMediaWrapper {
      display: none;
      right: 0px;

      @include xs {
        @include flexCenter(row);
        position: absolute;
        justify-content: flex-end;
        min-width: 25%;
      }

      .socialMediaIcon {
        @include lg {
          width: 5em;
        }

        width: 4em;
        height: 10em;
        @include flexCenter(row);
        background-color: pink;
        border-radius: $base-border-radius;
        // margin: 0 .15em;
        box-shadow: $base-box-shadow;
        cursor: pointer;
        transition: 0.3s;

        &:hover {
          filter: brightness(0.7);
        }
      }

      .fbIcon {
        background: linear-gradient(
          180deg,
          rgba(104, 155, 244, 1) 0%,
          rgba(39, 65, 201, 1) 100%
        );
      }

      .instaIcon {
        background: radial-gradient(
          circle at 30% 110%,
          #ffdb8b 0%,
          #ee653d 25%,
          #d42e81 50%,
          #a237b6 75%,
          #3e5fbc 100%
        );
      }

      .ytbIcon {
        background-color: #ff0000;
      }

      .tiktokIcon {
        background: linear-gradient(0deg, #000000, #ff0066);
      }
    }
  }

  #DfwHero {
    overflow: hidden;

    .bgImage {
      position: absolute;
      width: 100vw;
      height: 100vh;
      object-fit: cover;
    }

    .videoPopUpFixedOverlay {
      .exitBtn {
        opacity: 1 !important;

        .dsRoundBtn {
          background-color: white;
          color: black;
        }
      }
    }

    .videoPopUpContainer {
      background: $gradientHero !important;
    }

    #StandaardVideo {
      z-index: 1;
    }

    #StandaardOverlay,
    #StandaardVideo {
      width: unset;
      padding: unset;
      width: 80vw;
      padding: 0vw 10vw;
      padding-top: 5vh;

      @include md {
        height: 100vh;
        padding: 0vw 10vw;
      }

      .videoPopUpBgExit {
        background-color: rgba(0, 0, 0, 0.98);
      }

      .parentTmpButton,
      .vidMenuLink {
        display: none;

        @include md {
          display: flex;
        }
      }

      .vidPlayMenuWrapper {
        @include flexCenter(row);
        position: absolute;
        right: 0px;
        gap: 2em;
        justify-content: space-between;

        .vidMenuLink {
          font-size: 1.5em;
          color: $dfwHeaderLinks;

          &:hover {
            color: $dfwButton;
          }
        }

        .vidMenuLinkUnderline {
          background-color: $dfwButton;
        }

        @include md {
          // min-width: 30em;
        }
      }

      .vidMenuLink {
        display: none;

        @include md {
          display: flex;
        }

        color: white;
        font-size: 16px;
        cursor: pointer !important;
      }

      .vidMenuLinkUnderline {
        width: 0%;
        background-color: white;
        height: 2px;
        position: absolute;
        bottom: -3px;
      }

      .videoPopUpWrapper {
        .parentTmpButton {
          opacity: 0.5;

          .dsRoundBtn {
            background-color: rgba(255, 255, 255, 0.2);

            div {
              color: rgba(255, 255, 255, 0.75);
            }
          }
        }
      }

      #innerStandaardOverlay,
      .videoPopUpWrapper {
        width: 90vw;
        height: 60vh;

        @include md {
          width: 90vw;
          height: 90vh;
        }

        border-radius: $base-border-radius * 2;

        #videoPopUpElement {
          object-fit: cover;
          width: 110%;
          min-height: 120vh;
        }
      }
    }

    #StandaardOverlay {
      z-index: 1;
      position: relative;
      top: 0;
      pointer-events: none;
      @include flexCenter(column);

      @include lg {
        position: absolute;
      }

      #mobileHeroVideo {
        height: 100%;
        position: absolute;

        @include lg {
          display: none;
        }
      }

      #innerStandaardOverlay {
        position: relative;
        @include flexCenter(column);
        overflow: hidden;
        background: radial-gradient(
          circle,
          rgba(0, 0, 0, 0) 0%,
          rgba(0, 0, 0, 0.4) 86%
        );
      }

      #vidPlayHeader {
        position: absolute;
        top: 0px;
        // background-color: rgba(255, 0, 0, 0.15);

        width: 90%;
        height: fit-content;
        @include flexCenter(row);
        pointer-events: auto;
        padding: 0 2em;
        height: 4em;
        margin-top: 1em;

        @include md {
          justify-content: space-between;
          align-items: flex-start;
          width: 90%;
          padding: 1em;
        }

        #logoFilmRelease {
          width: 70vw;
          max-width: 15em;
          max-height: 4em;

          @include md {
            width: 25vw;
            max-width: 27.5em;
            max-height: 9em;
            margin-top: -1em;
          }

          min-height: 50%;
          object-fit: contain;
        }
      }

      #vidDescription {
        width: 90%;
        pointer-events: auto;
        bottom: 5%;

        @include md {
          width: 85%;
          justify-content: space-between;
          bottom: 0%;
          padding: 2.5% 7.5%;
        }

        position: absolute;
        // background-color: red;
        @include flexCenter(row);
        color: white;

        .heroInfo {
          @include flexCenter(column);
          gap: 0.75em;
          width: 100%;

          @include md {
            align-items: flex-start;
            width: 40%;
            left: 0;
          }

          .parentTmpButton {
            @include md {
              order: 100;
            }
          }

          .releaseTitle {
            text-align: center;
          }

          .filmInfoIconWrapper {
            @include flexCenter(row);
            gap: 1em;
            order: 1;

            .filmInfoContainer {
              @include flexCenter(column);
              text-align: center;

              @include lg {
                @include flexCenter(row);
              }

              gap: 0.25em;

              p {
                font-size: 0.75em;
              }
            }
          }
        }

        article {
          display: none;
          position: relative;
          width: 60%;

          .oneliner {
            font-size: $dfwOneliner;
          }

          q {
            padding: 0;

            p {
              margin-top: 0.35em;
            }

            &:after {
              color: $dfwButton;
              right: -0.5em;
            }

            &:before {
              margin-top: -0.5em;
              color: $dfwButton;
            }
          }

          @include md {
            @include flexCenter(column);
            gap: 0.5em;

            width: 45%;
          }
        }

        span {
          display: none;

          @include md {
            display: flex;
          }

          width: 1px;
          height: 150px;
          background-color: #fff;
          opacity: 0.5;
        }
      }
    }
  }

  #DfwCoreJwPlaceHodler {
    padding-top: 2.5vh !important;
    background: $gradientTrailer !important;
    @include fullWidth();
    @include flexCenter(column);

    @include md {
      padding-top: 5vh !important;
      padding-bottom: 5vh !important;
    }

    .wrapper {
      padding: 1em 0em;
      width: 100%;
      max-width: 65em;

      @include md {
        padding: 5em 0em;
      }

      .container-overflow {
        overflow: hidden;
        border-radius: $base-border-radius;

        .jw-preview {
          background-size: 100% 100% !important;
          background-size: cover !important;
          transform: scale(1.4);
        }

        @include md {
          border-radius: $base-border-radius * 2;
        }

        @include lg {
          border-radius: $base-border-radius * 3;
        }
      }
    }
  }

  #DfwTicketModule {
    background: $gradientTickets !important;
    @include fullWidth();

    @include md {
      padding-top: 7.5vh;
      padding-bottom: 7.5vh;
    }
  }

  #ZetInAgendaModule {
    // background: $gradientTickets !important;

    p {
      color: white;
      text-align: center;
    }

    .dfwBtn {
      min-width: 15em;
      color: $dfwButtonTxt;

      img {
        filter: drop-shadow(0 0 0.4rem rgba(53, 53, 53, 0.545));
      }
    }
  }

  .DfwStandaardInfo {
    background: $gradientInfo !important;

    p {
      text-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
    }

    .ImageComponentCore {
      a {
        img {
          box-shadow: 0 0px 28px rgba(255, 255, 255, 0.25),
            0 10px 50px rgba(255, 255, 255, 0.15);
        }
      }
    }

    .gradientBgImage {
      width: 100vw;
      bottom: 0px;
      height: 200%;

      @include md {
        height: 150%;
      }

      @include lg {
        height: 125%;
      }

      position: absolute;
      background: $gradientBgImageOverlay;
    }

    .bgImage {
      position: absolute;
      height: 100%;
      opacity: 0.85;
      width: 100vw;
      object-fit: cover;
      top: 0px;
    }

    .innerBg {
      pointer-events: none !important;
    }
  }

  .DFWINFOBASIC {
    background: $gradientHero !important;

    h2 {
      display: none;
    }

    .parent {
      .inner {
        flex-direction: column-reverse !important;

        @include md {
          flex-direction: row !important;
        }
      }
    }

    .infoFilmReleaseLogo {
      max-width: 100%;

      @include md {
        max-width: 90%;
      }

      @include lg {
        max-width: 28em;
      }

      object-fit: contain;
      max-height: 25vh;
      // background-color: red;
      margin-bottom: 1em;
    }
  }

  .DFWINFOVIDEO {
    h2 {
      display: unset;
    }
  }

  .DfwStandaardInfo {
    h2,
    h3 {
      padding-bottom: 0rem !important;
    }

    .inner {
      gap: 5vh !important;
      flex-direction: column !important;

      @include md {
        flex-direction: row !important;
      }
    }

    p,
    h3 {
      color: $DFWtxtColor;
    }

    .ph-1 {
      flex-direction: column !important;
      align-items: flex-start !important;

      .TextComponentCore {
        text-align: center;
        align-items: flex-start !important;

        @include sm {
          text-align: left !important;
          align-items: flex-start !important;
        }
      }

      @include md {
        width: 50% !important;
      }
    }

    .ph-2 {
      width: fit-content !important;

      @include md {
        width: 50%;
      }
    }

    .ImageComponentCore {
      height: 100%;
      width: 100%;

      .bgLayer {
        opacity: 0;
      }

      img {
        margin: 0;
        max-height: 30em;
        width: 100%;

        @include md {
          max-height: 75vh;
        }

        object-fit: contain;
      }
    }
  }

  .DfwImageViewer {
    background: $gradientImageViewer !important;
    gap: 0;

    .SwiperComponentCore,
    .swiper-wrapper,
    .SwiperComponentCoreWrapper {
      padding: 0;
    }

    .swiper-button-prev,
    .swiper-button-next {
      background-color: $dfwButton;

      &:after {
        color: $dfwButtonSecondary;
      }

      &:hover {
        background-color: $dfwButtonSecondary;
        color: black;

        &:after {
          color: white;
        }
      }
    }

    .swiper-button-next {
      transform: translateX(-8vw);

      @include md {
        transform: translateX(-2.5vw);
      }

      &:hover {
        transform: translateX(-8vw);

        @include md {
          transform: translateX(-2.5vw);
        }
      }
    }

    .swiper-button-prev {
      transform: translateX(8vw);

      @include md {
        transform: translateX(2.5vw);
      }

      &:hover {
        transform: translateX(8vw);

        @include md {
          transform: translateX(2.5vw);
        }
      }
    }

    .swiperSlide {
      height: fit-content !important;
      gap: 0 !important;

      .img,
      .backgroundImg {
        padding: 0;
        height: 50vh !important;
        min-width: 100%;
        min-height: 100%;
        object-fit: cover;

        @include md {
          height: fit-content !important;
        }

        width: 100% !important;
        position: relative !important;
      }
    }
  }

  #DfwSingleImageSection {
    @include fullWidth();
    background: $gradientSingleImage;
    @include flexCenter(column);
    padding-top: 5vh !important;
    padding-bottom: 10vh !important;
    gap: 5vw;

    @include md {
      @include flexCenter(row);
    }

    h3 {
      text-align: center;

      @include md {
        width: 65%;
      }
    }
  }

  .dfwFooter {
    flex-direction: column;
    gap: 1em;
    padding-top: 2em !important;

    @include md {
      flex-direction: column;
    }

    .linkFooter {
      cursor: pointer !important;
      font-size: 10px;
    }

    .imageRow {
      flex-wrap: wrap;
      gap: 1em;

      img {
        height: 5vh;
        max-width: 6.5em;
        max-height: 1em;

        @include md {
          max-height: 3em;
        }

        margin: 0 1em;
        object-fit: contain;
      }
    }
  }
}

.c-ticketmodule {
  input#Location.ctic-datapicker_location-field.w-input {
    background: transparent !important;
  }

  > * {
    font-family: $fontFamTxt !important;
  }

  .ctic-datapicker {
    width: 96% !important;
    margin-left: 2% !important;
  }

  .ctic-introcontent_header {
    font-size: 1em !important;
    margin-top: 0px;

    @include lg {
      font-size: 1.5em !important;
    }

    font-family: $fontFamTxt !important;
  }

  .ctic-introcontent {
    padding-top: 0px;
    text-align: left !important;
  }

  .ctic-datapicker_location-wrapper {
    padding: 17px 30px !important;
    padding-right: 20px !important;
  }

  .ctic-datapicker_location-wrapper.active {
    width: 45% !important;
  }

  .ctic-datapicker_submit-wrapper {
    padding: 10px !important;
  }

  .ctic-datapicker_date-wrapper {
    padding: 17px 0px 17px 17px !important;
  }

  .ctic-introcontent_text,
  .ctic-showtimes_dates_month,
  .ctic-cinemadetails_address {
    font-family: $fontFamTxt !important;
  }

  .ctic-entry_showtime_time,
  .ctic-cinemadetails_name,
  .ctic-showtimes_dates_date {
    font-family: $fontFamTitles !important;
  }

  .ctic-cta,
  .ctic-introcontent_text {
    color: black !important;
  }

  :root {
    --backgroundColor: #e9e4e5;
    --primairColor: #000;
    --primairTextColor: #000000;
    --secundairTextColor: #000000;
    --buttonTextColor: #ffffff;
    --fontHeading: "Lato";
    --fontText: "Lato";
    --primairColorOpacity33: #05444f33;
    --primairColorOpacity44: #05444f44;
  }

  .mobile-search-btn {
    border-style: none;
  }

  .ctic-datapicker,
  .ctic-datapicker_location-wrapper {
    border-width: 2px;
  }

  .ctic-wrapper {
    background-color: $bgTicketModule !important;
    border: 2px solid #ffffff57;
  }

  .ctic-button {
    background-color: $dfwButton !important;
    color: $dfwButtonTxt !important;

    &:hover {
      background-color: $dfwButtonSecondary !important;
      color: $dfwButtonTxtHover !important;
    }
  }

  input {
    outline: none;
    background: transparent;
    background-color: transparent;

    &:hover {
      outline: none;
    }
  }

  .ctic-datapicker_location-field,
  .ctic-datapicker_date-field {
    margin-left: 10px;
    font-family: $fontFamTxt;
    font-weight: 500;
    font-size: 1em;
  }

  .ctic-icon {
    transform: scale(0.8);
  }

  .ctic-button {
    font-family: unset;
    background-color: $dfwButton;
    border: none;

    &:hover {
      background-color: $dfwButtonSecondary;
    }
  }

  .ctic-introcontent_cinemacallout {
    font-family: unset;
  }
}

#ui-datepicker-div {
  margin-top: 24px !important;
  margin-left: -35px !important;
  padding: 1.25em !important;
  border-color: lightgrey !important;
  box-shadow: $base-box-shadow !important;
  border-radius: 1em;

  > * {
    font-family: $fontFamTxt !important;
  }
}

.ui-widget-header {
  border: unset;
  background: unset;
  color: unset;
  font-weight: unset;
}

.ui-datepicker td {
  padding: 0.25em !important;
}

.ui-datepicker td span,
.ui-datepicker td a {
  text-align: center !important;
}

.ui-state-default {
  color: black;
  border: none !important;
  background-color: unset !important;
  border-radius: 0.25em !important;
  padding: 0.25em !important;
  font-weight: 400 !important;
}

.ui-state-highlight {
  background: $dfwButton !important;
  opacity: 0.6 !important;
  color: white !important;
}

.ctic-date-overlay,
.ctic-location-overlay {
  visibility: none !important;
  display: none !important;
}

.ui-state-active {
  background: $dfwButtonSecondary !important;
}

.ui-widget {
  font-family: $fontFamTxt !important;
}

.ui-widget-header {
  border: none;
  background-color: unset;
}

.ui-datepicker {
  width: 25em;
}

#placeHolderTicketModule {
  width: 80vw;
  width: 100%;
  position: relative;
}

#placeHolderTicketModule .ctic-poster {
  max-width: 100%;
  vertical-align: middle;
  display: inline-block;
}

#buyTickets .placeHolderAgenda,
#buyTickets .title {
  order: 100;
}

#buyTicketsInner {
  order: 1;
}

.ctic-entry_cinema_icon {
  img {
    width: 55px;
  }
}

.ctic-showtimes {
  margin-top: 1em;
}

.ctic-shotimes_date {
  background-color: $dfwButton !important;

  .ctic-showtimes_dates_month,
  .ctic-showtimes_dates_date {
    color: white !important;
  }
}

.ctic-shotimes_date .w-inline-block .active {
  background-color: $dfwButtonSecondary !important;
}

.ctic-entry_showtime_time {
  color: black !important;
}

.ctic-title {
  line-height: 1em;
  text-align: left !important;
  font-size: 2.5em !important;
  margin-top: 15px;
  font-family: $fontFamTitles !important;
  font-family: 800 !important;
}

.ctic-cinemadetails_name,
.ctic-cinemadetails_address {
  color: $dfwTitles !important;
}

.ctic-cinemacallout_cinemas {
  justify-content: flex-start;
}

.ctic-cinemaicon_wrapper {
  width: 50px !important;
  height: 50px !important;

  @include lg {
    width: 72px !important;
    height: 72px !important;
  }
}

#data-response-api {
  > * {
    font-family: $fontFamTxt !important;
    color: $dfwTitles !important;
  }
}
