.videoPopUpContainer {
    background-color: $bgc;
    @include flexCenter(column);
    @include fullWidth();
    height: fit-content;
    perspective: 1000px;

    @include lg {
        height: 100vh;
    }

    // .bgDivider {
    //     background-color: $bgcSecondary;
    //     width: 100vw;
    //     position: absolute;
    //     bottom: 0;
    //     @include flexCenter(column);
    //     height: 15vh;

    //     @include md {
    //         height: 50vh;
    //     }

    //     .bgDividerBlur {
    //         background-color: $bgcSecondary;
    //         position: absolute;
    //         width: 200vw;
    //         height: 10em;
    //         top: -5em;
    //         filter: blur(20px);

    //         @include md {
    //             height: 45em;
    //             top: -15.5em;
    //             filter: blur(80px);
    //         }

    //     }
    // }

    .title {
        color: $txtColor;
    }

    .jwplayer {
        border-radius: $base-border-radius;
        overflow: hidden;
    }

    .videoPopUpWrapper {
        width: 100%;
        position: relative;
        height: fit-content;
        overflow: hidden;
        border-radius: $base-border-radius *2;
        box-shadow: $base-box-shadow;
        background-color: black;
        max-width: 100vw;
        @include flexCenter(column);
        cursor: pointer !important;

        .videoPopUpElement {
            transform: scale(1.025);
        }

        .parentTmpButton {
            position: absolute;
            transform: scale(2);
            z-index: 1;
            pointer-events: none;

            >* {
                pointer-events: none;
            }
        }

        .videoPopUpElement,
        .videoPopUpMfLayer {
            width: 100%;
            height: 100%;
            overflow: hidden;
            border-radius: $base-border-radius;
        }

        .videoPopUpMfLayer {
            z-index: 10;
            position: absolute;
            top: 0;
        }
    }

    .videoPopUpBgExit {
        width: 100vw;
        background-color: rgba(0, 0, 0, 0.7);
        height: 500vh;
        position: fixed;
        top: -250vh;
        z-index: 98;
        display: none;
    }

    .videoPopUpFixedOverlay {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
        position: fixed;
        width: 97vw;
        height: 35vh;
        padding: 1.5vw;
        z-index: 99;
        margin-top: -5.5vh;
        @include flexCenter(column);
        border-radius: $base-border-radius;

        @include md {
            margin-top: -2.5vh;

            height: 45vh;
        }

        @include lg {
            margin-top: 0vh;
            height: 90vh;
            width: 80vw;
            padding: 4vh;
        }

        top: 110vh;
        display: none;
        background-color: black;

        .exitBtn {
            cursor: pointer;
            top: 1.5vh;
            right: 2.5vh;

            @include lg {
                top: 3.5vh;
                right: 3.5vh;
            }

            position: absolute;
            z-index: 1001 !important;

            .parentTmpButton {
                .dsRoundBtn {
                    &:hover {
                        transform: scale(1.1);
                    }

                    &:active,
                    &:focus {
                        background-color: black;
                    }
                }
            }

        }

        .parentTmpButton .dsRoundBtn .dashedLine {
            visibility: hidden;
        }



        .overlayVideoWrapper {
            @include flexCenter(column);
            width: 100%;
            height: 90%;

            @include md {
                width: 90%;
            }

            .jw-wrapper {
                border-radius: $base-border-radius !important;
                overflow: hidden !important;
            }
        }
    }
}