// theme colors
$orange: #ff691d;
$black: #000;
$blue: #42809B;
// $buttonSecondary: #22DDAA;
// $lGreen: #d2fded;
// $xlGreen: #f5fffd;
// $darkBlue: #0e1a33;
// $lBlue: #E1FCF9;
// $xlBlue: #F5F6F8;
// $gradientLight: radial-gradient(at 80% 0%, hsla(172, 82%, 30%, 1) 0px, transparent 50%),
// radial-gradient(at 0% 50%, hsla(174, 100%, 62%, 1) 0px, transparent 50%),
// radial-gradient(at 0% 100%, hsla(164, 53%, 24%, 1) 0px, transparent 50%),
// radial-gradient(at 80% 100%, hsla(163, 44%, 41%, 1) 0px, transparent 50%),
// radial-gradient(at 0% 0%, hsla(165, 96%, 36%, 1) 0px, transparent 50%);
// $gradientDark: linear-gradient(180deg, #0f1a4b 7%, #0c3a46 100%);

// $buttonSecondary: #e95a47;
$buttonSecondary:yellow; 






$lGreen: #ffd9d9;
$xlGreen: #f1f1f1;
$darkBlue: #262626;
$lBlue: #f3e9e9;
$xlBlue: #f7f7f7;
$gradientLight: radial-gradient(at 40% 20%, hsla(7, 78%, 59%, 1) 0px, transparent 50%),
radial-gradient(at 80% 100%, hsla(0, 100%, 88%, 1) 0px, transparent 50%),
radial-gradient(at 0% 0%, hsla(343, 100%, 76%, 1) 0px, transparent 50%);
$gradientDark:linear-gradient(180deg, #000000 7%, #1a1a1a 100%);
// $gradientDark: linear-gradient(180deg, #262626 7%, #656362 100%);
// color pallete
$colors: ("oranje": $orange,
    "blauw": $blue,
    "xlGreen":$xlGreen,
);



@each $key,
$val in $colors {
    .txt-#{$key} {
        color: $val
    }

    .txt-hover-#{$key} {
        &:hover {
            color: $val;
        }
    }

    .bg-#{$key} {
        background-color: $val
    }
}