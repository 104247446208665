.singleViewSwiper {
    .SwiperComponentCoreWrapper {
        overflow: hidden !important;
        padding: 0 !important;
        border-radius: $base-border-radius *2;
        background-color: $bgcFourth;
        border: $base-border-thickness *3 $bgcFourth solid;
    }
}

.StandaardImageViewer {
    @include flexCenter(column);
    // background-color: rgb(60, 60, 60);
    background-color: $bgc;
    width: 100vw;
    position: relative;
    gap: 2.5em;
    @include fullWidth();
    padding-top: 2.5vh;
    padding-bottom: 2.5vh;

    @include md {
        @include fullWidth();
    }

    .title {
        color: $txtColorSecondary;
        z-index: 1;
    }

    .SwiperComponentCoreWrapper {
        @include xl {
            padding-top: 15vh;
            padding-bottom: 10vh;
        }
    }

    .bgLayer {
        width: 100vw;
        position: absolute;
        height: 30vh;
        bottom: 0;
        // background-color: $bgcSecondary;
    }

    .SwiperComponentCore {
        width: 100%;
        max-width: 100em;
        border-radius: $base-border-radius *2;
        // box-shadow: $base-box-shadow;
    }

    .swiperFullWidth {
        .swiper-wrapper {
            .swiperSlide {
                height: 50vh;

                // overflow: hidden;
                @include md {
                    height: 100vh;

                    &:hover {
                        .backgroundImg {
                            transform: scale(1) rotate(0deg);
                        }
                    }
                }
            }
        }
    }

}


.singleViewSwiper {
    .swiper-wrapper {
        padding: 0 !important;
    }

    .swiperSlide {
        height: 70vh !important;
        max-height: 20em;

        @include md {
            max-height: unset;
        }
    }

    .swiper-button-prev {
        margin-left: 7em !important;
    }

    .swiper-button-next {
        margin-right: 7em !important;
    }
}