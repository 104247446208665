// VERSIE 1.0
// PAS DEZE FILE NIET ZOMAAR AAN 
#standaardHero0 {
    background-size: 100% 100%;
    background-position: 0px 0px, 0px 0px;
    background-image: radial-gradient(50% 50% at 87% 107%, #FB0207 -50%, #FB020730 3%, #073AFF00 100%), radial-gradient(100% 91% at 54% 72%, #d2e8ff 0%, #E5F2FF 100%);
    height: 95vh;

    .scrollDownButton {
        display: none;

        @include md {
            display: flex;
        }
    }

    .parent {
        min-height: 100vh;
        justify-content: flex-start;
        background-color: transparent;

        .TextComponentCore {
            color: $txtColorSecondary;
        }

        .inner {
            margin-top: -5vh;
            flex-direction: column;

            @include md {
                margin-top: -5vh;
                flex-direction: row-reverse;
            }

            .swiperSlide {
                margin-top: 2.5vh;
                height: 45vh;
                max-height: 22.5em;

                @include md {
                    margin-top: 0vh;
                    height: 50vh;
                }
            }

            .ph-1,
            .ph-2 {
                max-width: 100vw;
            }

            .ph-2 {
                order: 1;
                width: 90%;

                @include md {
                    height: 100%;
                    width: 40%;
                }
            }

            .ph-1 {
                // align-items: flex-start;
                color: $txtColor;
                order: 2;
                // width: 100%;

                .TextComponentCore {
                    align-items: center;
                    text-align: center;
                    width: 100%;
                }

                h1 {
                    order: 1
                }

                p {
                    order: 3
                }

                .btnWrapper {
                    order: 2;
                }
            }

        }

        .bgImgContainer {
            width: 100%;
            height: 50%;
            @include flexCenter(column);

            .bgImg {
                width: 165vw;
                transform: rotate(-10deg);
                margin-top: -35vw;
                margin-right: -35vw;
            }
        }

        // .overlay {
        //     background-color: rgba(0, 255, 255, 0.25);
        // }
        .gradient {
            background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
            height: 100%;
            opacity: 1;
            position: absolute;
        }
    }
}