.countDownCounterDUMS {
    @include flexCenter(row);
    gap: .5em;

    .countDownItemWrapper {
        @include flexCenter(column);
        gap: .15em;
        .countDownItem {
            @include flexCenter(row);
            width: 1.65em;
            height: 1.65em;
            background-color: #e8e8e8;
            border: solid 1px lightgrey;
            border-radius: $base-border-radius *.25;
            font-size: 2em;

            @include md {
                font-size: 2.5em;
            }
        }

        p {
            font-size: .85em;
        }
    }
}