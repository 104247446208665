// VERSIE 1.0
// PAS DEZE FILE NIET ZOMAAR AAN 

.parentTmpButton {
    width: fit-content;
}


.tmpBtn {
    font-size: 1.1em;
    padding: .65rem 1.25rem;
    gap: .5em;

    .material-icons-outlined {
        height: 1em;
    }

    @include md {
        padding: .75rem 1.5rem;
        min-width: 160px;
        gap: 1em;
        font-size: 1em;

        .material-icons-outlined {
            height: 1em;
            transform: scale(1.2);
        }
    }

    border: 1px solid transparent;
    border-radius: $base-border-radius *.5;
    box-sizing: border-box;
    cursor: pointer;
    text-decoration: none;
    color: #fff;
    font-weight: 600;
    text-align: center;
    transition: all .3s ease-in-out;
    display: inline-block;
    vertical-align: top;
    white-space: nowrap;
    @include flexCenter(row);
    box-shadow: $base-box-shadow;
}

.tmpBtn {
    background-color: $button;

    &:hover {
        background-color: $buttonSecondary;

        i {
            opacity: .5;
        }
    }

    .arrowInside1,
    .arrowInside2,
    i {
        color: $buttonText;
    }

    i {
        font-weight: 600;
    }

    .btnArrowContainer {
        background: $buttonSecondary;
        width: fit-content;
        color: white;
        width: 2em;
        height: 2em;
        @include flexCenter(column);
        border-radius: $base-border-radius *.25;
        overflow: hidden;
        position: relative;
        pointer-events: none;

        .arrowInside {
            position: absolute;
            font-size: 1.1em;
            font-weight: 500;
        }
    }

}


.InfoIconRight {
    background-color: $button;
    width: 2.5em;
    height: 2.5em;
    padding-top: .4em;
    padding-left: .4em;
    position: absolute;
    @include flexCenter(column);
    color: white;
    border-radius: 3em 0 0 0;
    bottom: 0;
    right: 0;
    transform-origin: bottom right;
}

.tmpCloseBtn {
    @include flexCenter(column);
    margin: 0;
    padding: .75em;
    background-color: $button;
    color: $lightcolor;
    box-shadow: $base-box-shadow;
    border-radius: 10000em;
    z-index: 1;
    cursor: pointer;

    div {
        font-size: 1.75em;
    }
}