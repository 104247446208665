.dsVideoFormSection {
    // background-color: red;
    overflow: hidden;
    width: 100vw;
    @include flexCenter(column);
    background-color: #120b01;
    min-height: 90vh;
    @include fullWidth();

    .vidIntroWrapper {
        @include fullWidth();
        @include flexCenter(column);
        gap: 1em;
        padding-bottom: 1vh;

        .timeFrame {
            color: white;
            @include flexCenter(row);
            gap: .5em;
        }

        .vidIntroTitle {
            color: white;
            text-align: center;
        }

        #vidFormStartBtn {
            background-color: #FF303D;
            padding: .5em 1.15em;
            border-radius: 400px;
            color: white;
        }
    }

    .vidVideoFormWrapper {
        height: 60vh;
        width: 100vw;
        @include flexCenter(column);
        position: relative;
        overflow: hidden;


        .vidQuizExplainer {
            width: 80%;
            position: absolute;
            color: white;
            text-align: center;
        }

        .playContainer {
            position: absolute;
            z-index: 100;
            width: 100%;
            height: 100%;
            @include flexCenter(column);
            z-index: 1;

            .playButton {
                width: 5.5em;
                height: 5.5em;
                position: absolute;
                background-color: #EDF95A;
                @include flexCenter(column);
                border-radius: 500em;
                box-shadow: $base-box-shadow;

                svg {
                    width: 100%;
                    height: 40%;
                    position: absolute;
                    fill: #2E1B01;
                    @include flexCenter(column);
                    margin-left: .5em;

                    path {
                        @include flexCenter(column);
                        position: absolute;
                    }
                }
            }

            #svgCircles {
                width: 95%;
            }
        }

        .vidFormCount {
            color: white;
            font-size: 10em;
            position: absolute;
            font-weight: 800;
        }

        video {
            height: 100%;
            position: absolute;
            object-fit: cover;
            filter: blur(0px);
        }

        .gradient {
            width: 100%;
            position: absolute;
        }

        .gradient-bottom {
            bottom: -2px;
            height: 100%;
            transform-origin: bottom;
            background: linear-gradient(0deg, rgba(18, 11, 1, 1) 0%, rgba(18, 11, 1, 0) 100%);
        }

        .gradient-top {
            top: -2px;
            transform-origin: top;
            height: 100%;
            background: linear-gradient(180deg, rgba(18, 11, 1, 1) 0%, rgba(18, 11, 1, 0) 100%);
        }
    }

    .vidFormTimeRangeIntro,
    .vidFormTimeRange {
        width: 75%;
        height: .4em;
        border-radius: 400px;
        position: absolute;
        bottom: 3.5vh;
        background-color: #ffffff40;
        z-index: 1;

        .vidFormTimeRangeInnerIntro,
        .vidFormTimeRangeInner {
            width: 0;
            height: 100%;
            background-color: #EDF95A;
            position: absolute;
            border-radius: 400px;
            box-shadow: $base-box-shadow;
        }
    }


    .vidQuestionFormWrapper {
        @include flexCenter(column);
        @include fullWidth();
        height: 100%;
        padding-top: 1vh;
        padding-bottom: 2vh;
        overflow: hidden;

        .videoNextBtn {
            background-color: #fff;
            line-height: 0;
            @include flexCenter(column);
            width: 1em;
            height: 1em;
            padding: 1.25em;
            border-radius: 5px;
            .material-icons-outlined {
                font-size: 1.5em !important;
            }
        }


        .progress {
            color: white;
            width: 100%;
            text-align: center;
            padding: 2vh 0;
        }

        .vidFormQuestionTitle {
            color: white;
            width: 100%;
            text-align: center
        }

        .buttonWrapper {
            @include flexCenter(row);
            // justify-content: flex-start;
            gap: .75em;
            flex-wrap: wrap;
            width: 100%;
            padding: 5% 0;

            button {
                font-size: 1em;
                width: 45%;
            }

            // .btn0 {
            //     background-color: limegreen;
            // }

            // .btn1 {
            //     background-color: orange;
            // }

            // .btn2 {
            //     background-color: darkblue;
            // }

            // .btn3 {
            //     background-color: purple;
            // }
        }
    }


}