#basicHeader1 {
    .headerInner {
        justify-content: space-between;
    }

    .closeMenuIcon{
        margin: $base-margin;
        margin-left: $base-margin *1.75;
    }
    #sideMenu1 {
        position: absolute;
        background-color: $bgcThird;
        @include flexCenter(column);
        // width: 30vw;
        min-width: 30em;
        height: 95vh;
        top: 1em;
        margin-top: 2.5vh;
        right: 1em;
        margin-right: 2.5vh;
        box-shadow: 0 19px 38px rgba(0, 0, 0, 0.10), 0 5px 12px rgba(0, 0, 0, 0.22);
        border-radius: $base-border-radius *2.5;


        .menuItemList {
            @include flexCenter(column);
            gap: 1em;
            margin-top: -10vh;
            cursor: pointer;
            
            .menuItem {
                color: $menuButtonActive;
                background-color: $menuButton;
                font-size: 1.5em;
                padding: $base-padding*1.25 $base-padding*1.75;
                border-radius: $base-border-radius *.5;
                width: fit-content;
                min-width: 100%;
                text-align: center;

                @include md {
                    &:hover {
                        color: $menuButton;
                        background-color: $menuButtonHover;
                    }
                }

            }

            .active {
                background-color: $menuButtonActive;
                color: $menuButton;
            }
        }
    }

    .menuItemsWrapper {
        padding: 0 1em;
        height: 100%;
        background-color: $bgcThird;
        border-radius: 5000px;
        right: 0;
        justify-content: flex-end;
        gap: 1.5em;
        overflow: hidden;
        box-shadow: 0 19px 38px rgba(0, 0, 0, 0.10), 0 5px 12px rgba(0, 0, 0, 0.22);
        max-width: 3em;
        min-width: 3em;

        .tmpBtn{
            margin-left: 1em;
        }
        .hamburgerMenuIcon {
            position: relative;
            padding: .25em;
            margin: 0;
            margin-right: -.25em;
            right: unset;
            border-radius: 5000px;
            background: $menuButton;
            color: black;

            &:hover {
                background: $menuButtonHover;
                color: white;
            }
        }
    }
}