#StandaardVideoAll {
    background-color: $bgc;

    @include sm {
        padding-top: 5vh;
        padding-bottom: 5vh;
    }

    .videoPopUpContainer {
        padding-top: 0vh;

        @include md {
            padding-top: 4vh;
        }
    }

    .TextPhVideoPopUpComponent {
        @include fullWidth();
    }

    /* width */
    ::-webkit-scrollbar {
        width: 10px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        border: 1px solid rgba(0, 0, 0, 0);
        // box-shadow: inset 0 0 5px rgb(255, 255, 255);
        background-color: rgba(255, 255, 255, 0.2);
        border-radius: 10px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        border: 1px solid rgba(0, 0, 0, 0);
        background-clip: padding-box;
        border-radius: 9999px;
        background-color: white;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        border: 2px solid rgba(0, 0, 0, 0);
        background: rgba(255, 255, 255, 0.768);
    }

    .TextComponentCore {
        color: $txtColor;

        @include md {

            width: 80%;
            padding-right: 20%;

            h2 {
                width: 80%;
            }
        }

    }
}

.StandaardVideo {
    background-color: $bgc;
    @include fullWidth();

    @include md {
        @include fullWidth();
    }

    .singleVideoWrapper {
        @include flexCenter(column);
        perspective: 1000px;
        margin: 5vh 0vh;

        .singleVideoPlaceholder {
            width: 100%;
            border-radius: $base-border-radius *2;
            overflow: hidden;



        }
    }

    .textContainer {
        color: $txtColorSecondary;

        @include md {
            width: 100%;
            // margin: 0 7.5vw;
            // padding: 0 2.5%;
        }

    }

    .VideoComponent {
        width: 100%;
        min-height: 10em;
        height: fit-content;
        // overflow: hidden;
        position: relative;
        @include flexCenter(column);
        margin: 5vh 0vh;
    }


    .wrapper {
        @include flexCenter(column);
        position: relative;

        @include fullWidth();
        padding-bottom: 0;

        @include md {
            width: unset;
            // padding: 2.5% 0;
        }
    }

    .titleContainer {
        z-index: 1;
        width: 100%;
        margin-left: 10%;
        // background-color: red;
        left: 0;
        @include flexCenter(row);
        justify-content: flex-start;
        gap: 5%;
        // margin-bottom: 1em;

        @include md {
            gap: 1em;
            margin-left: unset;
        }



        .aantal {
            font-size: 1em;

        }
    }

    .backgroundImageContainer {
        width: 80vw;
        height: 50vw;
        top: 5vh;
        right: -20vw;
        opacity: .45;

        @include md {
            width: 80vw;
            height: 50vw;
            right: 0%;
            opacity: 1;

            top: 2vw;
        }


        @include lg {
            width: 45vw;
            height: 40vw;
            right: 15%;

        }

        @include flexCenter(column);
        position: absolute;
        z-index: 0;
        overflow: hidden;

        img {
            position: absolute;
            width: 120%;
            height: 120%;
            object-fit: cover;
            filter: blur(5px);
        }

        .gradient {
            position: absolute;
            width: 100%;
            top: 0%;
            height: 100%;
        }
    }

    .videoPlayer {
        @include flexCenter(column);
        overflow: hidden;
        border-radius: $base-border-radius;
        z-index: 1;
        position: relative;
        padding: 2vh 0%;
        width: 105%;
        background: $bgcTransparent;

        @include md {
            @include flexCenter(row);
            padding: unset;
            width: 100%;
            // overflow: unset;
        }

        .videoPlayerBackgroundColor {
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;
            display: none;
            z-index: -1;
            background-color: rgba(62, 62, 62, 0.122);

            @include lg {
                display: unset;
            }
        }

        // /* width */
        // ::-webkit-scrollbar {
        //     width: 10px;
        // }

        // /* Track */
        // ::-webkit-scrollbar-track {
        //     border: 4px solid rgba(0, 0, 0, 0);
        //     // box-shadow: inset 0 0 5px rgb(255, 255, 255);
        //     background-color: rgba(255, 255, 255, 0.2);
        //     border-radius: 10px;
        // }

        // /* Handle */
        // ::-webkit-scrollbar-thumb {
        //     border: 4px solid rgba(0, 0, 0, 0);
        //     background-clip: padding-box;
        //     border-radius: 9999px;
        //     background-color: white;
        // }

        // /* Handle on hover */
        // ::-webkit-scrollbar-thumb:hover {
        //     border: 2px solid rgba(0, 0, 0, 0);
        //     background: rgba(255, 255, 255, 0.768);
        // }

        .players-container {
            @include flexCenter(column);
            width: 100vw;
            position: relative;
            height: 100%;

            .relatedVideos {
                @include flexCenter(column);
                width: 100%;
                height: 7%;
                position: relative;
                margin-top: 3.5%;

                .title {
                    position: absolute;
                    font-weight: 500;
                    font-size: 1.25em;
                    color: $txtColor;
                }
            }

            .innerScroll {
                overflow: hidden;
                overflow-y: scroll;
                height: 90%;
                margin: 0 1em;
                padding-right: .5em;

            }

            .swiperWrapper {
                width: 100%;
                position: relative;
                @include flexCenter(column);
                background-color: $bgcThird;
                box-shadow: $base-box-shadow;
                width: 80%;
                border-radius: $base-border-radius;
                padding-bottom: 5%;
                padding-top: 2.5%;
                margin-top: -2.5em;
                gap: 1vh;

                .swiperTitleContainer {
                    width: 80%;
                    margin-bottom: .25em;
                    text-align: center;
                }

                .swiper-wrapper {
                    align-items: center;
                }

                h4 {
                    color: $txtColorSecondary;
                    font-weight: 300;
                    font-size: 0.9em;
                }

                .swiper-button-next,
                .swiper-button-prev {
                    color: $button !important;
                    // text-shadow: #00000085 1px 0 10px;
                    margin-top: -10%;
                    transform: scale(.75);
                }

                .swiper-pagination {
                    bottom: -1em !important;
                }


                .vidSwiper {
                    // overflow: visible !important;
                    height: 7em;
                    width: 100%;

                    .swiper-slide {
                        transition: all none;
                        background-color: #fff;

                        .slideThumb {
                            position: absolute;
                            min-width: 110%;
                            min-height: 110%;
                            object-fit: cover;
                        }

                        .dsRoundBtn {
                            height: 2.4em;
                            width: 2.4em;

                            .dashedLine {
                                width: 90%;
                                height: 90%;
                            }

                            .arrowInside {
                                font-size: .8em;
                            }
                        }

                        .parentTmpButton {
                            pointer-events: none !important;
                            position: absolute;
                            @include flexCenter(row);

                            >* {
                                pointer-events: none !important;
                            }

                            a {
                                pointer-events: none !important;

                                .dashedLine {
                                    pointer-events: none;
                                }

                                div {
                                    pointer-events: none !important;
                                }
                            }
                        }
                    }
                }
            }
        }

        .mainPlayerPlaceholder {
            @include flexCenter(column);
            width: fit-content;
            background-color: black;
            // padding: 5% 0%;
            border-radius: $base-border-radius;
            overflow: hidden;
            position: relative;

            @include md {
                padding: unset;
                overflow: unset;
            }

            .jwplayer {
                @include xs {
                    width: 480px !important;
                }

                @include sm {
                    width: 640px !important;
                }

                @include md {
                    width: 640px !important;
                }

                @include xl {
                    width: 854px !important;
                }

                @include xxl {
                    width: 1024px !important;
                }

                .jw-display-controls {
                    @include flexCenter(row);

                    .jw-display-icon-next,
                    .jw-display-icon-rewind,
                    .jw-icon-rewind {
                        display: none;
                    }

                    .jw-button-color {
                        background-color: $button;
                        padding: $base-padding *1.5;
                        border-radius: 4000px;
                    }
                }
            }
        }


        .players-container,
        .jwplayer {
            @include xs {
                height: 270px !important;
                width: 90%;
            }

            @include sm {
                height: 360px !important;
                width: 90%;
            }

            @include md {
                height: 405px !important;
            }

            @include xl {
                height: 480px !important;
            }

            @include xxl {
                height: 576px !important;
            }
        }

        .thumbWrapper {
            width: 100%;
            position: relative;
            border-radius: $base-border-radius;
            @include flexCenter(column);
            justify-content: normal;
            gap: 1.5em;
            padding: 5% 0;

            .thumbItem {
                min-width: 15em;
                width: 15vw;

                @include lg {
                    min-width: 20em;
                    width: 20vw;
                }
            }

            .thumbContainer {
                width: 100%;
                height: 12.5em;

                @include lg {
                    height: 15em;
                }
            }

            .thumbnail {
                @include flexCenter(column);
                width: 100%;
                height: 9.5em;
                overflow: hidden;
                position: relative;
                border-radius: $base-border-radius;
                box-shadow: $base-box-shadow;
                transition: all .25s;
                cursor: pointer;
                background-color: black;
                transform: scale(0.925) translateY(.25em);

                @include lg {
                    height: 12em;
                }

                &:hover {
                    opacity: .5;
                    transform: scale(.94) translateY(.25em);
                }

                .parentTmpButton {
                    pointer-events: none !important;
                    position: absolute;
                    @include flexCenter(row);

                    a {
                        pointer-events: none !important;

                        div {
                            pointer-events: none !important;
                        }
                    }
                }

                .thumbImg {
                    min-width: 120%;
                    min-height: 120%;
                    object-fit: cover;
                    -webkit-user-select: none;
                    -khtml-user-select: none;
                    -moz-user-select: none;
                    -o-user-select: none;
                    user-select: none;
                    -webkit-user-drag: none;
                    -khtml-user-drag: none;
                    -moz-user-drag: none;
                    -o-user-drag: none;


                }
            }

            .thumbSubDescription {
                @include flexCenter(row);
                justify-content: space-between;
                width: 90%;
                margin-top: .5em;
                margin-left: 5%;
                margin-bottom: 2em;
            }
        }
    }
}