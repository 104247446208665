.SwiperComponentCoreWrapper {
    width: 100%;
    max-width: 100vw;
    position: relative;
    min-height: 1em;
    height: fit-content;

    .bgLayer {
        background: $bgcTransparent;
        position: absolute;
        width: 80%;
        margin-left: 10%;
        height: 50%;
        bottom: 0;
        border-radius: $base-border-radius;

        @include md {
            border-radius: $base-border-radius *1.75;
        }
    }
}

.SwiperComponentCore {
    width: 80%;
    height: 100%;

    .swiper-button-next {
        margin-right: -2rem;

        &:after {
            position: absolute;
            margin-left: .15em;
        }
    }

    .swiper-button-prev {
        margin-left: -2rem;

        &:after {
            position: absolute;
            margin-left: -.15em;
        }
    }

    .gradientOverlay {
        width: 100%;
        height: 50%;
        position: absolute;
        bottom: -2px;
        background: linear-gradient(0deg, rgba(0,0,0,0.4374343487394958) 0%, rgba(0,0,0,0) 100%);
    }

    .swiper-button-next,
    .swiper-button-prev {
        color: $button;
        background-color: white;
        width: 3rem;
        height: 3rem;
        font-size: .05em;
        padding: 2em;
        border-radius: 500em;
        margin-top: -2.5rem;
        transition: all .35s ease-in-out;
        box-shadow: $base-box-shadow;
        transform: scale(0.8);

        @include md {
            width: 4rem;
            height: 4rem;
        }

        &::after {
            font-size: 1.5rem;

            @include md {
                font-size: 2rem;
            }
        }

        &:active {
            background-color: $button;
            color: $txtColor;
            transform: scale(.9);
        }

        &:hover {
            @include md {
                background-color: $button;
                color: $txtColor;
                transform: scale(.9);
            }
        }
    }

    .swiper-wrapper {
        width: 100%;
        padding: 5% 0;

        .swiperSlide {
            min-height: 1em;
            height: 40vh;
            background-color: #fff;
            @include flexCenter(column);
            border-radius: $base-border-radius;
            box-shadow: $base-box-shadow;
            gap: 2.5vh;
            .MuiFormControlLabel-root{
                border: solid transparent 1px !important;
            }
            .placeholder {
                width: 90%;
                height: 10em;
                background-color: black;
                @include flexCenter(column);
                border-radius: $base-border-radius;
                overflow: hidden;

                .img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    position: relative;
                }
            }

            .title,
            .parentTmpButton {
                z-index: 1;
            }

            .backgroundImg {
                width: 100%;
                height: 101%;
                position: absolute;
                object-fit: cover;
            }

            .title {
                color: $txtColor;
            }

            .gradient {
                opacity: .75;
            }
        }
    }
}

.swiperFullWidth {
    width: 100vw;
    overflow: visible;

    .swiper-button-prev {
        left: 5vw;
    }

    .swiper-button-next {
        right: 5vw;
    }

    .swiper-wrapper {
        padding: 0;

        .swiperSlide {
            height: 400px;
        }
    }
}