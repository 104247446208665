//font family
$base-font: urbane,
sans-serif;

// spacing
$base-margin: .55rem;
$base-padding: .75rem;

// borders
$base-border-thickness: .15em;
$base-border-radius: .8em;

// box-shadow
$base-box-shadow: 0 10px 20px rgba(42, 42, 42, 0.1),
0 3px 6px rgba(0, 0, 0, 0.066);
$subtile-box-shadow: 1px 0px 50px 20px #59725817;
// font-sizes
$base-font-size: .875rem;

$font-size-sm: $base-font-size * .85;
$font-size-lg: $base-font-size * 1.5;
$font-size-xl: $base-font-size * 1.7;
$font-size-xxl: $base-font-size * 3;