// VERSIE 1.0
// PAS DEZE FILE NIET ZOMAAR AAN 

.ImageComponentCore {
    width: 100%;
    @include flexCenter(column);
    position: relative;
    overflow: hidden;
    border-radius: $base-border-radius;

    @include md {
        border-radius: $base-border-radius *1.75;
    }

    a {
        @include flexCenter(column);
        width: 100%;
        height: 100%;
        img {
            height: 100%;
            width: 100%;
            object-fit: contain;
        }
    }

    .bgLayer {
        background: $bgcTransparent;
        position: absolute;
        width: 100%;
        height: 100%;
    }


    .bgImg {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: $base-border-radius;
        margin: 0;
    }

    .parentTmpButton {
        position: absolute;
        z-index: 1;
        bottom: 1em;
        right: 1em;
    }

    .parentTmpButton {
        z-index: 1;
        bottom: .5em;
        right: .5em;

        @include md {
            bottom: 1em;
            right: 1em;
        }

        .dsRoundBtn {
            width: 2.5em;
            height: 2.5em;

            @include md {
                width: 3em;
                height: 3em;
            }
        }

        .dsRoundBtn .material-icons-outlined {
            font-size: 1em;

            @include md {
                font-size: 1.25em;
            }
        }
    }


}