//flex
@mixin flexCenter($direction) {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: $direction;
}

@mixin flexRow() {
    display: flex;
    flex-direction: row;
}

@mixin flexColumn {
    display: flex;
    flex-direction: column;
}

@mixin flexCenterJc() {
    display: flex;
    justify-content: center;
}

@mixin flexCenterAi {
    display: flex;
    align-items: center;
}

@mixin fullWidth() {
    position: relative;
    width: 92.5vw;
    padding: 7.5vh 3.75vw;

    @include sm {
        width: 90vw;
        padding: 3em 5vw;
    }

    @include xl {
        width: 80vw;
        padding: 0vh 10vw;

    }

    @include xxl {
        width: 75vw;
        padding: 0vh 12.5vw;

    }
    @include xxxl {
        width: 60vw;
        padding: 0vh 20vw;

    }
}

@mixin placeHolder() {
    display: flex;
    justify-content: center;
    align-items: center;
}

@mixin tmpCard(){
    background-color: $lightcolor;
    border-radius: $base-border-radius;
    box-shadow: $base-box-shadow;
}