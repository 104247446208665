//defaultsettings
.motionPathContainer {
    width: 100vw;
    pointer-events: none;

    .cmpPlane {
        visibility: hidden;
        width: 5em;
        @include lg{
            width: 8em;

        }
    }

    path {
        stroke: $buttonSecondary;
        stroke-width: 1.5;
        stroke-dasharray: 8;

        @include sm{
            stroke-width: 1.1;
            stroke-dasharray: 5;
    
        }
        @include lg {
            stroke-width: .35;
            stroke-dasharray: 1.5;
        }

    }
}


#mpWerkwijze,
#mpAnalytics {
    position: absolute;
}