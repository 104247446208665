#StandaardInfo2 {
    background-color: $bgc;

    .ImageComponentCore {
        a {
            padding: 10% 0;
            img{
                width: 80%;
                height: 80%;
            }
        }
    }

    .TextComponentCore {
        text-align: start;
        align-items: flex-start;

        .btnWrapper {
            flex-direction: column;
        }
    }

    @include md {
        padding-top: 5vh;
    }

    @include lg {
        padding: 10vh 0vh;
    }

    .parent {
        .inner {
            gap: 5vh;
            border-radius: $base-border-radius *2;
            // overflow: hidden;
            @include fullWidth();

            @include md {
                gap: 2.5vw;
            }

            >* {
                color: $txtColorSecondary;
            }

            .ph-2 {
                order: 1;
                width: 100%;
                height: 100%;

                @include md {
                    width: 40%;
                    min-height: 40vh;
                }

                .ImageComponentCore {
                    width: 90%;
                    overflow: visible;
                    box-shadow: $base-box-shadow;

                    .bgLayer {
                        background-color: $bgcThird;
                        border-radius: $base-border-radius;
                    }

                    img {
                        z-index: 1;
                    }
                }

            }

            .btnWrapper {
                align-items: flex-start;
            }

            .ph-1 {
                order: 2;
                min-height: 40vh;

                flex-direction: row;
                color: $txtColor;




                .image {
                    width: 100%;
                }
            }

        }
    }
}