.StandaardImageViewer {
    @include flexCenter(column);
    background-color: $bgc;

    .articleHz,
    .title {
        color: $txtColor;
    }
    .SwiperComponentCoreWrapper {
        @include md {
            padding: 5vh;
          
        }
    }

    .swiper{
        overflow: visible;
    }
    .swiper-wrapper {
        .swiperSlide {
            overflow: hidden;
            border-radius: $base-border-radius;
            height: fit-content;
            @include flexCenter(column);
            gap: 0px;

            .backgroundImg{
                position: relative;
            }
        }
    }
}