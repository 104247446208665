// theme colors
$buttonText: white;
$buttonSecondary: #E60F14;
$button: #FB0207;
$lightcolor: white;
$lightcontrast: #cddded;
$lightcontrastborder: #D3E4F5;


$bgColor: #E5F2FF;
$bgColor2: #F0F8FF;
$txtColor: #000C69;
