.TextComponentCore {
    @include flexCenter(column);
    align-items: flex-start;
    text-align: left;
    gap: .75em;

    @include md {
        gap: 1.25em;
    }

    .btnWrapper {
        @include flexCenter(column);
        gap: 1em;
        @include md{
        @include flexCenter(row);
            
        }
    }
    .paraStagger {
        max-width: 55em
    }
    .retailWidget {
        height: 2em;
        // box-shadow: $base-box-shadow;
        // background-color: #fff;
        border-radius: $base-border-radius *.5;
        @include flexCenter(row);
        position: relative;
        gap: 1em;
        padding: $base-padding*.5 $base-padding;

        img {
            width: 25%;
            max-width: 3em;
            max-height: 80%;
            object-fit: contain;
        }
    }
}

.textComponentCenter{
    @include flexCenter(column);
    text-align: center;
    .btnWrapper{
        align-items: center !important;
    }
}