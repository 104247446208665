@mixin btn() {
    position: relative;
    text-decoration: none;
    display: inline-flex;
    align-items: center;
    width: fit-content;
    text-align: center;
    cursor: pointer;
    border-radius: $base-border-radius * .5;
    font-family: $base-font;
    font-size: $font-size-lg;
    border: 0;
    margin: $base-margin 0;
    letter-spacing: 1px;
}


.createDsBtn {
    margin: .5em 0;
}

.dsBtn {
    @include btn();
    color: black;
    text-align: left;
    border-radius: 0;
    padding: .35em;
    pointer-events: auto !important;
    cursor: pointer !important;
    box-shadow: $subtile-box-shadow;
    transition-duration: .5s;
    filter: brightness(1);
    background-color: $lGreen;
    border-radius: $base-border-radius *.4;
    overflow: hidden;
    height: fit-content;
    margin: 0;


    &:hover {
        background-color: $lGreen;
        color: white;
    }

    .externalIconOuter {
        display: inline-flex;
        padding: $base-padding*.75 $base-padding *1.25;
        align-items: center;
        border-radius: $base-border-radius *.2;
        gap: $base-font-size *.75;

        .externalIcon {
            background-color: $buttonText;
            font-size: $font-size-xl *1.25;
            display: inline-flex;
            vertical-align: top;
            color: white;
            padding: 0.15em;
            border-radius: $base-border-radius * .1;
        }

        i {
            transition-duration: .5s;
            font-size: $base-font-size *1.1;
            letter-spacing: normal;
            display: flex;
            width: fit-content;
            margin: 0 $base-margin;
            justify-content: center;
            text-align: center;
            color: black;
        }
    }
}





.backContainer {
    // background-color: blueviolet;
    z-index: 399;
    @include flexCenter(column);
    position: fixed;
    left: 0;
    margin: 0%;
    transform: scale(.7);

    @include sm {
        margin: 2%;
        transform: scale(1);
    }

    .glowGreen {
        position: absolute;
        opacity: .75;
        height: .25em;
        width: .25em;
        left: auto;
        top: auto;
        transform: scale(0.2);
    }

    button {
        position: absolute;
        z-index: 1;
    }
}


.closeBtnOutline {
    width: 5.5em;
    height: 5.5em;
    border-radius: 4000em;
    border: 1px $buttonSecondary solid;
}

.closeBtn {
    z-index: 1000;
    background-color: black;
    color: white;
    padding: .5em;
    transition-duration: .5s;
    width: fit-content;

    @include lg {
        padding: .75em;

    }

    border-radius: 100em;
    font-size: 1.75em !important;
    cursor: pointer !important;
    box-shadow: $base-box-shadow;

    &:hover {
        background-color: grey;
        color: black;
    }

    &:active {
        background-color: black;
        color: white;
    }
}

.lightBtn {
    padding: $base-padding*.5 $base-padding *1.25;
    border-radius: $base-border-radius;
    background-color: $bgColor;
    width: -webkit-fill-available;
    border: 1px solid $lightcontrastborder;

    &:hover {
        @include md {
            background-color: $lightcontrastborder;
        }

        .dsRoundBtn {
            background-color: $txtColor;
            transform: scale(.9);

            .dashedLine {
                transform: rotate(180deg);
                opacity: .7;
            }
        }
    }
}

.vl-button {
    font-size: 1.1em;
    padding: .65rem 1.25rem;
    gap: .5em;

    .material-icons-outlined {
        height: 1em;
    }

    @include md {
        padding: .75rem 1.5rem;
        min-width: 160px;
        gap: 1em;
        font-size: 1em;

        .material-icons-outlined {
            height: 1em;
            transform: scale(1.2);
        }
    }

    border: 1px solid transparent;
    border-radius: $base-border-radius *.5;
    box-sizing: border-box;
    cursor: pointer;
    text-decoration: none;
    color: #fff;
    font-weight: 600;
    text-align: center;
    transition: all .3s ease-in-out;
    display: inline-block;
    vertical-align: top;
    white-space: nowrap;
    @include flexCenter(row);
    box-shadow: $base-box-shadow;
}

.vl-button-small {
    font-size: .85em;
    padding: .4rem 1rem;
    gap: .5em;

    .material-icons-outlined {
        height: 1em;
        transform: scale(1);
    }
}

.dsRoundBtn {
    pointer-events: auto;
    z-index: 1;
    @include flexCenter(column);
    width: 3em;
    height: 3em;
    border-radius: 100em;
    color: $bgColor;
    background-color: $button;
    position: relative;
    transition: .15s all;

    &:hover {
        background-color: $buttonSecondary;
    }

    >* {
        cursor: pointer;
    }

    @include md {
        width: 4.5em;
        height: 4.5em;
    }

    .material-icons-outlined {
        font-size: 1.25em;

        @include md {
            font-size: 1.25em
        }
    }

    .dashedLine {
        position: absolute;
        width: 90%;
        height: 90%;
        transition: .5s all;

    }
}