// default color
html {
    color: black;
}

:root {
    --step--2: clamp(0.7rem, calc(0.69rem + 0.27vw), 0.97rem);
    --step--1: clamp(0.8rem, calc(0.85rem + 0.46vw), 1.20rem);
    --step-0: clamp(1.13rem, calc(0.99rem + 0.65vw), 1.50rem);
    --step-1: clamp(1.35rem, calc(1.17rem + 0.91vw), 1.88rem);
    --step-2: clamp(1.40rem, calc(1.27rem + 1.26vw), 2.34rem);
    --step-3: clamp(1.94rem, calc(1.60rem + 1vw), 2.93rem);
    --step-4: clamp(2.1rem, calc(2.25rem + 2.31vw), 3.66rem);
    --step-5: clamp(2.80rem, calc(2.51rem + 2.43vw), 4.20rem);

    // Aanpassen? check:
    // https://utopia.fyi/type/calculator?c=320,18,1.2,1240,24,1.25,5,2,&s=0.75%7C0.5%7C0.25,1.5%7C2%7C3%7C4%7C6,s-l&g=s,l,xl,12
    // https://www.youtube.com/watch?v=R1ehrIk0oUU
}


.staggerTitle div {
    overflow: hidden;
    vertical-align: top;
}

strong {
    font-weight: 600;

    @include xl {
        font-size: 4em;
        line-height: 1.25em;
    }
}

q {
    font-size: var(--step-0);
    padding-right: 15%;

    &:after,
    &:before {
        color: $buttonSecondary;
        font-family: auto;
        font-size: var(--step-5);
        position: absolute;
        margin-top: -0.1em;
    }

    &:before {
        margin-left: -.55em;

        @include lg {
            margin-left: -.65em;
        }
    }

    &:after {
        margin-left: .25em;
    }
}

// // Koptitle
h1 {
    font-size: 2.65em;
    line-height: 1.15em;
    font-weight: 600;
    @include md {
        font-size: 5.5em;
    }


    // font-size: $font-size-xxl *1.2;
    // margin: $base-margin 0;
    // font-weight: 300;
    // font-size: var(--step-3);

    // @include sm {
    //     font-size: var(--step-4);
    // }

    // @include xxl {
    //     font-size: var(--step-5);
    // }
}

// Subtitle
h2 {
    font-size: 2.5em;
    line-height: 1.1em;
    font-weight: 600;

    @include md {
        font-size: 3em;
    }
    // font-weight: 300;
    // font-size: var(--step-2);
    // line-height: 1.3;

    // @include sm {
    //     font-size: var(--step-3);
    // }

    // @include xxl {
    //     font-size: var(--step-4);
    // }
}

//Ondertitle
h3 {

    font-size: 1.8em;
    line-height: 1.4em;
    font-weight: 600;
    // font-weight: 300;
    // font-size: var(--step-1);

    // @include sm {
    //     font-size: var(--step-2);
    // }

    // @include xxl {
    //     font-size: var(--step-3);
    // }
}

//Ondertitle
h4 {
    // font-weight: 300;
    // font-size: var(--step--1);
    // line-height: 1.3;

    // @include sm {
    //     font-size: var(--step-0);
    // }

    // @include xxl {
    //     font-size: var(--step-1);
    // }

}

//Broodtekst
p {
    font-size: var(--step--2);
    line-height: 1.8em;
    font-weight: 300;
}


// h5 {
//     font-weight: 500;
//     font-size: var(--step--1);
// }

// h6 {
//     line-height: 1.8em;
//     font-weight: 300;
//     font-size: var(--step--0);
// }