.CookiePolicyPP {
    position: fixed;
    @include flexCenter(column);
    width: 100vw;
    height: 100vh;
    top: 0;

    z-index: 1000;

    .CookiePolicy-exitbg {
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.75);
    }

    .CookiePolicy-inner {
        width: 75vw;
        display: flex;
        flex-direction: column;
        max-width: 50em;
        // height: 50vh;
        gap: 1em;
        position: relative;
        background-color: #fff;

        border-radius: $base-border-radius *1.5;
        box-shadow: $base-box-shadow;
        padding: $base-padding *4 $base-padding *2.5;

        @include md {
            padding: $base-padding *4;
        }

        .CookiePolicy-title {
            font-size: 1.75em;
            width: 80%;
            // margin-top: 1em;
        }

    }

    .closeBtn {
        right: $base-padding *2;
        top: $base-padding *2;

        @include md {
            right: $base-padding *3;
        }

        position: absolute;
        float: right;
    }
}